import React, {useEffect, useRef, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateTextslide} from '../../graphql/mutations';
import {getPlayslide, getTextslide} from "../../graphql/queries";
import {useParams} from "react-router-dom";
import "../css/poll.css";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./style.css";
import ImageResize from 'quill-image-resize-module-react'
import { SnowTheme } from 'quill-color-picker-enhance'
import 'quill-color-picker-enhance/dist/index.css'
import WebFont from 'webfontloader';
import Resizer from "react-image-file-resizer";
const resizeFile = (file,width, height) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			width,
			height,
			"JPEG",
			85,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});
const Font = Quill.import('formats/font')
Font.whitelist = ['sans-serif', 'Monospace', 'roboto', 'Open Sans', 'Montserrat','Lato','Play','Prompt']
Quill.register(Font, true)
Quill.register('modules/imageResize', ImageResize)
Quill.register('themes/snow-quill-color-picker-enhance', SnowTheme);
Quill.formats = [
	'header', 'font', 'size', 'color', 'background', 'align',
	'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
	'list', 'bullet', 'indent', 'script',
	'image', 'video'
]
Quill.modules = {
	toolbar: [
		[{ 'font': Font.whitelist }],
		[{ size:[] }], [{ 'color': [] }, { 'background': [] }], [{ 'align': [] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
		[{ 'list': 'ordered' }, { 'list': 'bullet' },
			{ 'indent': '-1' }, { 'indent': '+1' }], [{ 'script': 'sub' }, { 'script': 'super' }],
		['image', 'video'],
		['clean']
	],
	clipboard: {
		// toggle to add extra line breaks when pasting HTML:
		matchVisual: false,
	},
	imageResize: {
		parchment: Quill.import('parchment'),
		modules: ['Resize', 'DisplaySize']
	}
}
Quill.theme = 'snow-quill-color-picker-enhance'

function Newteslideedit() {
	const { id,rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [value, setValue] = useState('');
	const quillRef = useRef(null)
	useEffect(() => {
		WebFont.load({
			google: {
				families: ['Roboto', 'Open Sans','Montserrat','Lato', "Play", "Prompt"]
			}
		})
	}, [])
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
		})()
	}, []);
	useEffect( () => {
		(async() => {
			const response = await API.graphql({
				query: getTextslide,
				variables:{id:rid}
			})
			const datas = response.data.getTextslide;
			const i1 = datas.bgcolor
			const contentState = JSON.parse(i1);
			setValue(contentState)
		})()
	}, []);

	async function submitItems (event) {
		event.preventDefault();
		const delta = quillRef.current.getEditor().getContents()
		const resizedOpPromises = delta.ops.map(async op => {
			if (op.insert.image == null) {
				return op
			}
			const imageElement = document.querySelector(`img[src="${op.insert.image}"]`);
			const width = imageElement.width;
			const height = imageElement.height;
			const response = await fetch(op.insert.image)
			const blob = await response.blob()
			const resized = await resizeFile(blob, width, height)
			return {insert:{image:resized}}
		})
		const resizedOps = await Promise.all(resizedOpPromises);
		const resizedDelta = { ops: resizedOps }
		const string = JSON.stringify(resizedDelta);
		try {
			const input = {
				id: rid,
				bgcolor:string
			}
			await API.graphql({
				query: updateTextslide,
				variables: {input}
			});
			setUploadedmessage("Updated!")

		} catch (err) {
			console.log(err)
		}
	}
	return (
		isOwner === isUser ?
		<>
		<div className="newtextslidepage">
			<ReactQuill
				ref={quillRef}
				theme={Quill.theme}
				value={value}
				onChange={setValue}
				modules={Quill.modules}
				formats={Quill.formats}
				placeholder="write something ..."
			/>
		</div>
	<div className="newtextslidepagebottom">
		<span className="redthis">{uploadedmessage}</span> <button onClick={submitItems} className="answersubmit">Update</button>
	</div>
			</>
			:""
	);
}
export default Newteslideedit;

import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getFlipcard, listLeaderboards} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import {Button} from "react-bootstrap";
import "../../css/flip.css";
import Flipshow from "./flipshow";
import moment from "moment";
import {createLeaderboard} from "../../../graphql/mutations";

function Flipshowroom() {
    const [isShow, setIsShow] = useState(true);
    const [counter, setCounter] = useState();
    const [cards, setCards] = useState([]);
    const { id, rid } = useParams();
    const starts = moment().format('MM/DD/YYYY hh:mm a');
    sessionStorage.setItem("edureachumthisstartstimer", starts)
    const start = new Date();
    sessionStorage.setItem("edureachumthisstarttimer", start.toString())
    useEffect( () => {
        fetchDas();
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            sessionStorage.setItem("edureachumthisid", thisid)
                            const thisques = plays[0].questions;
                            if(thisques) {
                                const serializedArray = JSON.stringify(thisques);
                                sessionStorage.setItem("edureachumthisques", serializedArray)
                            }
                            const totalscore = plays[0].score;
                            if(totalscore) {
                                sessionStorage.setItem("edureachumthistotalscore", totalscore);
                            }else{
                                sessionStorage.setItem("edureachumthistotalscore", 0)
                            }
                            sessionStorage.setItem("edureachumthisuser", vreachumnewerna)
                        }else{
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                score: 0,
                                thisgamescore: 0,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            sessionStorage.setItem("edureachumthisid", thistimerid);
                            sessionStorage.setItem("edureachumthistotalscore", 0)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getFlipcard,
                variables: {id:rid}
            })
            const datas = response.data.getFlipcard;
            const timers = datas.ptimer;
            setCounter(timers)
            const ques = datas.fwords;
            setCards(JSON.stringify(ques))
        }catch(err){
            console.log(err)
        }
    }
    function savethisdata(){
        localStorage.setItem("reachumflipcardgametimer",counter);
        localStorage.setItem("reachumflipcardgame",cards);
        setIsShow(false)
    }

    return (
        <>
            <div className="flipcardpage">
                    {isShow ?
                        <>
                            <br/>
                            <br/>
                            <br/>
                <h2>Memory matching game - Flipping card</h2>
                            <br/>
                <div>
                    <p className="biggertext">Select two cards with same meaning content<br/> consecutively to make them vanish.</p>
                </div>
                        <div className="startbutton">
                        <Button onClick={savethisdata} variant="success">CLICK TO START</Button>
                        </div>
                            </>
                        :
                        <>
                            <Flipshow />
                        </>
                    }
            </div>
        </>
    )
}

export default Flipshowroom;

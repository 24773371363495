import React, {useState, useEffect} from 'react'
import Showcategory from './showcategory'
import {API} from "aws-amplify";
import {getDraganddrop, listLeaderboards} from "../../../graphql/queries";
import {Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../css/taptap.css";
import moment from "moment";
import {createLeaderboard} from "../../../graphql/mutations";

export default function Showdragdrop() {
  const [isgameopen, setIsgameopen] = useState(false)
  const [quest, setQuest] = useState();
  const { id, rid } = useParams();
  const [thiscate, setThiscate] = useState([]);
  const [counter, setCounter] = useState();
  const starts = moment().format('dddd  MMMM Do YYYY  h:mm:ss a');
  sessionStorage.setItem("edureachumthisstartstimer", starts)
  const start = new Date();
  sessionStorage.setItem("edureachumthisstarttimer", start.toString())
  useEffect(() => {
    fetchDas();
  }, []);
  useEffect(() => {
    const vreachumnewerna = localStorage.getItem("vreachumnewername");
    const reachumnname = localStorage.getItem("vreachumneweremail");
    (async () => {
      if (reachumnname) {
        try {
          await API.graphql({
            query: listLeaderboards,
            variables: {
              filter: {
                "meetemail": {
                  eq: reachumnname
                },
                "answerid": {
                  eq: id
                }
              },
              limit: 10000
            }
          }).then(datas => {
            const plays = datas.data.listLeaderboards.items;
            const playss = plays.length;
            if (playss > 0) {
              const thisid = plays[0].id;
              sessionStorage.setItem("edureachumthisid", thisid)
              const thisques = plays[0].questions;
              if(thisques) {
                const serializedArray = JSON.stringify(thisques);
                sessionStorage.setItem("edureachumthisques", serializedArray)
              }
              const totalscore = plays[0].score;
              if(totalscore) {
                sessionStorage.setItem("edureachumthistotalscore", totalscore)
              }else{
                sessionStorage.setItem("edureachumthistotalscore", 0)
              }
              sessionStorage.setItem("edureachumthisuser", vreachumnewerna)
            }else{
              const input = {
                name: vreachumnewerna,
                meetemail: reachumnname,
                answerid: id,
                score: 0,
                thisgamescore: 0,
                timerin:starts
              }
              const responsee = API.graphql({
                query: createLeaderboard,
                variables: {input}
              });
              const thistimerid = responsee.id;
              sessionStorage.setItem("edureachumthisid", thistimerid);
              sessionStorage.setItem("edureachumthistotalscore", 0)
            }
          })
        } catch (err) {
          console.log(err)
        }
      }
    })()
  }, []);
  async function fetchDas() {
    try {
      const response = await API.graphql({
        query: getDraganddrop,
        variables: {id:rid}
      })
      const datas = response.data.getDraganddrop;
      const timers = datas.dtimer;
      setCounter(timers)
      const ques = datas.dquestion;
      setQuest(ques);
      sessionStorage.setItem("edureachumthisquestion", ques)
      const caes = datas.categories;
      setThiscate(caes);
    }catch(err){
      console.log(err)
    }
  }
  function savethisdata(){
    sessionStorage.setItem("dragdropcategories",JSON.stringify(thiscate));
    sessionStorage.setItem("dragdropcategoriescounter", counter);
    sessionStorage.setItem("dragdropcategoriesques", quest);
    setIsgameopen(true);
  }

  return (
      <div>
              {isgameopen ?
                  <>
                 <Showcategory />
                  </>
                  :
                  <div className="centerthis">
                    <br/>
                    <br/>
                    <br/>
                    <h2> Tap Tap </h2>
                    <br/>
                    <p className="biggertext">
                      Tap to select an item.</p>
                    <p className="biggertext"> Then tap the category where it belongs.
                    </p>
                    <br/>
                    <Button onClick={savethisdata}>CLICK TO START</Button>
                  </div>
              }
      </div>

  )
}

import React, {useEffect, useState} from "react";
import {API, Auth, Storage} from 'aws-amplify';
import {getAnswers, getPlayslide, listAnswerslists, listLeaderboards} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import {Button, Modal, ListGroup} from "react-bootstrap";
import "../css/answers.css";
import {
    createAnswerslist, createLeaderboard,
    deleteAnswerslist, updateLeaderboard
} from "../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {
    newDeleteAnswerslist,
    newCreateAnswerslist,
} from "../../graphql/subscriptions";
import DropdownButton from 'react-bootstrap/DropdownButton';
import ReactWordcloud from 'react-wordcloud';
import moment from "moment";
var XLSX = require("xlsx");


const options = {
    colors: ['#e82c82', '#034f4b', '#01312e', '#311d48', '#000000', '#595959', '#a574dc', '#269b7b', '#72b5a4', '#d2196e', '#580b3f'],
    enableTooltip: false,
    deterministic: false,
    fontFamily: ['impact', 'Helvetica', 'sans-serif', 'Tahoma', 'Verdana', 'Courier New'],
    fontSizes: [15, 70],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 1,
    rotations: 3,
    rotationAngles: [-90, 0, 90],
    scale: 'sqrt',
    spiral: 'rectangular',
    transitionDuration: 1000,
};
function Wordcloudroom() {
    const [isOpen, setIsOpen] = useState(null);
    const [openwords, setOpenwords] = useState(true);
    const [myData, setMyData] = useState([]);
    const [quest, setQuest] = useState();
    const [vals, setVals] = useState("");
    const { id, rid } = useParams();
    const [isDisabled, setDisabled] = useState(true);
    const [waitingresponse, setWaitingresponse] = useState("Waiting for responses ...");
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => (sessionStorage.setItem("answerowner", user.attributes.email)))
            .catch(err => console.log(err));
        fetchowner()
        fetchDas();
    }, []);
    useEffect(() => {
        fetchData();
        const deleteAnswerslist = API.graphql(
            {
                query:newDeleteAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();

            }
        })
        const createanswerslist = API.graphql(
            {
                query:newCreateAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })

        return()=>{
            deleteAnswerslist.unsubscribe();
            createanswerslist.unsubscribe();
        }
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        const starts = moment().format('MM/DD/YYYY hh:mm a');
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            sessionStorage.setItem("edureachumthisid", thisid)
                            const thisques = plays[0].questions;
                            if(thisques) {
                                const serializedArray = JSON.stringify(thisques);
                                sessionStorage.setItem("edureachumthisques", serializedArray)
                            }
                        }else{
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                score: 0,
                                thisgamescore: 0,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            sessionStorage.setItem("edureachumthisid", thistimerid)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getAnswers,
                variables: {id:rid}
            })
            const datas = response.data.getAnswers;
            const ques = datas.fquestion;
            setQuest(ques);
            sessionStorage.setItem("edureachumthisquestion", ques)
        }catch(err){
            console.log(err)
        }
    }
    async function fetchowner() {
        try{
        const response = await API.graphql({
            query: getPlayslide,
            variables: {id}
        })
        const datas = response.data.getPlayslide;
        const iowner = datas.owner;
       const t = sessionStorage.getItem("answerowner");
        if(t === iowner){
            setIsOpen(false)
        }else{
            setIsOpen(true)
        }
        }catch(err){
            console.log(err)
        }
    }
    function getRandomSize() {
        return Math.floor(Math.random() * 1000) + 100; // Adjust range as needed
    }
    async function fetchData(){
        try {
        const response = await API.graphql({
            query: listAnswerslists,
            variables: {
                filter: {
                    "answerid": {
                        eq: rid
                    }
                }
            }
        })
        const datas = response.data.listAnswerslists;
        const yy = datas.items;
            if(yy.length !== 0) {
                const newData = yy.map(item => {
                    return {
                        ...item,
                        text: item.answers,
                        value: getRandomSize()
                    };
                });
                newData.forEach(item => delete item.answers);
                setMyData(newData);
                setWaitingresponse("");
            }else{
                setMyData([]);
                setWaitingresponse("Waiting for responses ...")
            }
    }catch(err){
        console.log(err)
    }
    }

    const downloadFile=()=>{
        const rows = myData.map(row => ({
            name: row.name,
            email: row.email,
            answer: row.text
        }));
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reachum");
        XLSX.utils.sheet_add_aoa(worksheet, [["Name","Email", "Answer"]], { origin: "A1" });
        XLSX.writeFile(workbook, `${quest} - REACHUM_wordcloud.xlsx`, { compression: true });
    }
    const psubmit = async () => {
            let mymeetnames = localStorage.getItem("vreachumnewername");
            let mymeetemails = localStorage.getItem("vreachumneweremail");
            const input = {
                answerid: rid,
                answers: vals,
                name:mymeetnames,
                email:mymeetemails,
            }
            API.graphql({
                query: createAnswerslist,
                variables: {input}
            });
        setVals("");
        setDisabled(true);
        setOpenwords(false);
    }


    const handleChanges = e => {
        const thisval = e.target.value;
        setVals(thisval);
        if(thisval !== ""){
            setDisabled(false)
        }
    }
    const clearresults = async ()=>{
        let text = "Are you sure you want to delete all answers? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            try {
                let cl = myData.length;
                for (let i=0; i<cl; i++){
                    const input = {
                        id: myData[i].id
                    };
                    await API.graphql({
                        query: deleteAnswerslist,
                        variables: {input}
                    });
                }
            }catch(err){
                console.log(err)
            }
        }
    }
    useEffect(() => {
        let start;
        const handleStartTime = () => {
            start = new Date();
        };
        const onClose = () => {
            const thisrid = sessionStorage.getItem("edureachumthisrid");
            const thisques = sessionStorage.getItem("edureachumthisquestion");
            const thisqueslist = sessionStorage.getItem("edureachumthisques");
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            const end = new Date();
            const timeDiff = end - start;
            const seconds = parseInt((timeDiff / 1000) % 60);
            try {
                const thisqq = {
                    rid: thisrid,
                    question: thisques,
                    duration: seconds
                }
                let qq;
                if (thisqueslist) {
                    const objectArray = JSON.parse(thisqueslist);
                    qq = [...objectArray, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: thisid,
                    questions: qq,
                    timerout: ends
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            }catch (err) {
                console.log(err)
            }
        }
        window.addEventListener('load', handleStartTime);
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('load', handleStartTime);
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
        <>
            <div className="answersshowroompage">
            <div className="answersroom">
                <div className="purple1back">
                            <h2>{quest}</h2>
                        </div>
                {isOpen ?
                    <>
                        {openwords ?
                            <div>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <Grid container spacing={0}>
                                <Grid item xs={6} className="rightside">
                                    <input onChange={handleChanges} maxLength="25" placeholder="Type Word Here" value={vals} className="wordcloudinput"/>
                                </Grid>
                                <Grid item xs={6} className="wordsleftside">
                                    &nbsp; &nbsp; <Button id="pollsubmit" onClick={psubmit} className="convosubmit"
                                            disabled={isDisabled}>Submit</Button>
                                </Grid>
                                </Grid>
                            </div>
                            :
                            <div className="cccenter">
                                <br/>
                                <ReactWordcloud words={myData} options={options}/>
                            </div>
                        }
                    </>
                    :(
                    <>
                        <div className="anserrightside">
                            <div className="answershowleftside">
                                <DropdownButton id="dropdown-basic-button" className="rdropmenu" title="">
                                    <ListGroup>
                                        <ListGroup.Item>
                                            <span onClick={downloadFile} className="answersspan"> Save as Spreadsheet </span>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <span onClick={clearresults} className="answersspan"> Clear Responses </span>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </DropdownButton>
                            </div>
                            <div className="cccenter">
                                <div className="answersroomwaiting">
                                    <br/>
                                    <br/>
                                    <h1> {waitingresponse}</h1>
                                </div>
                                <ReactWordcloud words={myData} options={options}/>
                            </div>
                        </div>
                    </>
                    )
                }

            </div>
            </div>
            </>

    )
}

export default Wordcloudroom;


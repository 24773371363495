import React, {useEffect, useState} from "react";
import { API} from 'aws-amplify';
import {getTextslide, listLeaderboards} from '../../graphql/queries';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {useParams} from "react-router-dom";
import "../css/slide.css";
import moment from "moment";
import {createLeaderboard, updateLeaderboard} from "../../graphql/mutations";


function Newershowroom() {
    const [clist, setClist] = useState("");
    const { id, rid } = useParams();
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        fetchDas();
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        const starts = moment().format('MM/DD/YYYY hh:mm a');
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            sessionStorage.setItem("edureachumthisid", thisid)
                            const thisques = plays[0].questions;
                            if(thisques) {
                                const serializedArray = JSON.stringify(thisques);
                                sessionStorage.setItem("edureachumthisques", serializedArray)
                            }
                        }else{
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            sessionStorage.setItem("edureachumthisid", thistimerid)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getTextslide,
                variables: {id:rid}
            })
            const datas = response.data.getTextslide;
            const tests = datas.bgcolor;
            if(tests !== null) {
                setClist(tests);
            }
        }catch(err){
            console.log(err)
        }
    }
    useEffect(() => {
        let start;
        const handleStartTime = () => {
            start = new Date();
        };
        const onClose = () => {
            const thisrid = sessionStorage.getItem("edureachumthisrid");
            const thisqueslist = sessionStorage.getItem("edureachumthisques");
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            const end = new Date();
            const timeDiff = end - start;
            const seconds = parseInt((timeDiff / 1000) % 60);
            try {
                const thisqq = {
                    rid: thisrid,
                    question: "Math Slide",
                    duration: seconds
                }
                let qq;
                if (thisqueslist) {
                    const objectArray = JSON.parse(thisqueslist);
                    qq = [...objectArray, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: thisid,
                    questions: qq,
                    timerout: ends
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            }catch (err) {
                console.log(err)
            }
            return 'Ssave DB'
        }
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('load', handleStartTime);
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('load', handleStartTime);
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
        <>
            <div className="newertextshowpage">
                <SunEditor hideToolbar={true} disable={true} setDefaultStyle="font-size: 24px;" setContents={clist} width="100%" height="100%" />
            </div>
        </>
    )
}

export default Newershowroom;

import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateTextslide} from '../../graphql/mutations';
import {getPlayslide, getTextslide} from "../../graphql/queries";
import {useParams} from "react-router-dom";
import "../css/poll.css";
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function Newtextslideedit() {
	const { id,rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [editorState, setEditorState] = useState();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
		})()
	}, []);
	useEffect( () => {
		(async() => {
			const response = await API.graphql({
				query: getTextslide,
				variables:{id:rid}
			})
			const datas = response.data.getTextslide;
			const i1 = datas.bgcolor
			const contentState = convertFromRaw(JSON.parse(i1));
			setEditorState(EditorState.createWithContent(contentState) );
		})()
	}, []);

	function  onEditorStateChange(editorState){
		setEditorState(editorState)
	}
	async function submitItems (event) {
		event.preventDefault();
		const contentState = editorState.getCurrentContent();
		const contentStateJSON = JSON.stringify(convertToRaw(contentState));
		try {
			const input = {
				id: rid,
				bgcolor:contentStateJSON
			}
			await API.graphql({
				query: updateTextslide,
				variables: {input}
			});
			setUploadedmessage("Updated!")

		} catch (err) {
			console.log(err)
		}
	}
	return (
		isOwner === isUser ?
		<>
		<div className="newtextslidepage">
			<Editor
				wrapperClassName="wrapper-class"
				editorClassName="editor-class"
				toolbarClassName="toolbar-class"
				toolbar={{
					inline: { inDropdown: true },
					list: { inDropdown: true },
					textAlign: { inDropdown: true },
					link: { inDropdown: true },
					history: { inDropdown: true },
				}}
				editorState={editorState}
				onEditorStateChange={onEditorStateChange}
				className="newtextarea"
			/>
		</div>
	<div className="newtextslidepagebottom">
		<span className="redthis">{uploadedmessage}</span> <button onClick={submitItems} className="answersubmit">Update</button>
	</div>
			</>
			:""
	);
}
export default Newtextslideedit;

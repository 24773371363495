import React, { useState, useEffect } from 'react';
import {API} from 'aws-amplify';
import {useParams} from "react-router-dom";
import {getDesmomathslide, listLeaderboards} from "../../graphql/queries";
import Grid from "@material-ui/core/Grid";
import {Button} from 'react-bootstrap';
import moment from "moment";
import {createLeaderboard, updateLeaderboard} from "../../graphql/mutations";


const  Mathslideshow = ()  =>{
    const { id,rid } = useParams();
    const [thisimage, setThisimage] = useState('')
    const [thisgimage, setThisgimage] = useState('')
    const [openimagebuttons, setOpenimagebuttons] =useState(false)
    const [openimage, setOpenimage] =useState(true)
    const [openthisimage, setOpenthisimage] = useState(false)
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        const starts = moment().format('MM/DD/YYYY hh:mm a');
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            sessionStorage.setItem("edureachumthisid", thisid)
                            const thisques = plays[0].questions;
                            if(thisques) {
                                const serializedArray = JSON.stringify(thisques);
                                sessionStorage.setItem("edureachumthisques", serializedArray)
                            }
                        }else{
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                score: 0,
                                thisgamescore: 0,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            sessionStorage.setItem("edureachumthisid", thistimerid)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);
   async function fetchData(){
            const response = await API.graphql({
                query: getDesmomathslide,
                variables:{id:rid}
            })
            const datas = response.data.getDesmomathslide;
            const tt = datas.graphic;
            if(tt!== ""){
                setThisgimage(tt);
            }
       const mimage = datas.image;
       if(mimage !== ""){
           setThisimage(mimage);
           setOpenimagebuttons(true)
       }
}
    useEffect(() => {
        let start;
        const handleStartTime = () => {
            start = new Date();
        };
        const onClose = () => {
            const thisrid = sessionStorage.getItem("edureachumthisrid");
            const thisqueslist = sessionStorage.getItem("edureachumthisques");
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            const end = new Date();
            const timeDiff = end - start;
            const seconds = parseInt((timeDiff / 1000) % 60);
            try {
                const thisqq = {
                    rid: thisrid,
                    question: "Math Slide",
                    duration: seconds
                }
                let qq;
                if (thisqueslist) {
                    const objectArray = JSON.parse(thisqueslist);
                    qq = [...objectArray, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: thisid,
                    questions: qq,
                    timerout: ends
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            }catch (err) {
                console.log(err)
            }
        }
        window.addEventListener('load', handleStartTime);
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('load', handleStartTime);
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
        <>
            <div className="cccenter">
                <br/>
                <br/>
                <br/>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        {openimagebuttons ?
                            <>
                                {openimage ?
                                    <Button onClick={()=> {
                                        setOpenimage(false);
                                        setOpenthisimage(true)
                                    }} variant="success"> Show Image</Button>
                                    :
                                    <Button onClick={()=> {
                                        setOpenimage(true);
                                        setOpenthisimage(false)
                                    }} variant="success"> Hide Image </Button>
                                }
                            </>
                            : ""
                        }
                    </Grid>
                </Grid>
                <br/>
                <br/>
                {openthisimage ?
                    <img src={thisimage} className="pximages400"/>
                    : ""
                }
                <br/>
                <br/>
                    <img src={thisgimage} className="pximage960"/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        </>
    );
}

export default Mathslideshow;

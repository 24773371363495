import React, {useEffect, useState, useRef} from "react";
import {API, Auth} from 'aws-amplify';
import {createTextslide, updatePlayslide} from '../../graphql/mutations';
import {getPlayslide} from "../../graphql/queries";
import { v4 as uuid } from 'uuid';
import "../css/slide.css";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import katex from 'katex'
import "./style.css";
import Resizer from "react-image-file-resizer";
const resizeFile = (file,width, height) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            width,
            height,
            "JPEG",
            85,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana"
];
const buttonList = [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['paragraphStyle', 'blockquote'],
    ['table', 'image', 'video', 'audio', 'math'],
    [ 'showBlocks', 'codeView'],
    ['preview', 'print'],
    ['save']
]
function Textslide({id, handleClose68}) {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [images, setImages] = useState([]);
    const [defaultFontSize, setDefaultFontSize] = useState('24px');
    const [smessage, setSmessage] = useState("")
    const [opensmessage, setOpensmessage] = useState(true)
    const d = uuid();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);
    const sortedFontOptions = [
        "Logical",
        "Salesforce Sans",
        "Garamond",
        "Sans-Serif",
        "Serif",
        "Times New Roman",
        "Helvetica",
        "Roboto",
        "Poppins",
        ...defaultFonts
    ].sort();
    const defaultSizes = [
        14, 16, 18, 20, 22, 24, 26, 28,30,34,36,40,48,56,60,68,72,82
    ].sort();
    const reorderurl = `https://edu.reachum.com/newershowroom/${id}/${d}`;
    const erurl = `https://edu.reachum.com/newerslideedit/${id}/${d}`
    async function handleSave(content){
        const response = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = response.data.getPlayslide;
        const images = datas.playlistlinks;
        try {
            const input = {
                id:d,
                bgcolor:content,
                pfname:"text slide"
            }
       await API.graphql({
                query: createTextslide,
                variables: {input}
            });
            const urldata = {
                rid: d,
                title: "text slide",
                rurl: reorderurl,
                eurl: erurl
            };
            const allImages = [...images, urldata]
            const inputs = {
                id,
                playlistlinks: allImages
            }
            await API.graphql({
                query: updatePlayslide,
                variables: {input:inputs}
            })
            setOpensmessage(false);
            setSmessage("Submitted");
            setTimeout(() => {
                handleClose68()
            }, 1000)
        } catch (err) {
            console.log(err)
        }
    }
    const options = {
        buttonList,
        katex,
        font: sortedFontOptions,
        fontSize: defaultSizes,
        defaultStyle: `font-size: ${defaultFontSize};`,
        onChange: (contents, core) => {
            const currentFontSize =
                core.getSelectedElement().style.fontSize || defaultFontSize;
            setDefaultFontSize(currentFontSize);
        },
        minHeight: '100vh',
        callBackSave: handleSave
    }
    async function handleImageUpload(target, index, state, imageInfo) {
        if(target) {
            const response = await fetch(target.src);
            const blob = await response.blob()
            const resized = await resizeFile(blob, target.width, target.height)
            target.src = resized
        }
    }
    return (
        isOwner === isUser ?
            <>
                <div className="slidepage">
                    <div className="thiscenter">
                    <h3>Text slide</h3>
                    </div>
                    <div>
                        {opensmessage ?
                           ""
                            :
                            <div className="cccenter">
                            <p className="redthis"> {smessage} </p>
                            </div>
                        }
                        <div>
                            <SunEditor setOptions={options} placeholder="Please type here..." onImageUpload={handleImageUpload} />
                        </div>
                    </div>
                </div>
            </>
            :""
    );
}
export default Textslide;

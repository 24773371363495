import React, {useEffect, useRef, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {createDesmosfillin,updatePlayslide} from '../../../../graphql/mutations';
import { useForm } from "react-hook-form";
import {getPlayslide} from "../../../../graphql/queries";
import { v4 as uuid } from 'uuid';
import "../../../css/math.css";
import Grid from "@material-ui/core/Grid";
import DesmosCalculator from "../DesmosCalculator";
import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			400,
			400,
			"JPEG",
			95,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});
function Mathfastanswer({id, handleClose57}) {
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const [selectedFile, setSelectedFile] = useState("");
	const [ftimers, setFtimers] = useState(0);
	const [showtimerform, setShowtimerform] = useState(false);
	const [smessage, setSmessage] = useState("")
	const [opensmessage, setOpensmessage] = useState(true)
	const addRef = useRef(null)
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, [id]);
	const handleFileChange = async (event) => {
		const img = event.target.files[0];
		const resized = await resizeFile(img);
		setSelectedFile(resized)
	};
	const {register, handleSubmit} = useForm({});
	const reorderurl = `https://edu.reachum.com/mathfasteranswerroom/${id}/${d}`;
	const erurl = `https://edu.reachum.com/mathfastansweredit/${id}/${d}`;
	const ileaderboardurl = `https://edu.reachum.com/leaderboard/${id}`;
	const canser = `https://edu.reachum.com/mathfastercorrctanswer/${id}/${d}`;
	const tt = `${d}1`;
	const cc = `${d}333`;
	const onSubmit = async (data) => {
		const calculatorState = addRef.current.getState()
		const latexExpressions = calculatorState.expressions.list.filter(expression => {
			return expression.latex != null
		})
		let resized;
		if (latexExpressions.length === 0) {
			resized = "";
		} else {
			const screenshot = addRef.current.screenshot()
			const response = await fetch(screenshot)
			const blob = await response.blob()
			resized = await resizeFile(blob)
		}
		try {
			const input = {
				id:d,
				ffname:data.ffname,
				ftimer:ftimers,
				fquestion:data.fquestion,
				fanswer:data.fanswer,
				image: selectedFile,
				mathstate:resized
			}
			await API.graphql({
				query: createDesmosfillin,
				variables: {input}
			});
			const urldata = {
				rid:d,
				title:data.ffname,
				rurl: reorderurl,
				eurl:erurl
			};
			const leaderboard = {
				rid:tt,
				title:"leaderboard",
				rurl:ileaderboardurl
			}
			const correcrur = {
				rid: cc,
				title:"correct answer",
				rurl: canser
			}
			const allImages = [...images, urldata, correcrur, leaderboard]
			const inputs = {
				id,
				playlistlinks: allImages
			}
			await API.graphql({
				query: updatePlayslide,
				variables: {input:inputs}
			})
			setOpensmessage(false);
			setSmessage("Submitted");
			setTimeout(() => {
				handleClose57()
			}, 1000)
		} catch (err) {
			console.log(err)
		}
	}

	return (
		isOwner === isUser ?
		<>
			<div className="mathfaspage">
				<div className="thiscenter">
				<h3>Fill in the Blank</h3>
				</div>
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					Game name : &nbsp;
				</Grid>
				<Grid item xs={10}>
				<input
					name="ffname"
					defaultValue=""
					ref={register({ required: true, maxLength:60 })}
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					Timer : &nbsp;
				</Grid>
				<Grid item xs={10}>
					<Grid container spacing={0}>
						<Grid item xs={7} className="cccenter">
							<div className="labelinlinetimer">
								<label>
									<input
										type="radio"
										name="timer"
										value="AddTimer"
										onChange={()=>{setShowtimerform(true)}}
										className="biggerradiotimer"
									/>
									Add Timer (Seconds) &nbsp;
								</label>
							{showtimerform ?
								<input
									name="ftimer"
									value={ftimers}
									className="timers"
									type="number"
									maxLength="3"
									onChange={(e)=>setFtimers(e.target.value)}
								/>
								: ""
							}
							</div>
						</Grid>
						<Grid item xs={5} className="cccenter">
							<label>
							<input type="radio" name="timer" value="NoTimer" onChange={()=>{
								setShowtimerform(false);
								setFtimers(0)
							}} className="biggerradiotimer" /> No Timer
							</label>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={2}>
			Question : &nbsp;
				</Grid>
				<Grid item xs={10}>
				<textarea
					name="fquestion"
					defaultValue=""
					ref={register({ required: true, maxLength: 200})}
					className="quesmathinput"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					<p>Upload image:(option) </p>
				</Grid>
				<Grid item xs={10} className="leftside">
					<input type="file" onChange={handleFileChange}/>
				</Grid>
			</Grid>
			<div className="mathcccenter">
				<img src={selectedFile} className="pximages400"/>
			</div>
			<br/>
			<span onClick={()=>  window.open("https://www.desmos.com/", "_blank")} className="underlinetext"> Desmos </span> <span className="redthis"> After submission, you cannot make changes, but you have the option to recreate it. </span>
			<br/>
			<DesmosCalculator ref={addRef} />
			<br/>
			<br/>
			<br/>
			<Grid container spacing={0}>
				<Grid container spacing={0}>
					<Grid item xs={3}>
						<p className="biggertext">Correct answer:</p>
					</Grid>
					<Grid item xs={9} className="leftside">
						<input
							name="fanswer"
							defaultValue=""
							ref={register({ required: true, maxLength: 100})}
							className="fullline"
						/>
					</Grid>
				</Grid>
			</Grid>
			<br/>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={11} className="rightside">
					{opensmessage ?
					<input type="submit" value="Submit" className="answersubmit"/>
						:
						<p className="redthis"> {smessage} </p>
					}
				</Grid>
				<Grid item xs={1}>
				</Grid>
			</Grid>
		</form>
			</div>
			<br/>
			<br/>
			</>
			:""
	);
}
export default Mathfastanswer;

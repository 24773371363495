import React, {useEffect, useState} from "react";
import {API, Auth } from 'aws-amplify';
import {createAnswers, updatePlayslide} from '../../graphql/mutations';
import { useForm } from "react-hook-form";
import {getPlayslide} from "../../graphql/queries";
import { v4 as uuid } from 'uuid';
import "../css/answers.css";
import Grid from "@material-ui/core/Grid";

function Words({id, handleClose76}) {
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const [smessage, setSmessage] = useState("")
	const [opensmessage, setOpensmessage] = useState(true)
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, [id]);
	const {register, handleSubmit} = useForm();
	const reorderurl = `https://edu.reachum.com/wordcloudroom/${id}/${d}`;
	const editurl = `https://edu.reachum.com/wordcloudedit/${id}/${d}`;
	const onSubmit = async (data) => {
		try {
			const input = {
				id:d,
				ffname:data.ffname,
				fquestion:data.fquestion
			}
			await API.graphql({
				query: createAnswers,
				variables: {input}
			});
			const urldata = {
				rid:d,
				title:data.ffname,
				rurl: reorderurl,
				eurl: editurl
			};
			const allImages = [...images, urldata]
			const inputs = {
				id,
				playlistlinks: allImages
			}
			await API.graphql({
				query: updatePlayslide,
				variables: {input:inputs}
			})
			setOpensmessage(false);
			setSmessage("Submitted");
			setTimeout(() => {
				handleClose76()
			}, 1000)
		} catch (err) {
			console.log(err)
		}
	}

	return (
		isOwner === isUser ?
		<>
			<div className="answerlist">
				<div className="thiscenter">
				<h3>Word Cloud</h3>
				</div>

		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					Question name: &nbsp;
				</Grid>
				<Grid item xs={10}>
				<input
					name="ffname"
					defaultValue=""
					ref={register({ required: true, maxLength: 60 })}
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					Question: &nbsp;
				</Grid>
				<Grid item xs={10}>
				<textarea
					name="fquestion"
					defaultValue=""
					ref={register({ required: true, maxLength: 500})}
					className="quesname"
				/>
				</Grid>
			</Grid>
			<br/>
		<div className="rightside">
			{opensmessage ?
				<input type="submit" value="Submit" className="answersubmit"/>
				:
				<p className="redthis"> {smessage} </p>
			}
		</div>
		</form>
			</div>
			</>
			:""
	);
}
export default Words;

import React, {useEffect, useState, useRef} from "react";
import {API, Auth} from 'aws-amplify';
import {updateMultioptionsdesmoslist} from '../../../../graphql/mutations';
import {getMultioptionsdesmoslist, getPlayslide} from "../../../../graphql/queries";
import { useForm } from "react-hook-form";
import {useParams} from "react-router-dom";
import { v4 as uuid } from 'uuid';
import "../../../css/poll.css";
import DeleteIcon from "../../../../img/dash/Delete-Button-X.png";
import Add from "../../../../img/dash/Add-Button.png";
import Grid from "@material-ui/core/Grid";
import DesmosCalculator from '../DesmosCalculator';
import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			400,
			400,
			"JPEG",
			95,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});

function Multidesmosedit() {
	const { id,rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [options, setOptions] = useState([]);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [mques, setMques] = useState("");
	const [isshowmath, setIsshowmath]= useState(false);
	const [isshowmathph, setIsshowmathph] = useState(true);
	const [dname, setDname] = useState("");
	const [mtimer, setMtimer] = useState(0);
	const [newOption, setNewOption] = useState(''); // Input field for adding new options
	const addRef = useRef(null)
	const [selectedFile, setSelectedFile] = useState("");
	const [showtimerform, setShowtimerform] = useState(false)
	const d = uuid();
	const {register, control, handleSubmit} = useForm({
		defaultValues: {
			ptest: [{panswer: ""}]
		}
	});
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
		})()
	}, []);
	useEffect( () => {
		(async() => {
			const response = await API.graphql({
				query: getMultioptionsdesmoslist,
				variables:{id:rid}
			})
			const datas = response.data.getMultioptionsdesmoslist;
			const dd = datas.mfname;
			setDname(dd);
			const dc = datas.mquestion
			setMques(dc)
			const tt = datas.mtimer;
			if(tt !== 0) {
				setMtimer(tt)
				setShowtimerform(true)
			}
			const mimage = datas.image;
			if(mimage !== null){
				setSelectedFile(mimage)
			}
		})()
	}, []);

	const handleAddOption = async () => {
		if (newOption.trim() !== '') {
			const calculatorState = addRef.current.getState();
			if(calculatorState){
				const latexExpressions = calculatorState.expressions.list.filter(expression => {
					return expression.latex != null
				})
				let resized;
				if (latexExpressions.length === 0) {
					resized = "";
					setIsshowmathph(false)
				} else {
					const screenshot = addRef.current.screenshot()
					const response = await fetch(screenshot)
					const blob = await response.blob()
					resized = await resizeFile(blob);
					setIsshowmath(true)
				}
				setOptions([...options, {panswer: newOption, checkans: false, mathstate: resized}]);
				setNewOption('');
			}else{
				setOptions([...options, {panswer: newOption, checkans: false}]);
				setNewOption('');
			}
		}
	};
	const handleOptionChange = (index) => {
		// Toggle the checkbox state for the selected option
		const updatedOptions = [...options];
		updatedOptions[index].checkans = !updatedOptions[index].checkans;
		setOptions(updatedOptions);
	};
	const handleFileChange = async (event) => {
		const img = event.target.files[0];
		const resized = await resizeFile(img);
		setSelectedFile(resized)
	};
	const handleDeleteOption = (index) => {
		// Remove the selected option from the options state
		const updatedOptions = [...options];
		updatedOptions.splice(index, 1);
		setOptions(updatedOptions);
	};
	const tt = `${d}1`;
	const ccu = `${d}333`;

	const onSubmit = async (data) => {
		const arrayOfObjectsWithId = options.map((obj, index) => ({ id: index, ...obj }));
		const arrayOfObjectsWithoutcheckbox = arrayOfObjectsWithId.map(({ checkans, ...rest }) => rest);
		const arrayOfObjects = options.map(({ panswer,mathstate, ...rest }) => rest);
		if(options.length !== 0) {
			try {
				const input = {
					id: rid,
					mquestion: data.pquestion,
					mtimer: mtimer,
					mtest: arrayOfObjectsWithoutcheckbox,
					manswer: arrayOfObjects,
					image:selectedFile
				}
				await API.graphql({
					query: updateMultioptionsdesmoslist,
					variables: {input}
				});
				setUploadedmessage("Updated ")
			} catch (err) {
				console.log(err)
			}
		}else{
			const thism = "Please check the correct ones. "
			setUploadedmessage(thism)
		}
	}

	return (
		isOwner === isUser ?
		<>
			<br/>
			<div className="dragdroppage">
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={0}>
						<Grid item xs={3}>
					<p>Game name : &nbsp;</p>
						</Grid>
						<Grid item xs={9}>
							{dname}
						</Grid>
					</Grid>
<br/>
					<Grid container spacing={0}>
						<Grid item xs={2}>
							Timer : &nbsp;
						</Grid>
						<Grid item xs={10}>
							<Grid container spacing={0}>
								<Grid item xs={7} className="cccenter">
									<input type="radio" name="timer" value="AddTimer" onChange={()=>{setShowtimerform(true)}}/> Add Timer (Seconds) &nbsp;
									{showtimerform ?
										<input
											value={mtimer}
											className="dragdroptimer"
											type="number"
											maxLength="3"
											onChange={(e)=>setMtimer(e.target.value)}
										/>
										: ""
									}
								</Grid>
								<Grid item xs={5} className="cccenter">
									<input type="radio" name="timer" value="NoTimer" onChange={()=>{
										setShowtimerform(false);
										setMtimer(0)
									}} /> No Timer
								</Grid>
							</Grid>
						</Grid>
					</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
			Question : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<textarea
					name="pquestion"
					defaultValue={mques}
					ref={register({ required: true, maxLength:200 })}
					className="quesmathinput"
				/>
				</Grid>
			</Grid>
			<br/>
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<p>Upload image: </p>
						</Grid>
						<Grid item xs={9} className="leftside">
							<input type="file" onChange={handleFileChange}/>
						</Grid>
					</Grid>
					<div className="mathcccenter">
						<img src={selectedFile} className="pximages400"/>
					</div>
					<br/>
					<br/>
			<div className="centerside">
				<h4>Options:</h4>
			</div>
				{options.map((option, index) => (
					<div key={index} className="centerside">
						<Grid container spacing={0}>
							<Grid item xs={10}>
						<label>
							<Grid container spacing={0}>
								<Grid item xs={2} className="rightside">
							<input
								type="checkbox"
								checked={option.checkans}
								onChange={() => handleOptionChange(index)}
								className="multicheckbox"
							/>
								</Grid>
								<Grid item xs={5}>
									<p>{option.panswer}</p>
								</Grid>
								<Grid item xs={5}>
									{isshowmath ?
										<img src={option.mathstate} className="borderthis" alt="graphics"/>
										: ""
									}
								</Grid>
							</Grid>
						</label>
							</Grid>
							<Grid item xs={2} className="leftside">
								<img src={DeleteIcon} onClick={() => handleDeleteOption(index)} alt="delete"/>
							</Grid>
						</Grid>
					</div>
				))}
			<hr/>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={11} className="rightside">
					<span className="redthis">{uploadedmessage} </span> <input type="submit" value="Update" id="summitb" />
				</Grid>
				<Grid item xs={1}>
				</Grid>
			</Grid>
		</form>
				<div>
					<h4>Add Options:</h4>
					<input
						type="text"
						placeholder="Text required"
						value={newOption}
						onChange={(e) => setNewOption(e.target.value)}
						className="quesmath"
					/>
					<br/>
					<br/>
					{isshowmathph ?
						<DesmosCalculator ref={addRef}/>
						: ""
					}
					<br/>
					<Grid container spacing={0}>
						<Grid item xs={1} className="rightside">
						</Grid>
						<Grid item xs={11}>
					<div className="upgradenew3" id="3">
					<img src={Add} onClick={handleAddOption} width="40px"/>
						<span className="tooltiptext3">Click here to add options.</span>
					</div>
						</Grid>
					</Grid>
				</div>
			</div>
			<br/>
			<br/>
			<br/>
			</>
			:""
	);
}
export default Multidesmosedit;

import React from "react";
import Bigsmile from "../../../img/smile.gif";

function Sedit(){

    return (
        <>
            <div className="thiscenter">
                <br/>
                <br/>
                <img src={Bigsmile} />
            </div>
        </>


    );
};

export default Sedit

import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePlayslide} from '../../../graphql/mutations';
import { getPlayslide } from "../../../graphql/queries";
import { UploadFiles } from './FileUpload';
import { processFile } from './fileService';
import "../../css/uploadimg.css";
import {Card} from "react-bootstrap";
import {v4 as uuid} from "uuid";

const allowedMediaSourceTypes = ['.jpeg','.png','.jpg','.svg','.webp','.bmp','.tif','.tiff','.eps'];

function Uploadimg({id, handleClose69}) {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [images, setImages] = useState([]);
    const [error, setError] = useState('');
    const [smessage, setSmessage] = useState("")
    const [opensmessage, setOpensmessage] = useState(true)
    const rid = uuid();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);

    const upload = async (files) => {
        setProcessing(true);
        setError('');
        let arrays = [];
        for (var j = 0; j < files.length; j++) {
            try {
                const MAX_FILE_SIZE = 5120;
                const file = files[j];
                const fileSizeKiloBytes = file.size / 1024;
                if(fileSizeKiloBytes > MAX_FILE_SIZE){
                    setError("File size is greater than maximum limit");
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
                const processedFile = await processFile(file);
                const results = processedFile.images;
                const pptxtoimage = results.map(({name, location}) => {
                    return {
                        title: "image",
                        lurl: `https://edureachum200445-dev.s3.amazonaws.com/public/img/${name}`
                    };
                })
                const newpptx = pptxtoimage.map((r, i) => ({
                    ...r,
                    rid: `img${rid}${j}`,
                    rurl: `https://edu.reachum.com/imgshowroom/${id}/img${rid}${j}`,
                    eurl: `https://edu.reachum.com/imgedit/${id}/img${rid}${j}`
                }));
                arrays.push(newpptx[0]);
            } catch (err) {
                console.error(err);
                setError(`Unable to process file`)
            }
        }
        setProcessing(false);
        const allImages = [...images].concat(arrays);
        const input = {
            id,
            playlistlinks: allImages
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setOpensmessage(false);
        setSmessage("Uploaded");
        setTimeout(() => {
            handleClose69()
        }, 1000)
    }

    return (
        isOwner === isUser ?
        <>
            <div className="uploadpptx">
                <Card className="text-center">
                    <Card.Header as="h6">Upload Image (5MB limit) <br/> You can upload multiple images at once.</Card.Header>
                    <Card.Body>
                    {error && <h5>{error}</h5>}
                    <UploadFiles processing={processing} allowedFileTypes={allowedMediaSourceTypes} onUpload={upload} />
                    </Card.Body>
                </Card>
                {opensmessage ?
                    ""
                    :
                    <div className="cccenter">
                        <p className="redthis"> {smessage} </p>
                    </div>
                }
            </div>
        </>
            :""
    );
}

export default Uploadimg;

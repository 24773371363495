/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createHost = /* GraphQL */ `
  mutation CreateHost(
    $input: CreateHostInput!
    $condition: ModelHostConditionInput
  ) {
    createHost(input: $input, condition: $condition) {
      id
      owner
      email
      capacity
      usage
      status
      refer
      shareemail {
        email
      }
      sharecode
      statustime
      createdAt
      updatedAt
    }
  }
`;
export const updateHost = /* GraphQL */ `
  mutation UpdateHost(
    $input: UpdateHostInput!
    $condition: ModelHostConditionInput
  ) {
    updateHost(input: $input, condition: $condition) {
      id
      owner
      email
      capacity
      usage
      status
      refer
      shareemail {
        email
      }
      sharecode
      statustime
      createdAt
      updatedAt
    }
  }
`;
export const deleteHost = /* GraphQL */ `
  mutation DeleteHost(
    $input: DeleteHostInput!
    $condition: ModelHostConditionInput
  ) {
    deleteHost(input: $input, condition: $condition) {
      id
      owner
      email
      capacity
      usage
      status
      refer
      shareemail {
        email
      }
      sharecode
      statustime
      createdAt
      updatedAt
    }
  }
`;
export const createPlayslide = /* GraphQL */ `
  mutation CreatePlayslide(
    $input: CreatePlayslideInput!
    $condition: ModelPlayslideConditionInput
  ) {
    createPlayslide(input: $input, condition: $condition) {
      id
      meetingname
      playlistlinks {
        rid
        title
        rurl
        lurl
        eurl
        vtimer
        adobeprojectid
        file {
          bucket
          key
          region
        }
      }
      myrlink
      clientrlink
      ctime
      counts
      mid
      clink
      exiton
      qrstring
      desmosmathstate
      fulls
      vplaypause
      aplaypause
      group
      limitpeople
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updatePlayslide = /* GraphQL */ `
  mutation UpdatePlayslide(
    $input: UpdatePlayslideInput!
    $condition: ModelPlayslideConditionInput
  ) {
    updatePlayslide(input: $input, condition: $condition) {
      id
      meetingname
      playlistlinks {
        rid
        title
        rurl
        lurl
        eurl
        vtimer
        adobeprojectid
        file {
          bucket
          key
          region
        }
      }
      myrlink
      clientrlink
      ctime
      counts
      mid
      clink
      exiton
      qrstring
      desmosmathstate
      fulls
      vplaypause
      aplaypause
      group
      limitpeople
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deletePlayslide = /* GraphQL */ `
  mutation DeletePlayslide(
    $input: DeletePlayslideInput!
    $condition: ModelPlayslideConditionInput
  ) {
    deletePlayslide(input: $input, condition: $condition) {
      id
      meetingname
      playlistlinks {
        rid
        title
        rurl
        lurl
        eurl
        vtimer
        adobeprojectid
        file {
          bucket
          key
          region
        }
      }
      myrlink
      clientrlink
      ctime
      counts
      mid
      clink
      exiton
      qrstring
      desmosmathstate
      fulls
      vplaypause
      aplaypause
      group
      limitpeople
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createFolders = /* GraphQL */ `
  mutation CreateFolders(
    $input: CreateFoldersInput!
    $condition: ModelFoldersConditionInput
  ) {
    createFolders(input: $input, condition: $condition) {
      id
      folderlists
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateFolders = /* GraphQL */ `
  mutation UpdateFolders(
    $input: UpdateFoldersInput!
    $condition: ModelFoldersConditionInput
  ) {
    updateFolders(input: $input, condition: $condition) {
      id
      folderlists
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteFolders = /* GraphQL */ `
  mutation DeleteFolders(
    $input: DeleteFoldersInput!
    $condition: ModelFoldersConditionInput
  ) {
    deleteFolders(input: $input, condition: $condition) {
      id
      folderlists
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createDesktopitems = /* GraphQL */ `
  mutation CreateDesktopitems(
    $input: CreateDesktopitemsInput!
    $condition: ModelDesktopitemsConditionInput
  ) {
    createDesktopitems(input: $input, condition: $condition) {
      id
      deskitems
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateDesktopitems = /* GraphQL */ `
  mutation UpdateDesktopitems(
    $input: UpdateDesktopitemsInput!
    $condition: ModelDesktopitemsConditionInput
  ) {
    updateDesktopitems(input: $input, condition: $condition) {
      id
      deskitems
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteDesktopitems = /* GraphQL */ `
  mutation DeleteDesktopitems(
    $input: DeleteDesktopitemsInput!
    $condition: ModelDesktopitemsConditionInput
  ) {
    deleteDesktopitems(input: $input, condition: $condition) {
      id
      deskitems
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createPptxFile = /* GraphQL */ `
  mutation CreatePptxFile(
    $input: CreatePptxFileInput!
    $condition: ModelPptxFileConditionInput
  ) {
    createPptxFile(input: $input, condition: $condition) {
      id
      name
      uploadedFile
      status
      images {
        name
        location
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePptxFile = /* GraphQL */ `
  mutation UpdatePptxFile(
    $input: UpdatePptxFileInput!
    $condition: ModelPptxFileConditionInput
  ) {
    updatePptxFile(input: $input, condition: $condition) {
      id
      name
      uploadedFile
      status
      images {
        name
        location
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePptxFile = /* GraphQL */ `
  mutation DeletePptxFile(
    $input: DeletePptxFileInput!
    $condition: ModelPptxFileConditionInput
  ) {
    deletePptxFile(input: $input, condition: $condition) {
      id
      name
      uploadedFile
      status
      images {
        name
        location
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGiphyGif = /* GraphQL */ `
  mutation CreateGiphyGif(
    $input: CreateGiphyGifInput!
    $condition: ModelGiphyGifConditionInput
  ) {
    createGiphyGif(input: $input, condition: $condition) {
      id
      title
      url
      createdAt
      updatedAt
    }
  }
`;
export const updateGiphyGif = /* GraphQL */ `
  mutation UpdateGiphyGif(
    $input: UpdateGiphyGifInput!
    $condition: ModelGiphyGifConditionInput
  ) {
    updateGiphyGif(input: $input, condition: $condition) {
      id
      title
      url
      createdAt
      updatedAt
    }
  }
`;
export const deleteGiphyGif = /* GraphQL */ `
  mutation DeleteGiphyGif(
    $input: DeleteGiphyGifInput!
    $condition: ModelGiphyGifConditionInput
  ) {
    deleteGiphyGif(input: $input, condition: $condition) {
      id
      title
      url
      createdAt
      updatedAt
    }
  }
`;
export const createPolllist = /* GraphQL */ `
  mutation CreatePolllist(
    $input: CreatePolllistInput!
    $condition: ModelPolllistConditionInput
  ) {
    createPolllist(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      pquestion
      pimage
      ptest {
        id
        panswer
        pimage
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePolllist = /* GraphQL */ `
  mutation UpdatePolllist(
    $input: UpdatePolllistInput!
    $condition: ModelPolllistConditionInput
  ) {
    updatePolllist(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      pquestion
      pimage
      ptest {
        id
        panswer
        pimage
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePolllist = /* GraphQL */ `
  mutation DeletePolllist(
    $input: DeletePolllistInput!
    $condition: ModelPolllistConditionInput
  ) {
    deletePolllist(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      pquestion
      pimage
      ptest {
        id
        panswer
        pimage
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPolls = /* GraphQL */ `
  mutation CreatePolls(
    $input: CreatePollsInput!
    $condition: ModelPollsConditionInput
  ) {
    createPolls(input: $input, condition: $condition) {
      id
      PolllistId
      panswer
      createdAt
      updatedAt
    }
  }
`;
export const updatePolls = /* GraphQL */ `
  mutation UpdatePolls(
    $input: UpdatePollsInput!
    $condition: ModelPollsConditionInput
  ) {
    updatePolls(input: $input, condition: $condition) {
      id
      PolllistId
      panswer
      createdAt
      updatedAt
    }
  }
`;
export const deletePolls = /* GraphQL */ `
  mutation DeletePolls(
    $input: DeletePollsInput!
    $condition: ModelPollsConditionInput
  ) {
    deletePolls(input: $input, condition: $condition) {
      id
      PolllistId
      panswer
      createdAt
      updatedAt
    }
  }
`;
export const createFasteranswer = /* GraphQL */ `
  mutation CreateFasteranswer(
    $input: CreateFasteranswerInput!
    $condition: ModelFasteranswerConditionInput
  ) {
    createFasteranswer(input: $input, condition: $condition) {
      id
      ftimer
      ffname
      fquestion
      fimage
      fanswer
      createdAt
      updatedAt
    }
  }
`;
export const updateFasteranswer = /* GraphQL */ `
  mutation UpdateFasteranswer(
    $input: UpdateFasteranswerInput!
    $condition: ModelFasteranswerConditionInput
  ) {
    updateFasteranswer(input: $input, condition: $condition) {
      id
      ftimer
      ffname
      fquestion
      fimage
      fanswer
      createdAt
      updatedAt
    }
  }
`;
export const deleteFasteranswer = /* GraphQL */ `
  mutation DeleteFasteranswer(
    $input: DeleteFasteranswerInput!
    $condition: ModelFasteranswerConditionInput
  ) {
    deleteFasteranswer(input: $input, condition: $condition) {
      id
      ftimer
      ffname
      fquestion
      fimage
      fanswer
      createdAt
      updatedAt
    }
  }
`;
export const createOptionslist = /* GraphQL */ `
  mutation CreateOptionslist(
    $input: CreateOptionslistInput!
    $condition: ModelOptionslistConditionInput
  ) {
    createOptionslist(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      pquestion
      pimage
      ptest {
        id
        panswer
        pimage
      }
      oanswer
      createdAt
      updatedAt
    }
  }
`;
export const updateOptionslist = /* GraphQL */ `
  mutation UpdateOptionslist(
    $input: UpdateOptionslistInput!
    $condition: ModelOptionslistConditionInput
  ) {
    updateOptionslist(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      pquestion
      pimage
      ptest {
        id
        panswer
        pimage
      }
      oanswer
      createdAt
      updatedAt
    }
  }
`;
export const deleteOptionslist = /* GraphQL */ `
  mutation DeleteOptionslist(
    $input: DeleteOptionslistInput!
    $condition: ModelOptionslistConditionInput
  ) {
    deleteOptionslist(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      pquestion
      pimage
      ptest {
        id
        panswer
        pimage
      }
      oanswer
      createdAt
      updatedAt
    }
  }
`;
export const createTextslide = /* GraphQL */ `
  mutation CreateTextslide(
    $input: CreateTextslideInput!
    $condition: ModelTextslideConditionInput
  ) {
    createTextslide(input: $input, condition: $condition) {
      id
      pfname
      ptest {
        id
        plist
      }
      titlecolor
      listcolor
      bgcolor
      createdAt
      updatedAt
    }
  }
`;
export const updateTextslide = /* GraphQL */ `
  mutation UpdateTextslide(
    $input: UpdateTextslideInput!
    $condition: ModelTextslideConditionInput
  ) {
    updateTextslide(input: $input, condition: $condition) {
      id
      pfname
      ptest {
        id
        plist
      }
      titlecolor
      listcolor
      bgcolor
      createdAt
      updatedAt
    }
  }
`;
export const deleteTextslide = /* GraphQL */ `
  mutation DeleteTextslide(
    $input: DeleteTextslideInput!
    $condition: ModelTextslideConditionInput
  ) {
    deleteTextslide(input: $input, condition: $condition) {
      id
      pfname
      ptest {
        id
        plist
      }
      titlecolor
      listcolor
      bgcolor
      createdAt
      updatedAt
    }
  }
`;
export const createDraganddrop = /* GraphQL */ `
  mutation CreateDraganddrop(
    $input: CreateDraganddropInput!
    $condition: ModelDraganddropConditionInput
  ) {
    createDraganddrop(input: $input, condition: $condition) {
      id
      dname
      dtimer
      dquestion
      categories {
        name
        items {
          text
          image
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDraganddrop = /* GraphQL */ `
  mutation UpdateDraganddrop(
    $input: UpdateDraganddropInput!
    $condition: ModelDraganddropConditionInput
  ) {
    updateDraganddrop(input: $input, condition: $condition) {
      id
      dname
      dtimer
      dquestion
      categories {
        name
        items {
          text
          image
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDraganddrop = /* GraphQL */ `
  mutation DeleteDraganddrop(
    $input: DeleteDraganddropInput!
    $condition: ModelDraganddropConditionInput
  ) {
    deleteDraganddrop(input: $input, condition: $condition) {
      id
      dname
      dtimer
      dquestion
      categories {
        name
        items {
          text
          image
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFlipcard = /* GraphQL */ `
  mutation CreateFlipcard(
    $input: CreateFlipcardInput!
    $condition: ModelFlipcardConditionInput
  ) {
    createFlipcard(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      fwords {
        type
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFlipcard = /* GraphQL */ `
  mutation UpdateFlipcard(
    $input: UpdateFlipcardInput!
    $condition: ModelFlipcardConditionInput
  ) {
    updateFlipcard(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      fwords {
        type
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFlipcard = /* GraphQL */ `
  mutation DeleteFlipcard(
    $input: DeleteFlipcardInput!
    $condition: ModelFlipcardConditionInput
  ) {
    deleteFlipcard(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      fwords {
        type
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMultioptionslist = /* GraphQL */ `
  mutation CreateMultioptionslist(
    $input: CreateMultioptionslistInput!
    $condition: ModelMultioptionslistConditionInput
  ) {
    createMultioptionslist(input: $input, condition: $condition) {
      id
      mfname
      mtimer
      mquestion
      mimage
      mtest {
        id
        panswer
        pimage
      }
      manswer {
        checkans
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMultioptionslist = /* GraphQL */ `
  mutation UpdateMultioptionslist(
    $input: UpdateMultioptionslistInput!
    $condition: ModelMultioptionslistConditionInput
  ) {
    updateMultioptionslist(input: $input, condition: $condition) {
      id
      mfname
      mtimer
      mquestion
      mimage
      mtest {
        id
        panswer
        pimage
      }
      manswer {
        checkans
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMultioptionslist = /* GraphQL */ `
  mutation DeleteMultioptionslist(
    $input: DeleteMultioptionslistInput!
    $condition: ModelMultioptionslistConditionInput
  ) {
    deleteMultioptionslist(input: $input, condition: $condition) {
      id
      mfname
      mtimer
      mquestion
      mimage
      mtest {
        id
        panswer
        pimage
      }
      manswer {
        checkans
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVideooptionslist = /* GraphQL */ `
  mutation CreateVideooptionslist(
    $input: CreateVideooptionslistInput!
    $condition: ModelVideooptionslistConditionInput
  ) {
    createVideooptionslist(input: $input, condition: $condition) {
      id
      videoid
      vfname
      vtimer
      vquestion
      vvideo {
        bucket
        key
        region
      }
      vsettime
      vtest {
        id
        panswer
      }
      vanswer
      createdAt
      updatedAt
    }
  }
`;
export const updateVideooptionslist = /* GraphQL */ `
  mutation UpdateVideooptionslist(
    $input: UpdateVideooptionslistInput!
    $condition: ModelVideooptionslistConditionInput
  ) {
    updateVideooptionslist(input: $input, condition: $condition) {
      id
      videoid
      vfname
      vtimer
      vquestion
      vvideo {
        bucket
        key
        region
      }
      vsettime
      vtest {
        id
        panswer
      }
      vanswer
      createdAt
      updatedAt
    }
  }
`;
export const deleteVideooptionslist = /* GraphQL */ `
  mutation DeleteVideooptionslist(
    $input: DeleteVideooptionslistInput!
    $condition: ModelVideooptionslistConditionInput
  ) {
    deleteVideooptionslist(input: $input, condition: $condition) {
      id
      videoid
      vfname
      vtimer
      vquestion
      vvideo {
        bucket
        key
        region
      }
      vsettime
      vtest {
        id
        panswer
      }
      vanswer
      createdAt
      updatedAt
    }
  }
`;
export const createOptionsmathlist = /* GraphQL */ `
  mutation CreateOptionsmathlist(
    $input: CreateOptionsmathlistInput!
    $condition: ModelOptionsmathlistConditionInput
  ) {
    createOptionsmathlist(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      pquestion
      mathques
      image
      ptest {
        id
        panswer
        mathanswer
      }
      oanswer
      bgimg
      createdAt
      updatedAt
    }
  }
`;
export const updateOptionsmathlist = /* GraphQL */ `
  mutation UpdateOptionsmathlist(
    $input: UpdateOptionsmathlistInput!
    $condition: ModelOptionsmathlistConditionInput
  ) {
    updateOptionsmathlist(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      pquestion
      mathques
      image
      ptest {
        id
        panswer
        mathanswer
      }
      oanswer
      bgimg
      createdAt
      updatedAt
    }
  }
`;
export const deleteOptionsmathlist = /* GraphQL */ `
  mutation DeleteOptionsmathlist(
    $input: DeleteOptionsmathlistInput!
    $condition: ModelOptionsmathlistConditionInput
  ) {
    deleteOptionsmathlist(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      pquestion
      mathques
      image
      ptest {
        id
        panswer
        mathanswer
      }
      oanswer
      bgimg
      createdAt
      updatedAt
    }
  }
`;
export const createOptionsdrawlist = /* GraphQL */ `
  mutation CreateOptionsdrawlist(
    $input: CreateOptionsdrawlistInput!
    $condition: ModelOptionsdrawlistConditionInput
  ) {
    createOptionsdrawlist(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      pquestion
      drawques
      ptest {
        id
        panswer
      }
      oanswer
      bgimg
      createdAt
      updatedAt
    }
  }
`;
export const updateOptionsdrawlist = /* GraphQL */ `
  mutation UpdateOptionsdrawlist(
    $input: UpdateOptionsdrawlistInput!
    $condition: ModelOptionsdrawlistConditionInput
  ) {
    updateOptionsdrawlist(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      pquestion
      drawques
      ptest {
        id
        panswer
      }
      oanswer
      bgimg
      createdAt
      updatedAt
    }
  }
`;
export const deleteOptionsdrawlist = /* GraphQL */ `
  mutation DeleteOptionsdrawlist(
    $input: DeleteOptionsdrawlistInput!
    $condition: ModelOptionsdrawlistConditionInput
  ) {
    deleteOptionsdrawlist(input: $input, condition: $condition) {
      id
      pfname
      ptimer
      pquestion
      drawques
      ptest {
        id
        panswer
      }
      oanswer
      bgimg
      createdAt
      updatedAt
    }
  }
`;
export const createLeaderboard = /* GraphQL */ `
  mutation CreateLeaderboard(
    $input: CreateLeaderboardInput!
    $condition: ModelLeaderboardConditionInput
  ) {
    createLeaderboard(input: $input, condition: $condition) {
      id
      name
      meetemail
      answerid
      questions {
        rid
        question
        correctanswer
        thisscore
        duration
        answer
        timerin
        timerout
        thisstatus
      }
      morereport
      score
      thisgamescore
      status
      duration
      timerin
      timerout
      createdAt
      updatedAt
    }
  }
`;
export const updateLeaderboard = /* GraphQL */ `
  mutation UpdateLeaderboard(
    $input: UpdateLeaderboardInput!
    $condition: ModelLeaderboardConditionInput
  ) {
    updateLeaderboard(input: $input, condition: $condition) {
      id
      name
      meetemail
      answerid
      questions {
        rid
        question
        correctanswer
        thisscore
        duration
        answer
        timerin
        timerout
        thisstatus
      }
      morereport
      score
      thisgamescore
      status
      duration
      timerin
      timerout
      createdAt
      updatedAt
    }
  }
`;
export const deleteLeaderboard = /* GraphQL */ `
  mutation DeleteLeaderboard(
    $input: DeleteLeaderboardInput!
    $condition: ModelLeaderboardConditionInput
  ) {
    deleteLeaderboard(input: $input, condition: $condition) {
      id
      name
      meetemail
      answerid
      questions {
        rid
        question
        correctanswer
        thisscore
        duration
        answer
        timerin
        timerout
        thisstatus
      }
      morereport
      score
      thisgamescore
      status
      duration
      timerin
      timerout
      createdAt
      updatedAt
    }
  }
`;
export const createSelfstudytimer = /* GraphQL */ `
  mutation CreateSelfstudytimer(
    $input: CreateSelfstudytimerInput!
    $condition: ModelSelfstudytimerConditionInput
  ) {
    createSelfstudytimer(input: $input, condition: $condition) {
      id
      name
      meetemail
      roomid
      status
      timer {
        eachtimer
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSelfstudytimer = /* GraphQL */ `
  mutation UpdateSelfstudytimer(
    $input: UpdateSelfstudytimerInput!
    $condition: ModelSelfstudytimerConditionInput
  ) {
    updateSelfstudytimer(input: $input, condition: $condition) {
      id
      name
      meetemail
      roomid
      status
      timer {
        eachtimer
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSelfstudytimer = /* GraphQL */ `
  mutation DeleteSelfstudytimer(
    $input: DeleteSelfstudytimerInput!
    $condition: ModelSelfstudytimerConditionInput
  ) {
    deleteSelfstudytimer(input: $input, condition: $condition) {
      id
      name
      meetemail
      roomid
      status
      timer {
        eachtimer
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEngagement = /* GraphQL */ `
  mutation CreateEngagement(
    $input: CreateEngagementInput!
    $condition: ModelEngagementConditionInput
  ) {
    createEngagement(input: $input, condition: $condition) {
      id
      questionid
      engage
      people
      createdAt
      updatedAt
    }
  }
`;
export const updateEngagement = /* GraphQL */ `
  mutation UpdateEngagement(
    $input: UpdateEngagementInput!
    $condition: ModelEngagementConditionInput
  ) {
    updateEngagement(input: $input, condition: $condition) {
      id
      questionid
      engage
      people
      createdAt
      updatedAt
    }
  }
`;
export const deleteEngagement = /* GraphQL */ `
  mutation DeleteEngagement(
    $input: DeleteEngagementInput!
    $condition: ModelEngagementConditionInput
  ) {
    deleteEngagement(input: $input, condition: $condition) {
      id
      questionid
      engage
      people
      createdAt
      updatedAt
    }
  }
`;
export const createAnswers = /* GraphQL */ `
  mutation CreateAnswers(
    $input: CreateAnswersInput!
    $condition: ModelAnswersConditionInput
  ) {
    createAnswers(input: $input, condition: $condition) {
      id
      ffname
      fquestion
      createdAt
      updatedAt
    }
  }
`;
export const updateAnswers = /* GraphQL */ `
  mutation UpdateAnswers(
    $input: UpdateAnswersInput!
    $condition: ModelAnswersConditionInput
  ) {
    updateAnswers(input: $input, condition: $condition) {
      id
      ffname
      fquestion
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnswers = /* GraphQL */ `
  mutation DeleteAnswers(
    $input: DeleteAnswersInput!
    $condition: ModelAnswersConditionInput
  ) {
    deleteAnswers(input: $input, condition: $condition) {
      id
      ffname
      fquestion
      createdAt
      updatedAt
    }
  }
`;
export const createAnswerslist = /* GraphQL */ `
  mutation CreateAnswerslist(
    $input: CreateAnswerslistInput!
    $condition: ModelAnswerslistConditionInput
  ) {
    createAnswerslist(input: $input, condition: $condition) {
      id
      name
      email
      answerid
      answers
      aimgurl
      createdAt
      updatedAt
    }
  }
`;
export const updateAnswerslist = /* GraphQL */ `
  mutation UpdateAnswerslist(
    $input: UpdateAnswerslistInput!
    $condition: ModelAnswerslistConditionInput
  ) {
    updateAnswerslist(input: $input, condition: $condition) {
      id
      name
      email
      answerid
      answers
      aimgurl
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnswerslist = /* GraphQL */ `
  mutation DeleteAnswerslist(
    $input: DeleteAnswerslistInput!
    $condition: ModelAnswerslistConditionInput
  ) {
    deleteAnswerslist(input: $input, condition: $condition) {
      id
      name
      email
      answerid
      answers
      aimgurl
      createdAt
      updatedAt
    }
  }
`;
export const createHightlightanswers = /* GraphQL */ `
  mutation CreateHightlightanswers(
    $input: CreateHightlightanswersInput!
    $condition: ModelHightlightanswersConditionInput
  ) {
    createHightlightanswers(input: $input, condition: $condition) {
      id
      name
      thisanswer
      thgisaimgurl
      createdAt
      updatedAt
    }
  }
`;
export const updateHightlightanswers = /* GraphQL */ `
  mutation UpdateHightlightanswers(
    $input: UpdateHightlightanswersInput!
    $condition: ModelHightlightanswersConditionInput
  ) {
    updateHightlightanswers(input: $input, condition: $condition) {
      id
      name
      thisanswer
      thgisaimgurl
      createdAt
      updatedAt
    }
  }
`;
export const deleteHightlightanswers = /* GraphQL */ `
  mutation DeleteHightlightanswers(
    $input: DeleteHightlightanswersInput!
    $condition: ModelHightlightanswersConditionInput
  ) {
    deleteHightlightanswers(input: $input, condition: $condition) {
      id
      name
      thisanswer
      thgisaimgurl
      createdAt
      updatedAt
    }
  }
`;
export const createThumbvote = /* GraphQL */ `
  mutation CreateThumbvote(
    $input: CreateThumbvoteInput!
    $condition: ModelThumbvoteConditionInput
  ) {
    createThumbvote(input: $input, condition: $condition) {
      id
      answerlistid
      thumbup
      thumbdown
      createdAt
      updatedAt
    }
  }
`;
export const updateThumbvote = /* GraphQL */ `
  mutation UpdateThumbvote(
    $input: UpdateThumbvoteInput!
    $condition: ModelThumbvoteConditionInput
  ) {
    updateThumbvote(input: $input, condition: $condition) {
      id
      answerlistid
      thumbup
      thumbdown
      createdAt
      updatedAt
    }
  }
`;
export const deleteThumbvote = /* GraphQL */ `
  mutation DeleteThumbvote(
    $input: DeleteThumbvoteInput!
    $condition: ModelThumbvoteConditionInput
  ) {
    deleteThumbvote(input: $input, condition: $condition) {
      id
      answerlistid
      thumbup
      thumbdown
      createdAt
      updatedAt
    }
  }
`;
export const createAdobeexpress = /* GraphQL */ `
  mutation CreateAdobeexpress(
    $input: CreateAdobeexpressInput!
    $condition: ModelAdobeexpressConditionInput
  ) {
    createAdobeexpress(input: $input, condition: $condition) {
      id
      dname
      videofile {
        bucket
        key
        region
      }
      adobeprojectid
      adobeimags {
        data
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAdobeexpress = /* GraphQL */ `
  mutation UpdateAdobeexpress(
    $input: UpdateAdobeexpressInput!
    $condition: ModelAdobeexpressConditionInput
  ) {
    updateAdobeexpress(input: $input, condition: $condition) {
      id
      dname
      videofile {
        bucket
        key
        region
      }
      adobeprojectid
      adobeimags {
        data
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdobeexpress = /* GraphQL */ `
  mutation DeleteAdobeexpress(
    $input: DeleteAdobeexpressInput!
    $condition: ModelAdobeexpressConditionInput
  ) {
    deleteAdobeexpress(input: $input, condition: $condition) {
      id
      dname
      videofile {
        bucket
        key
        region
      }
      adobeprojectid
      adobeimags {
        data
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSequencinggame = /* GraphQL */ `
  mutation CreateSequencinggame(
    $input: CreateSequencinggameInput!
    $condition: ModelSequencinggameConditionInput
  ) {
    createSequencinggame(input: $input, condition: $condition) {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSequencinggame = /* GraphQL */ `
  mutation UpdateSequencinggame(
    $input: UpdateSequencinggameInput!
    $condition: ModelSequencinggameConditionInput
  ) {
    updateSequencinggame(input: $input, condition: $condition) {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSequencinggame = /* GraphQL */ `
  mutation DeleteSequencinggame(
    $input: DeleteSequencinggameInput!
    $condition: ModelSequencinggameConditionInput
  ) {
    deleteSequencinggame(input: $input, condition: $condition) {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDesmomath = /* GraphQL */ `
  mutation CreateDesmomath(
    $input: CreateDesmomathInput!
    $condition: ModelDesmomathConditionInput
  ) {
    createDesmomath(input: $input, condition: $condition) {
      id
      mathstate
      createdAt
      updatedAt
    }
  }
`;
export const updateDesmomath = /* GraphQL */ `
  mutation UpdateDesmomath(
    $input: UpdateDesmomathInput!
    $condition: ModelDesmomathConditionInput
  ) {
    updateDesmomath(input: $input, condition: $condition) {
      id
      mathstate
      createdAt
      updatedAt
    }
  }
`;
export const deleteDesmomath = /* GraphQL */ `
  mutation DeleteDesmomath(
    $input: DeleteDesmomathInput!
    $condition: ModelDesmomathConditionInput
  ) {
    deleteDesmomath(input: $input, condition: $condition) {
      id
      mathstate
      createdAt
      updatedAt
    }
  }
`;
export const createDesmomathslide = /* GraphQL */ `
  mutation CreateDesmomathslide(
    $input: CreateDesmomathslideInput!
    $condition: ModelDesmomathslideConditionInput
  ) {
    createDesmomathslide(input: $input, condition: $condition) {
      id
      mathstate
      graphic
      image
      createdAt
      updatedAt
    }
  }
`;
export const updateDesmomathslide = /* GraphQL */ `
  mutation UpdateDesmomathslide(
    $input: UpdateDesmomathslideInput!
    $condition: ModelDesmomathslideConditionInput
  ) {
    updateDesmomathslide(input: $input, condition: $condition) {
      id
      mathstate
      graphic
      image
      createdAt
      updatedAt
    }
  }
`;
export const deleteDesmomathslide = /* GraphQL */ `
  mutation DeleteDesmomathslide(
    $input: DeleteDesmomathslideInput!
    $condition: ModelDesmomathslideConditionInput
  ) {
    deleteDesmomathslide(input: $input, condition: $condition) {
      id
      mathstate
      graphic
      image
      createdAt
      updatedAt
    }
  }
`;
export const createSequencingdesmos = /* GraphQL */ `
  mutation CreateSequencingdesmos(
    $input: CreateSequencingdesmosInput!
    $condition: ModelSequencingdesmosConditionInput
  ) {
    createSequencingdesmos(input: $input, condition: $condition) {
      id
      dname
      dtimer
      dquestion
      qmathstate
      image
      orders {
        id
        text
        mathstate
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSequencingdesmos = /* GraphQL */ `
  mutation UpdateSequencingdesmos(
    $input: UpdateSequencingdesmosInput!
    $condition: ModelSequencingdesmosConditionInput
  ) {
    updateSequencingdesmos(input: $input, condition: $condition) {
      id
      dname
      dtimer
      dquestion
      qmathstate
      image
      orders {
        id
        text
        mathstate
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSequencingdesmos = /* GraphQL */ `
  mutation DeleteSequencingdesmos(
    $input: DeleteSequencingdesmosInput!
    $condition: ModelSequencingdesmosConditionInput
  ) {
    deleteSequencingdesmos(input: $input, condition: $condition) {
      id
      dname
      dtimer
      dquestion
      qmathstate
      image
      orders {
        id
        text
        mathstate
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDesmosfillin = /* GraphQL */ `
  mutation CreateDesmosfillin(
    $input: CreateDesmosfillinInput!
    $condition: ModelDesmosfillinConditionInput
  ) {
    createDesmosfillin(input: $input, condition: $condition) {
      id
      ftimer
      ffname
      fquestion
      mathstate
      image
      fanswer
      createdAt
      updatedAt
    }
  }
`;
export const updateDesmosfillin = /* GraphQL */ `
  mutation UpdateDesmosfillin(
    $input: UpdateDesmosfillinInput!
    $condition: ModelDesmosfillinConditionInput
  ) {
    updateDesmosfillin(input: $input, condition: $condition) {
      id
      ftimer
      ffname
      fquestion
      mathstate
      image
      fanswer
      createdAt
      updatedAt
    }
  }
`;
export const deleteDesmosfillin = /* GraphQL */ `
  mutation DeleteDesmosfillin(
    $input: DeleteDesmosfillinInput!
    $condition: ModelDesmosfillinConditionInput
  ) {
    deleteDesmosfillin(input: $input, condition: $condition) {
      id
      ftimer
      ffname
      fquestion
      mathstate
      image
      fanswer
      createdAt
      updatedAt
    }
  }
`;
export const createMultioptionsdesmoslist = /* GraphQL */ `
  mutation CreateMultioptionsdesmoslist(
    $input: CreateMultioptionsdesmoslistInput!
    $condition: ModelMultioptionsdesmoslistConditionInput
  ) {
    createMultioptionsdesmoslist(input: $input, condition: $condition) {
      id
      mfname
      mtimer
      mquestion
      mathstate
      image
      mtest {
        id
        panswer
        mathstate
      }
      manswer {
        checkans
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMultioptionsdesmoslist = /* GraphQL */ `
  mutation UpdateMultioptionsdesmoslist(
    $input: UpdateMultioptionsdesmoslistInput!
    $condition: ModelMultioptionsdesmoslistConditionInput
  ) {
    updateMultioptionsdesmoslist(input: $input, condition: $condition) {
      id
      mfname
      mtimer
      mquestion
      mathstate
      image
      mtest {
        id
        panswer
        mathstate
      }
      manswer {
        checkans
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMultioptionsdesmoslist = /* GraphQL */ `
  mutation DeleteMultioptionsdesmoslist(
    $input: DeleteMultioptionsdesmoslistInput!
    $condition: ModelMultioptionsdesmoslistConditionInput
  ) {
    deleteMultioptionsdesmoslist(input: $input, condition: $condition) {
      id
      mfname
      mtimer
      mquestion
      mathstate
      image
      mtest {
        id
        panswer
        mathstate
      }
      manswer {
        checkans
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRankingpoll = /* GraphQL */ `
  mutation CreateRankingpoll(
    $input: CreateRankingpollInput!
    $condition: ModelRankingpollConditionInput
  ) {
    createRankingpoll(input: $input, condition: $condition) {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRankingpoll = /* GraphQL */ `
  mutation UpdateRankingpoll(
    $input: UpdateRankingpollInput!
    $condition: ModelRankingpollConditionInput
  ) {
    updateRankingpoll(input: $input, condition: $condition) {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRankingpoll = /* GraphQL */ `
  mutation DeleteRankingpoll(
    $input: DeleteRankingpollInput!
    $condition: ModelRankingpollConditionInput
  ) {
    deleteRankingpoll(input: $input, condition: $condition) {
      id
      dname
      dtimer
      dquestion
      orders {
        id
        text
        image
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRankingresultlist = /* GraphQL */ `
  mutation CreateRankingresultlist(
    $input: CreateRankingresultlistInput!
    $condition: ModelRankingresultlistConditionInput
  ) {
    createRankingresultlist(input: $input, condition: $condition) {
      id
      Rankingpoll
      rankingpoll {
        id
        vote
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRankingresultlist = /* GraphQL */ `
  mutation UpdateRankingresultlist(
    $input: UpdateRankingresultlistInput!
    $condition: ModelRankingresultlistConditionInput
  ) {
    updateRankingresultlist(input: $input, condition: $condition) {
      id
      Rankingpoll
      rankingpoll {
        id
        vote
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRankingresultlist = /* GraphQL */ `
  mutation DeleteRankingresultlist(
    $input: DeleteRankingresultlistInput!
    $condition: ModelRankingresultlistConditionInput
  ) {
    deleteRankingresultlist(input: $input, condition: $condition) {
      id
      Rankingpoll
      rankingpoll {
        id
        vote
      }
      createdAt
      updatedAt
    }
  }
`;

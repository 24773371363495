import React, {useEffect, useState} from "react";
import {
    useParams,
    useNavigate
} from "react-router-dom";
import "./videorecordpage.css";
import {API, Auth, Storage} from "aws-amplify";
import {getPlayslide} from "../../../../graphql/queries";
import {Helmet} from "react-helmet";
import Navbars from "../../../Navbar";
import Goback from "../../../../img/dash/Go-Back-Button.png";
import {v4 as uuid} from "uuid";
import {createVideooptionslist} from "../../../../graphql/mutations";
import awsExports from "../../../../aws-exports";
import {Button} from 'react-bootstrap';
import Foot from "../../../Foot";

let vbucket = awsExports.aws_user_files_s3_bucket;
let vregion = awsExports.aws_user_files_s3_bucket_region;
const Videopreviewpage = (props) => {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [processing, setProcessing] = useState("");
    const history = useNavigate();
    const { id } = useParams();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
    }, []);
    const upload = async () => {
        const tl = uuid();
        const nfile = `videos/${tl}.webm`;
        const blob = props.location.state.videoBlob;
        await Storage.put(nfile, blob, {
            contentType: blob.type,
            progressCallback(progress) {
                const lods = progress.loaded;
                const done = progress.total;
                const loadin = Math.floor((lods / done) * 100);
                const per = loadin + "%";
                setProcessing(per)
                if (loadin === 100) {
                    setProcessing("Uploading...")
                }
            },
        }).then(() => {
            const input = {
                id:tl,
                vvideo: {
                    bucket: vbucket,
                    region: vregion,
                    key: nfile
                },
                videoid:tl
            }
            API.graphql({
                query: createVideooptionslist,
                variables: {input}
            });
            setTimeout(() => {
                setProcessing("Wait a minute");
                history(`/videoquizsetup/${id}/${tl}`)
            }, 10000)
        })

            .catch(err => console.log(err));
    }
    function gotorecord(){
        history(`/videorecord/${id}`);
    }
    function scp(){
        history(`/videoquizfile/${id}`);
    }
    return (
        isOwner === isUser ?
                <>
                    <Helmet>
                        <title>Video Quiz</title>
                    </Helmet>
                    <Navbars/>
                    <div className="uploadpptx">
                        <div className="upgradenew3">
                            <img src={Goback} className="dropbtn" onClick={scp} alt="go back"/>
                            <span className="tooltiptext3">Back to manage content</span>
                        </div>
                        <br/>
                        <br/>
                    <div className="videorecordepage">
                        <div>
                            {props.location.state && props.location.state.videoBlob && (
                                <div style={{width: "100%", maxWidth: 480, height: 360}}>
                                    <video
                                        src={window.URL.createObjectURL(props.location.state.videoBlob)}
                                        width={480}
                                        height={360}
                                        controls
                                        className="beauvideo"
                                    />
                                </div>
                            )}
                        </div>
                        <p>{processing}</p>
                        <div className="toprecord">
                            <Button onClick={gotorecord} className="mr-5">Re-record</Button>
                            <Button onClick={upload} className="mr-5">Use it</Button>
                        </div>
                    </div>
                    </div>
                    <Foot />
                </>
                : ""

    );
};

export default Videopreviewpage


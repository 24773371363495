import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePlayslide} from '../../../graphql/mutations';
import { getPlayslide } from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import { UploadFiles } from './FileUpload';
import { processFile } from './fileService';
import "../../css/uploadimg.css";
import {Card} from "react-bootstrap";

const allowedMediaSourceTypes = ['.jpeg','.png','.jpg','.svg','.webp','.bmp','.tif','.tiff','.eps'];

function Imgedit() {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [uploadedmessage, setUploadedmessage] = useState("");
    const [isShow, setIsShow] = useState(true);
    const { id, rid } = useParams();
    const [images, setImages] = useState([]);
    const [error, setError] = useState('');
    const [thislurlfile, setThislurlfile] = useState('');
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
            const newdatas = images.filter(data => data.rid === rid);
            const lurls = newdatas[0].lurl;
            setThislurlfile(lurls)
        })()
    }, []);

    const upload = async (files) => {
        setProcessing(true);
        setError('');
        let newpptx;
            try {
                const MAX_FILE_SIZE = 5120;
                const file = files[0];
                const fileSizeKiloBytes = file.size / 1024;
                if(fileSizeKiloBytes > MAX_FILE_SIZE){
                    setError("File size is greater than maximum limit");
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
                const processedFile = await processFile(file);
                const results = processedFile.images;
                const pptxtoimage = results.map(({name, location}) => {
                    return {
                        title: "image",
                        lurl: `https://edureachum200445-dev.s3.amazonaws.com/public/img/${name}`
                    };
                });
                newpptx = pptxtoimage.map((r,i) => ({...r, rid, rurl:`https://edu.reachum.com/imgshowroom/${id}/${rid}` }));
            } catch (err) {
                console.error(err);
                setError(`Unable to process file`)
            }
            setProcessing(false);
        const old = JSON.stringify(images).replace(thislurlfile, newpptx[0].lurl); //convert to JSON string
        const newArray = JSON.parse(old);
        const input = {
            id,
            playlistlinks: newArray
        };
        API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setIsShow(false)
        setUploadedmessage("Updated!")
        }

    return (
        isOwner === isUser ?
        <>
            <div className="uploadpptx">
                {isShow ?
                    <Card className="text-center">
                        <Card.Header as="p">Update Image (5MB limit)</Card.Header>
                        <Card.Body>
                            {error && <h5>{error}</h5>}
                            <UploadFiles processing={processing} allowedFileTypes={allowedMediaSourceTypes}
                                         onUpload={upload}/>
                        </Card.Body>
                        <Card.Footer className="text-muted">
                        </Card.Footer>
                    </Card>
                    :
                    <>
                        <div className="thiscenter">
                        <h3 className="redwar">{uploadedmessage}</h3>
                        </div>
                    </>
                }
            </div>
        </>
            :""
    );
}

export default Imgedit;


import React, {useState} from "react";
import ReactCountdownClock from "react-countdown-clock";
import Timesout from "./timer.gif";
import "./css/timer.css";

function Timer() {
    const [isOpen, setIsOpen] = useState(true);
    const [thisvalue, setThisvalue] = useState(60);
    const [value, setValue] = useState(null);
    const [istimer, setIstimer] = useState(true);
    const [pausethis, setPausethis] = useState(true);
    const [showtimer, setShowtimer] = useState(true)
   const thistimeout =()=>{
    setIsOpen(false);
   }
   function submitthis(){
       setThisvalue(value);
       setShowtimer(true);
       setIsOpen(true);
       setIstimer(prevState => !prevState);
       setPausethis(true)
   }
   function togglechangetimer(){
       setIstimer(prevState => !prevState);
   }
    return (
        <>
            <div className="timerpage">
                {isOpen ?
                    <div className="timerg">
                        {showtimer ?
                        <ReactCountdownClock seconds={thisvalue}
                                             color="#258893"
                                             alpha={0.9}
                                             size={300}
                                             weight={50}
                                             paused={pausethis}
                                             onComplete={thistimeout}/>
                            :""
                        }
                    </div>
                    :
                    <img src={Timesout} className="timerimg"/>
                }
            </div>
            <div className="centerthis">
                <button onClick={()=> setPausethis(prevState => !prevState)} className="timersubmit">{pausethis ? "Start" : "Pause"}</button>
                {istimer ?
                    <button onClick={togglechangetimer} className="timersubmit">Reset timer</button>
                    :
                    <div className="leftmagin">
                        <input src={value} type="number" onChange={(e) => {
                            setValue(e.target.value);
                            setShowtimer(false)
                        }}
                               className="smallform"/>
                        <button onClick={submitthis} className="timersubmitw">Reset</button>
                        <br/>
                    </div>
                }
            </div>
        </>
    )
}

export default Timer;

import React, {useEffect, useRef, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateOptionsdrawlist} from '../../../graphql/mutations';
import {Form,Table} from "react-bootstrap";
import {getOptionsdrawlist, getPlayslide} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../css/optionshowroom.css";
import DeleteIcon from "../../../img/dash/Delete-Button-X.png";
import Add from "../../../img/dash/Add-Button.png";
import Grid from "@material-ui/core/Grid";

function Drawoptionsedit() {
	const ref = useRef(null)
	const { id,rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [dname, setDname] = useState('')
	const [polltimer, setPolltimer] = useState(0)
	const [dquestion, setDquestion] = useState('')
	const [items1, setItems1] = useState([{ panswer: '' }])
	const [pchecked, setPchecked] = useState();
	const [imgdata, setImgdata] = useState("");
	const [showtimerform, setShowtimerform] = useState(false)
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
		})()
	}, []);
	useEffect( () => {
		(async() => {
			const response = await API.graphql({
				query: getOptionsdrawlist,
				variables:{id:rid}
			})
			const datas = response.data.getOptionsdrawlist;
			const dn = datas.pfname
			setDname(dn)
			const tm = datas.ptimer
			if(tm !== 0) {
				setPolltimer(tm)
				setShowtimerform(true)
			}
			const q = datas.pquestion
			setDquestion(q)
			const i1 = datas.ptest
			setItems1(i1)
			const ma = datas.drawques;
			setImgdata(ma)
			const t = Number(datas.oanswer)
			document.getElementsByName('options')[t].checked=true
		})()
	}, []);

	function changedtimer(event){
		setPolltimer(event.target.value)
	}
	function changedquestion(event){
		setDquestion(event.target.value)
	}
	function addItem1 () {
		setItems1(state => [...state, ''])
	}

	const items1View = items1.map((item, index) => {
		function deleteItem () {
			setItems1(state => {
				return state.filter((item, stateIndex) => index !== stateIndex)
			})
		}
		function changeItem (event) {
			event.persist()
			setItems1(state => {
				return state.map((item, stateIndex) => {
					const match = index === stateIndex
					if (match) {
						const newItem = { ...item, panswer: event.target.value, id: stateIndex }
						return newItem
					}
					return item
				})
			})
		}
		return (
			<li key={index} className="optionslistdistanse">
				<input
					name="options"
					value={`${index}`} // make sure to set up defaultValue
					type="radio"
					onChange={()=> {
						setPchecked(`${index}`)
					}}
				/>
				<input value={item.panswer} onChange={changeItem} placeholder=""  className="optionsullwidth"/>
				<img src={DeleteIcon} onClick={deleteItem} className="dragdropdele" />
			</li>
		)
	})

	async function submitItems (event) {
		event.preventDefault();
		const radiobox = document.querySelector('input[name = "options"]:checked');
		if(radiobox != null) {
			try {
				const input = {
					id: rid,
					ptimer: polltimer,
					pquestion: dquestion,
					ptest: items1,
					oanswer: pchecked
				}
				await API.graphql({
					query: updateOptionsdrawlist,
					variables: {input}
				});
				setUploadedmessage("Updated!")
			} catch (err) {
				console.log(err)
			}
		}else{
			setUploadedmessage("Please check the correct one.")
		}
	}

	return (
		isOwner === isUser ?
		<div className="orderlist">
			<form onSubmit={submitItems}>
				<Form.Group className="mb-3" controlId="formBasicEmail">
					<Form.Label>Game name: {dname}</Form.Label>
				</Form.Group>
				<Grid container spacing={0}>
					<Grid item xs={2}>
						Timer : &nbsp;
					</Grid>
					<Grid item xs={10}>
						<Grid container spacing={0}>
							<Grid item xs={7} className="cccenter">
								<input type="radio" name="timer" value="AddTimer" onChange={()=>{setShowtimerform(true)}}/> Add Timer (Seconds) &nbsp;
								{showtimerform ?
									<input
										value={polltimer}
										className="dragdroptimer"
										type="number"
										maxLength="3"
										onChange={(e)=>setPolltimer(e.target.value)}
									/>
									: ""
								}
							</Grid>
							<Grid item xs={5} className="cccenter">
								<input type="radio" name="timer" value="NoTimer" onChange={()=>{
									setShowtimerform(false);
									setPolltimer(0)
								}} /> No Timer
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<br/>
				<Form.Group className="mb-3">
					<Form.Label>Question :</Form.Label>
					<br/>
					<textarea type="text" value={dquestion} onChange={changedquestion} className="dragdropques" />
				</Form.Group>
				<Form.Group className="mb-3" controlId="formBasicEmail">
					<img src= {imgdata} className="drawwidth" />
				</Form.Group>
				<Form.Group className="mb-3" >
					<Form.Label>Answers :</Form.Label>
					<ol>
						{items1View}
					</ol>
					<div>
						<img src={Add} onClick={addItem1} />
					</div>
				</Form.Group>
				<hr/>
				<br/>
				<Table>
					<tr>
						<td>
						</td>
						<td className="orderright">
							{uploadedmessage} <input type="submit" value="Update" id="summitb" />
						</td>
					</tr>
				</Table>
			</form>
		</div>
			:""
	);
}
export default Drawoptionsedit;

import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateAnswers} from '../../graphql/mutations';
import { useForm } from "react-hook-form";
import {Table} from "react-bootstrap";
import {getAnswers, getPlayslide} from "../../graphql/queries";
import {useParams} from "react-router-dom";
import "../css/fastaneditpage.css";
import Grid from "@material-ui/core/Grid";

function Answeredit() {
	const { id,rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [fname, setFname] = useState("");
	const [fques, setFques] = useState("");
	const [uploadedmessage, setUploadedmessage] = useState("");
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
		})()
	}, []);
	useEffect( () => {
		(async() => {
			const responses = await API.graphql({
				query: getAnswers,
				variables:{id:rid}
			})
			const datas = responses.data.getAnswers;
			const fanswer = datas.ffname;
			setFname(fanswer);
			const fq = datas.fquestion;
			setFques(fq)
		})()
	}, [rid]);
	const {register, handleSubmit} = useForm({});
	const onSubmit = async (data) => {
		try {
			const input = {
				id:rid,
				fquestion:data.fquestion
			}
			await API.graphql({
				query: updateAnswers,
				variables: {input}
			});
			setUploadedmessage(" Updated. ")
		} catch (err) {
			console.log(err)
		}
	}
	return (
		isOwner === isUser ?
		<>
			<div className="answersroomedit">
				<br/>
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					<p>Question name: </p>
				</Grid>
				<Grid item xs={10}>
					<p>{fname}</p>
				</Grid>
			</Grid>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					<p>Update question: </p>
				</Grid>
				<Grid item xs={10}>
				<textarea
					name="fquestion"
					defaultValue={fques}
					ref={register({maxLength: 500})}
					className="quesname"
				/>
				</Grid>
			</Grid>
			<Table>
				<tr>
					<td>
					</td>
				<td className="orderright">
					<span className="redthis">{uploadedmessage}</span> <input type="submit" value="Update" className="answersubmit"/>
				</td>
				</tr>
			</Table>
		</form>
			</div>
			</>
			:""
	);
}
export default Answeredit;

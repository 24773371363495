import React, {useEffect, useState} from 'react'
import {API, Auth} from "aws-amplify";
import {getSequencinggame, listLeaderboards} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import moment from "moment";
import {createLeaderboard, updateLeaderboard} from "../../../graphql/mutations";

export default function Correctsequencing () {
  const { id, rid } = useParams();
  const [dquestion, setDquestion] = useState('')
  const [categoris, setCategoris] = useState([])
  useEffect( () => {
    (async() => {
      const response = await API.graphql({
        query: getSequencinggame,
        variables:{id:rid}
      })
      const datas = response.data.getSequencinggame;
      const q = datas.dquestion;
      sessionStorage.setItem("edureachumthisquestion", q)
      setDquestion(q)
      const t1 = datas.orders;
      setCategoris(t1)
    })()
  }, []);
  useEffect(() => {
    const vreachumnewerna = localStorage.getItem("vreachumnewername");
    const reachumnname = localStorage.getItem("vreachumneweremail");
    const starts = moment().format('dddd  MM/DD/YYYY hh:mm a');
    (async () => {
      if (reachumnname) {
        try {
          await API.graphql({
            query: listLeaderboards,
            variables: {
              filter: {
                "meetemail": {
                  eq: reachumnname
                },
                "answerid": {
                  eq: id
                }
              },
              limit: 10000
            }
          }).then(datas => {
            const plays = datas.data.listLeaderboards.items;
            const playss = plays.length;
            if (playss > 0) {
              const thisid = plays[0].id;
              sessionStorage.setItem("edureachumthisid", thisid)
              const thisques = plays[0].questions;
              if(thisques) {
                const serializedArray = JSON.stringify(thisques);
                sessionStorage.setItem("edureachumthisques", serializedArray)
              }
            }else{
              const input = {
                name: vreachumnewerna,
                meetemail: reachumnname,
                answerid: id,
                timerin:starts
              }
              const responsee = API.graphql({
                query: createLeaderboard,
                variables: {input}
              });
              const thistimerid = responsee.id;
              sessionStorage.setItem("edureachumthisid", thistimerid)
            }
          })
        } catch (err) {
          console.log(err)
        }
      }
    })()
  }, []);
  const renderData = () => {
    return (
        <div>
          {categoris.map((item, index) => (
              <li key={index} className="selist">
                {item.text} &nbsp; &nbsp;
                <img src={item.image} width="300px"/>
              </li>
          ))}
        </div>
    );
  };
  useEffect(() => {
    let start;
    const handleStartTime = () => {
      start = new Date();
    };
    const onClose = () => {
      const thisrid = sessionStorage.getItem("edureachumthisrid");
      const thisqueslist = sessionStorage.getItem("edureachumthisques");
      const thisques = sessionStorage.getItem("edureachumthisquestion");
      const thisid = sessionStorage.getItem("edureachumthisid");
      const ends = moment().format('dddd  MM/DD/YYYY hh:mm a');
      const end = new Date();
      const timeDiff = end - start;
      const seconds = parseInt((timeDiff / 1000) % 60);
      try {
        const thisqq = {
          rid: thisrid,
          question: thisques,
          duration: seconds
        }
        let qq;
        if (thisqueslist) {
          const objectArray = JSON.parse(thisqueslist);
          qq = [...objectArray, thisqq];
        } else {
          qq = [thisqq]
        }
        const input = {
          id: thisid,
          questions: qq,
          timerout: ends
        }
        API.graphql({
          query: updateLeaderboard,
          variables: {input}
        });
      }catch (err) {
        console.log(err)
      }
    }
    window.addEventListener('load', handleStartTime);
    window.addEventListener('beforeunload', onClose)
    window.addEventListener('blur', onClose);
    window.addEventListener('pagehide', onClose);
    return () => {
      window.removeEventListener('load', handleStartTime);
      window.removeEventListener('beforeunload', onClose);
      window.removeEventListener('blur', onClose);
      window.removeEventListener('pagehide', onClose);
    }
  }, []);
  return (
      <div className="correctanswerpage">
      <div className="corrctcentermath">
        <h2>{dquestion}</h2>
        <br/>
        <br/>
        <p>The correct answer is: </p>
      <ol className="centerthisse">
        {renderData()}
      </ol>
        <br/>
        <br/>
        <br/>
    </div>
     </div>
  )
}

import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getPlayslide, listLeaderboards} from '../../../graphql/queries';
import "../../css/leaderboard.css";
import writeXlsx from 'write-excel-file';

function Downloadlessonlog(props) {
    const  id  = props.data;
    const [myData, setMyData] = useState([]);
    const [roomname, setRoomname] = useState("");
    useEffect(() => {
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const myroomname = datas.meetingname;
            setRoomname(myroomname)
        })()
        fetchDas();
    }, []);

    async function fetchDas() {
        const scounts = [];
        const dcounts=[];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "answerid": {
                            eq: id
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listLeaderboards.items;
            datas.forEach((data) => {
                const listdata = data;
                scounts.push(listdata)
            })
            scounts.sort((a, b) => {
                return b.score - a.score;
            });
            scounts.forEach((e) => {
                dcounts.push(e)
            });
            setMyData(dcounts)
        }catch(err){
            console.log(err)
        }
    }
    const questions = myData.reduce((result, column) => {
        if (column.questions) {
            column.questions.forEach(question => {
                result[question.rid] = question.question;
            });
        };
        return result;
    }, {});

    const questionIdToNumbers = Object.keys(questions).reduce((result, qId, i) => {
        result[qId] = i+1;
        return result;
    }, {});

    let headings = [
        { value: 'Name', fontWeight: "bold"},
        { value: "Email", fontWeight: "bold"},
        { value: "Total Score", fontWeight: "bold"},
        { value: "Total Duration(min)", fontWeight: "bold"},
        { value: "Time In", fontWeight: "bold"},
        { value: "Time Out", fontWeight: "bold"},
        { value: "Education Level", fontWeight: "bold"},
        { value: "Source", fontWeight: "bold"}
    ];
    Object.keys(questions).forEach((questionId) => {
        headings.push(
            {value: `Question ${questionIdToNumbers[questionId]}`, fontWeight: 'bold'},
            {value: `Answer ${questionIdToNumbers[questionId]}`, fontWeight: 'bold'},
            {value: `Score ${questionIdToNumbers[questionId]}`, fontWeight: 'bold'},
            {value: `Timer In ${questionIdToNumbers[questionId]}`, fontWeight: 'bold'},
            {value: `Timer Out ${questionIdToNumbers[questionId]}`, fontWeight: 'bold'},
            {value: `Duration ${questionIdToNumbers[questionId]}`, fontWeight: 'bold'},
        )
    });

    const rows = myData.map((rowData) => {
        const questionsForRow = rowData.questions || [];
        const questionsRowMap = questionsForRow.reduce((result, question) => {
            result[question.rid] = question;
            return result;
        }, {});
        const finalResults = [
            { type: String, value: rowData.name },
            { type: String, value: rowData.meetemail },
            { type: Number, value: rowData.score },
            { type: Number, value: Math.round((rowData.duration)/60) },
            { type: String, value: rowData.timerin },
            { type: String, value: rowData.timerout },
            { type: String, value: "Higher" },
            { type: String, value: "Reachum" }
        ];
        Object.keys(questions).forEach((questionId) => {
            finalResults.push(
                {value: questionsRowMap[questionId]?.question || '', type: String},
                {value: questionsRowMap[questionId]?.correctanswer || '', type: String},
                {value: questionsRowMap[questionId]?.thisscore || '', type: Number},
                {value: questionsRowMap[questionId]?.timerin || '', type: String},
                {value: questionsRowMap[questionId]?.timerout || '', type: String},
                {value: questionsRowMap[questionId]?.duration || '', type: Number}
            );
        });
        return finalResults;
    })

    const excelData = [
        headings,
        ...rows
    ]
    const downloadlessonFile = async () => {
        await writeXlsx(excelData, {fileName: `${roomname} - Reachum.xlsx`})
    };

    return (
        <>
                        <div className="leaderpage">
                                <div>
                                    <button onClick={downloadlessonFile} className="savespread">Download</button>
                                </div>
                        </div>
                    </>
    )
}

export default Downloadlessonlog;

import React, {useEffect,useState,useRef} from "react";
import { API } from 'aws-amplify';
import ReactPlayer from 'react-player/lazy'
import {getPlayslide, listLeaderboards} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import {createLeaderboard, updateLeaderboard, updatePlayslide} from "../../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {Button} from "react-bootstrap";
import {newUpdatePlayslide} from "../../../graphql/subscriptions";
import "../../css/vshowroom.css";
import Vmute from "../../../img/videoicon/unmute-icon.svg";
import Vlongunmute from "../../../img/videoicon/mute-long-version.svg";
import Vunmute from "../../../img/videoicon/mute-short-icon.svg";
import moment from "moment";
import Playb from "../../../img/host/play-btn.png";
import Pauseb from "../../../img/host/pause-btn.png";

function Vshowroom() {
    const [vfile, setVfile] = useState("");
    const [playing, setPlaying] = useState(false);
    const [isopen, setIsopen] = useState(true);
    const [isshow, setIsshow] = useState(true);
    const [volumes, setVolumes] = useState(0);
    const [vmuted, setVmuted] = useState(true);
    const [showp, setShowp] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [played, setPlayed] = useState(0);
    const [seeking, setSeeking] = useState(false);
    const [opensync, setOpensync] = useState(false)
    const [images, setImages] = useState([]);
    const [thattimer, setThattimer] = useState('');
    const [openconles, setOpenconles] = useState(true);
    const [isplaypauseshow, setIsplaypauseshow] = useState(false)
    const [opencontrols, setOpencontrols] = useState(true);
    const [isShows, setIsShows] = useState(true);
    const { id, rid } = useParams();
    const playerRef = useRef(null);
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        const reachumnname = localStorage.getItem("vreachumnewername");
        if(!reachumnname){
            setShowp(true);
            setIsplaypauseshow(true)
        }
        const ty = sessionStorage.getItem("reachumvideosilent");
        if(ty){
            setOpenconles(false);
        }
    }, []);
    useEffect(() => {
        const longicon = sessionStorage.getItem("hidelongicon");
        if(longicon){
            setIsshow(false)
        }
    }, []);
    useEffect(() => {
        fetchDatas();
        const subscription = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchDattas()
        })
        return()=>{
            subscription.unsubscribe()
        }
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        const starts = moment().format('MM/DD/YYYY hh:mm a');
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            sessionStorage.setItem("edureachumthisid", thisid)
                            const thisques = plays[0].questions;
                            if(thisques) {
                                const serializedArray = JSON.stringify(thisques);
                                sessionStorage.setItem("edureachumthisques", serializedArray)
                            }
                        }else{
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                score: 0,
                                thisgamescore: 0,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            sessionStorage.setItem("edureachumthisid", thistimerid)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);
    async function fetchDattas() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datass = response.data.getPlayslide;
            const vdataplays = datass.vplaypause;
            setPlaying(vdataplays);
            const imags = datass.playlistlinks;
            const newdatas = imags.filter(data => data.rid === rid);
            const lurls = newdatas[0].vtimer;
            setCurrentTime(lurls)
        }catch(error){
            console.log("can't display")
        }
    }
    async function fetchDatas() {
        try {
            const responses = await API.graphql({
                query: getPlayslide,
                variables:{id}
            });
            const datas = responses.data.getPlayslide;
            const vdataplay = datas.vplaypause;
            setPlaying(vdataplay);
            const rurls = `https://reachumvideos.s3.amazonaws.com/public/videos/${rid}/${rid}.mp4`;
            setVfile(rurls);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
            const selfstudy = datas.group;
            if(selfstudy === "selfstudy"){
                setOpencontrols(false);
                setOpenconles(false)
            }
        }catch(error){
            console.log("Error", error)
        }
    }
    const playthisv = () =>{
        setVmuted(false);
        setVolumes(1);
        setIsopen(false);
        setIsshow(false)
        sessionStorage.setItem("hidelongicon", "false")
    }
    const mutethisv = () =>{
        setVmuted(true);
        setVolumes(0);
        setIsopen(true)
    }
    const handleProgress = (state) => {
        setCurrentTime(state.playedSeconds);
        if (!seeking) {
            setPlayed(state.played)
        }
    };
    const formatTime = (seconds) => {
        const date = new Date(null);
        date.setSeconds(seconds);
        return date.toISOString().substr(11, 8);
    };
    const handleMouseDown = () => {
        setSeeking(true)
    }
    const handleMouseUp = async (event) => {
        setSeeking(false)
        const newValue = parseFloat(event.target.value);
        playerRef.current.seekTo(newValue);
        setThattimer(newValue);
        setOpensync(true);
        setIsplaypauseshow(true)
    }
    function sharethis(){
        const thisarray = images;
        const item = thisarray.find(obj => obj.rid === rid);
         item.vtimer = thattimer;
         const uu = thisarray
        const input = {
            id,
            playlistlinks: uu
        };
        API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setOpensync(false)
    }
    const handleSeekChange = (event) => {
        setPlaying(false);
        const newValue = parseFloat(event.target.value)
        setPlayed(newValue);
        setIsShows(true)
    }
    const handlevideoPlay = () => {
        if (currentTime && playerRef.current) {
            playerRef.current.seekTo(currentTime);
            setCurrentTime(null); // Clear the seek time
        }
    };
    const handleplay = async () =>{
        setPlaying(true);
        const input = {
            id,
            vplaypause:true,
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setIsShows(false);
        setTimeout(()=> removetime(), 500)
    }
    async function removetime(){
        const thisarray = images;
        const item = thisarray.findIndex(obj => obj.rid === rid);
        if (item  !== -1) {
            delete thisarray[item].vtimer
        }
        const input = {
            id,
            playlistlinks: thisarray
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
    }
    const handlepause = async () =>{
        setPlaying(false);
        const input = {
            id,
            vplaypause:false
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setIsShows(true)
    }
    const changetoplay = async () =>{
        setIsplaypauseshow(false)
        setIsShows(true);
        const input = {
            id,
            vplaypause:false,
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
    }
    useEffect(() => {
        let start;
        const handleStartTime = () => {
            start = new Date();
        };
        const onClose = () => {
            const thisrid = sessionStorage.getItem("edureachumthisrid");
            const thisqueslist = sessionStorage.getItem("edureachumthisques");
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            const end = new Date();
            const timeDiff = end - start;
            const seconds = parseInt((timeDiff / 1000) % 60);
            try {
                const thisqq = {
                    rid: thisrid,
                    question: "Video",
                    duration: seconds
                }
                let qq;
                if (thisqueslist) {
                    const objectArray = JSON.parse(thisqueslist);
                    qq = [...objectArray, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: thisid,
                    questions: qq,
                    timerout: ends
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            }catch (err) {
                console.log(err)
            }
        }
        window.addEventListener('load', handleStartTime);
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('load', handleStartTime);
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
        <>
            {openconles ?
                <>
                    <div className="centerthisimages">
                    {isopen ?
                        <div className="centerthis">
                            {isshow ?
                                <img src={Vlongunmute} onClick={playthisv} className="playervideobutton" alt="unmute"/>
                                :
                                <img src={Vunmute} onClick={playthisv} className="playervideobutton" alt="mute"/>
                            }
                       </div>
                        :
                        <img src={Vmute} onClick={mutethisv} className="playervideobutton" alt="unmute"/>
                    }
                    </div>
                </> : ""
            }
            <div className="vidshows">
                <div className='player-wrapper'>
                    {opencontrols ?
                        <ReactPlayer
                            id="reactplayer"
                            className='react-player'
                            playing={playing}
                            volume={volumes}
                            ref={playerRef}
                            width='100%'
                            height='100%'
                            muted={vmuted}
                            url={vfile}
                            playsinline={true}
                            onProgress={handleProgress}
                            onPlay={handlevideoPlay}
                            onEnded={changetoplay}
                        />
                        :
                        <ReactPlayer
                            className='react-player'
                            width='100%'
                            height='100%'
                            url={vfile}
                            playsinline={true}
                            controls={true}
                        />
                    }
                    {isplaypauseshow ?
                        <>
                            {isShows ?

                                <img src={Playb} onClick={handleplay} className="playpbutton"/>
                                :
                                <img src={Pauseb} onClick={handlepause} className="playpbutton"/>

                            }
                        </>
                        :""
                    }
                </div>
                <br/>
                {showp ?
                    <>
                        <Grid container spacing={0} className="atvideobottom">
                            <Grid item xs={2}>
                                <span className="whitetext">{formatTime(currentTime)}</span>
                            </Grid>
                            <Grid item xs={8}>
                                {opensync ?
                                    <Button onClick={sharethis} size="sm" className="syncbutton"> SYNC </Button>
                                    : ""
                                }
                                <br/>
                                <input min={0} onChange={handleSeekChange} onMouseDown={handleMouseDown}
                                       onMouseUp={handleMouseUp} max={0.9999} step='any' value={played} type='range' className="fullsynwidth"/>
                            </Grid>
                            <Grid item xs={2} className="leftside">
                            </Grid>
                        </Grid>
                    </> : ""
                }
            </div>
        </>
    );
}

export default Vshowroom;

import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateDesmosfillin} from '../../../graphql/mutations';
import { useForm } from "react-hook-form";
import {Form,Table} from "react-bootstrap";
import {getDesmosfillin, getPlayslide} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../css/fastaneditpage.css";
import Resizer from "react-image-file-resizer";
import Grid from "@material-ui/core/Grid";
const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			400,
			400,
			"JPEG",
			95,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});
function Fastanswerimgedit() {
	const { id,rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [fname, setFname] = useState("");
	const [ftimers, setFtimers] = useState(0);
	const [fques, setFques] = useState("");
	const [fansw, setFansw] = useState("");
	const [thisimg, setThisimg] =useState("");
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [showtimerform, setShowtimerform] = useState(false)
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
		})()
	}, []);
	useEffect( () => {
		(async() => {
			const responses = await API.graphql({
				query: getDesmosfillin,
				variables:{id:rid}
			})
			const datas = responses.data.getDesmosfillin;
			const ffname = datas.ffname;
			setFname(ffname)
			const ftimer = datas.ftimer;
			if(ftimer !== 0) {
				setFtimers(ftimer);
				setShowtimerform(true)
			}
			const fquestion = datas.fquestion;
			setFques(fquestion)
			const thisimg = datas.mathstate;
			if(thisimg){
				setThisimg(thisimg);
			}
			const fanswer = datas.fanswer;
			setFansw(fanswer)
		})()
	}, [rid]);
	const {register, handleSubmit} = useForm({});
	const handleFileChange = async (event) => {
		const img = event.target.files[0];
		const resized = await resizeFile(img);
		setThisimg(resized)
	};
	const onSubmit = async (data) => {
		try {
			const input = {
				id:rid,
				ftimer:ftimers,
				fquestion:data.fquestion,
				mathstate:thisimg,
				fanswer:data.fanswer
			}
			await API.graphql({
				query: updateDesmosfillin,
				variables: {input}
			});
			setUploadedmessage(" Updated. ")
		} catch (err) {
			console.log(err)
		}
	}
	return (
		isOwner === isUser ?
		<>
			<div className="fastaneditpage">
		<form onSubmit={handleSubmit(onSubmit)}>
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Label>Game name: {fname}</Form.Label>
			</Form.Group>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					Timer : &nbsp;
				</Grid>
				<Grid item xs={10}>
					<Grid container spacing={0}>
						<Grid item xs={7} className="cccenter">
							<input type="radio" name="timer" value="AddTimer" onChange={()=>{setShowtimerform(true)}}/> Add Timer (Seconds) &nbsp;
							{showtimerform ?
								<input
									name="ftimer"
									value={ftimers}
									className="timers"
									type="number"
									maxLength="3"
									onChange={(e)=>setFtimers(e.target.value)}
								/>
								: ""
							}
						</Grid>
						<Grid item xs={5} className="cccenter">
							<input type="radio" name="timer" value="NoTimer" onChange={()=>{
								setShowtimerform(false);
								setFtimers(0)
							}} /> No Timer
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<br/>
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Label>Question :</Form.Label>
				<br/>
				<textarea
					name="fquestion"
					defaultValue={fques}
					ref={register({ required: true, maxLength: 200})}
					className="ques"
				/>
			</Form.Group>
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Label>Image</Form.Label>
				<br/>
				<input type="file" onChange={handleFileChange} />
			</Form.Group>
			<Form.Group className="mb-3" controlId="formBasicPassword">
				<Form.Label>Correct answer :</Form.Label>
				<br/>
				<input
					name="fanswer"
					defaultValue={fansw}
					ref={register({ required: true, maxLength: 200})}
					className="ques"
				/>
			</Form.Group>
			<Table>
				<tr>
					<td>
					</td>
				<td className="orderright">
					{uploadedmessage} <input type="submit" value="Update" className="answersubmit"/>
				</td>
				</tr>
			</Table>
		</form>
			</div>
			</>
			:""
	);
}
export default Fastanswerimgedit;

import React, {useEffect} from "react";
import {
    Authenticator,
    useAuthenticator,
    useTheme,
    Button,
    View
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import "./css/login.css";
import {useNavigate} from "react-router-dom";
import {Auth} from "aws-amplify";


const Login = () => {
    let history = useNavigate();
    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                await Auth.currentAuthenticatedUser();
            } catch (error) {
                console.error('User is not authenticated', error);
            }
        };
        checkAuthentication();
    }, []);
    const components = {
        Header() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" paddingTop={tokens.space.large}>
                    <h3> Log in </h3>
                </View>
            );
        },
        SignIn: {
            Footer() {
                const { toResetPassword } = useAuthenticator();
                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toResetPassword}
                            size="small"
                            variation="link"
                        >
                            Forgot your password?
                        </Button>
                        <br/>
                        <br/>
                        <p onClick={()=> history("/signup")} className="smallsizefooter1">Don't have an account? Sign up</p>
                    </View>
                );
            },
        }
    };
    return (
        <>
            <br/>
            <Authenticator components={components} hideSignUp={true}>
            </Authenticator>
        </>
    )
}

export default Login

import React, {useEffect, useState, useRef} from "react";
import {API, Auth} from 'aws-amplify';
import {createMultioptionsdesmoslist, updatePlayslide} from '../../../../graphql/mutations';
import { useForm } from "react-hook-form";
import {getPlayslide} from "../../../../graphql/queries";
import { v4 as uuid } from 'uuid';
import "../../../css/poll.css";
import DeleteIcon from "../../../../img/dash/Delete-Button-X.png";
import Add from "../../../../img/dash/Add-Button.png";
import Grid from "@material-ui/core/Grid";
import DesmosCalculator from '../DesmosCalculator';
import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			400,
			400,
			"JPEG",
			95,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});

function Createdesmosmultioptions({id, handleClose58}) {
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [options, setOptions] = useState([]);
	const [images, setImages] = useState([]);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [isshowmath, setIsshowmath] = useState(false);
	const [isshowmathph, setIsshowmathph] = useState(true);
	const [newOption, setNewOption] = useState(''); // Input field for adding new options
	const addRef = useRef(null);
	const [selectedFile, setSelectedFile] = useState("");
	const [ftimers, setFtimers] = useState(0);
	const [showtimerform, setShowtimerform] = useState(false);
	const [smessage, setSmessage] = useState("")
	const [opensmessage, setOpensmessage] = useState(true)
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, []);
	const {register, control, handleSubmit} = useForm({
		defaultValues: {
			ptest: [{panswer: ""}]
		}
	});
	const handleAddOption = async () => {
		// Add a new option to the options state
		if (newOption.trim() !== '') {
				const calculatorState = addRef.current.getState();
		  	    if(calculatorState){
				const latexExpressions = calculatorState.expressions.list.filter(expression => {
					return expression.latex != null
				})
				let resized;
				if (latexExpressions.length === 0) {
					resized = "";
					setIsshowmathph(false)
				} else {
					const screenshot = addRef.current.screenshot()
					const response = await fetch(screenshot)
					const blob = await response.blob()
					resized = await resizeFile(blob);
					setIsshowmath(true)
				}
				setOptions([...options, {panswer: newOption, checkans: false, mathstate: resized}]);
				setNewOption('');
			}else{
				setOptions([...options, {panswer: newOption, checkans: false}]);
				setNewOption('');
			}
		}
	};

	const handleFileChange = async (event) => {
		const img = event.target.files[0];
		const resized = await resizeFile(img);
		setSelectedFile(resized)
	};
	const handleOptionChange = (index) => {
		// Toggle the checkbox state for the selected option
		const updatedOptions = [...options];
		updatedOptions[index].checkans = !updatedOptions[index].checkans;
		setOptions(updatedOptions);
	};

	const handleDeleteOption = (index) => {
		// Remove the selected option from the options state
		const updatedOptions = [...options];
		updatedOptions.splice(index, 1);
		setOptions(updatedOptions);
	};
	const reorderurl = `https://edu.reachum.com/multidesmosshowroom/${id}/${d}`;
	const erurl = `https://edu.reachum.com/multidesmosedit/${id}/${d}`;
	const ccurl = `https://edu.reachum.com/multidesmoscorrctanswer/${id}/${d}`;
	const ileaderboardurl = `https://edu.reachum.com/leaderboard/${id}`;
	const tt = `${d}1`;
	const ccu = `${d}333`;

	const onSubmit = async (data) => {
		const arrayOfObjectsWithId = options.map((obj, index) => ({ id: index, ...obj }));
		const arrayOfObjectsWithoutcheckbox = arrayOfObjectsWithId.map(({ checkans, ...rest }) => rest);
		const arrayOfObjects = options.map(({ panswer,mathstate, ...rest }) => rest);
		if(options.length !== 0) {
			try {
				const input = {
					id: d,
					mfname: data.pfname,
					mtimer: ftimers,
					mtest: arrayOfObjectsWithoutcheckbox,
					mquestion:data.pquestion,
					manswer: arrayOfObjects,
					image:selectedFile
				}
		await API.graphql({
					query: createMultioptionsdesmoslist,
					variables: {input}
				});
				const urldata = {
					rid: d,
					title: data.pquestion,
					rurl: reorderurl,
					eurl: erurl
				};
				const leaderboard = {
					rid:tt,
					title:"leaderboard",
					rurl:ileaderboardurl
				}
				const correcta = {
					rid:ccu,
					title:"correct answer",
					rurl: ccurl
				}
				const allImages = [...images, urldata, correcta, leaderboard]
				const inputs = {
					id,
					playlistlinks: allImages
				}
				await API.graphql({
					query: updatePlayslide,
					variables: {input: inputs}
				})
				setOpensmessage(false);
				setSmessage("Submitted");
				setTimeout(() => {
					handleClose58()
				}, 1000)
			} catch (err) {
				console.log(err)
			}
		}else{
			const thism = "Please check the correct ones. "
			setUploadedmessage(thism)
		}
	}

	return (
		isOwner === isUser ?
		<>
			<div className="dragdroppage">
				<div className="thiscenter">
					<h3>Single or Multi Select</h3>
				</div>
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0}>
				<Grid item xs={3}>
					Game Name: &nbsp;
				</Grid>
				<Grid item xs={9}>
					<input
						name="pfname"
						defaultValue=""
						ref={register({ required: true, maxLength:60 })}
					/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
					Timer : &nbsp;
				</Grid>
				<Grid item xs={9}>
					<Grid container spacing={0}>
						<Grid item xs={7} className="cccenter">
							<div className="labelinlinetimer">
								<label>
									<input
										type="radio"
										name="timer"
										value="AddTimer"
										onChange={()=>{setShowtimerform(true)}}
										className="biggerradiotimer"
									/>
									Add Timer (Seconds) &nbsp;
								</label>
							{showtimerform ?
								<input
									value={ftimers}
									className="timers"
									type="number"
									maxLength="3"
									onChange={(e)=>setFtimers(e.target.value)}
								/>
								: ""
							}
							</div>
						</Grid>
						<Grid item xs={5} className="cccenter">
							<label>
							<input type="radio" name="timer" value="NoTimer" onChange={()=>{
								setShowtimerform(false);
								setFtimers(0)
							}} className="biggerradiotimer" /> No Timer
							</label>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
			Question : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<textarea
					name="pquestion"
					defaultValue=""
					ref={register({ required: true, maxLength:200 })}
					className="quesmathinput"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					<p>Upload image:(option) </p>
				</Grid>
				<Grid item xs={10} className="leftside">
					<input type="file" onChange={handleFileChange}/>
				</Grid>
			</Grid>
			<div className="mathcccenter">
				<img src={selectedFile} className="pximages400"/>
			</div>
			<br/>
			<div className="centerside">
				<p className="redthis">Once submitted, you won't be able to make changes to the graphics.
					<br/>Please review and verify the correct selections<br/> before clicking the 'Submit' button.
					</p>
				<h4>Options:</h4>
			</div>
				{options.map((option, index) => (
					<div key={index} className="centerside">
						<Grid container spacing={0}>
							<Grid item xs={10}>
						<label>
							<Grid container spacing={0}>
								<Grid item xs={2} className="rightside">
							<input
								type="checkbox"
								checked={option.checkans}
								onChange={() => handleOptionChange(index)}
								className="multicheckbox"
							/>
								</Grid>
								<Grid item xs={5}>
									<p>{option.panswer}</p>
								</Grid>
								<Grid item xs={5}>
									{isshowmath ?
									<img src={option.mathstate} className="borderthis" alt="graphics"/>
										:""
									}
								</Grid>
							</Grid>
						</label>
							</Grid>
							<Grid item xs={2} className="leftside">
								<img src={DeleteIcon} onClick={() => handleDeleteOption(index)} alt="delete"/>
							</Grid>
						</Grid>
					</div>
				))}
			<hr/>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={11} className="rightside">
					{opensmessage ?
						<>
					{uploadedmessage} <input type="submit" value="Submit" id="summitb" />
					</>
						:
						<p className="redthis"> {smessage} </p>
					}
				</Grid>
				<Grid item xs={1}>
				</Grid>
			</Grid>

		</form>
				<div>
					<h4>Add Options: (<span onClick={()=>  window.open("https://www.desmos.com/", "_blank")} className="underlinetext"> Desmos </span>)</h4>
					<input
						type="text"
						placeholder="Text required"
						value={newOption}
						onChange={(e) => setNewOption(e.target.value)}
						className="quesmath"
					/>
					<br/>
					<br/>
					{isshowmathph ?
						<DesmosCalculator ref={addRef}/>
						: ""
					}
					<br/>
					<Grid container spacing={0}>
						<Grid item xs={1} className="rightside">
						</Grid>
						<Grid item xs={11}>
							<div className="upgradenew3" id="3">
								<img src={Add} onClick={handleAddOption} width="40px"/>
								<span className="tooltiptext3">Click here to add options.</span>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
			</>
			:""
	);
}
export default Createdesmosmultioptions;

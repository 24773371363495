import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePlayslide} from '../../graphql/mutations';
import {useParams} from "react-router-dom";
import "../css/uploadurl.css";
import {getPlayslide} from "../../graphql/queries";
import Grid from "@material-ui/core/Grid";

function Urledit() {
    const { id, rid } = useParams();
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [val, setVal] = useState("");
    const [vals, setVals] = useState("");
    const [images, setImages] = useState([]);
    const [uploadedmessage, setUploadedmessage] = useState("");
    const [uploadedmessages, setUploadedmessages] = useState("");
    const [thislurlfile, setThislurlfile] = useState('');
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
            const newdatas = images.filter(data => data.rid === rid);
            const lurls = newdatas[0].lurl;
            setThislurlfile(lurls)
            setVal(lurls)
        })()
    }, []);
    const handleChange = e => {
        setVal(e.target.value);
    }
    const handleChanges = e => {
        setVals(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const old = JSON.stringify(images).replace(thislurlfile, val);
        const newval = JSON.parse(old);
        try {
            const input = {
                id,
                playlistlinks: newval
            }
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
            setUploadedmessage("Uploading...")
            setUploadedmessage("Updated")
        }catch(err){
            console.log(err)
        }
    }
    const handleSubmits = async (e) => {
        e.preventDefault();
        const old = JSON.stringify(images).replace(thislurlfile, vals);
        const newval = JSON.parse(old);
        try {
            const input = {
                id,
                playlistlinks: newval
            }
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
            setUploadedmessages("Uploading...")
            setUploadedmessages("Updated")

        }catch(err){
            console.log(err)
        }
    }

    return (
        isOwner === isUser ?
            <>
                <div className="uploadurl">
                    <form onSubmit={handleSubmit}>
                            <p>Update URL (Example: https://example.com)</p>
                        <div className="form-row">
                            <Grid container spacing={0}>
                                <Grid item xs={9}>
                            <div className="upgradenew5">
                                <input onChange={handleChange} value={val} className="form-control urlsform" name="rurl" placeholder="URL"/>
                                    <span className="tooltiptext5">URLs to public content go here.</span>
                                </div>
                                </Grid>
                                <Grid item xs={3}>
                                <input type="submit" value="Update" className="answersubmit"/>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                    <p className="uploaded">{uploadedmessage}</p>
                    <br/>
                    <form onSubmit={handleSubmits}>
                            <p>Update the URL here if it doesn't work using the entry field above.</p>
                        <div className="form-row">
                            <Grid container spacing={0}>
                                <Grid item xs={9}>
                            <div className="upgradenew2">
                                <input onChange={handleChanges} value={vals} className="form-control urlsform" name="lurl" placeholder="Secure URL"/>
                                    <span className="tooltiptext2">
                                It appears in a secure popup window.
                            </span>
                                </div>
                                </Grid>
                                <Grid item xs={3}>
                                <input type="submit" value="Update" className="answersubmit"/>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                    <p className="uploaded">{uploadedmessages}</p>
                    <br/>
                </div>
            </>
            :""

    );
}

export default Urledit;

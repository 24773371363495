import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import { getAdobeexpress, listLeaderboards } from "../../graphql/queries";
import {updateLeaderboard, createLeaderboard} from "../../graphql/mutations";
import {useParams} from "react-router-dom";
import moment from "moment";
import "../css/adobe.css"

function  Adobeshow(){
    const { id,rid } = useParams();
    const [itt, setItt] = useState("");
    const [images, setImages] = useState([]);
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect( () => {
        (async() => {
            const response = await API.graphql({
                query: getAdobeexpress,
                variables:{id:rid}
            })
            const datas = response.data.getAdobeexpress;
            const images = datas.adobeimags;
            const im = datas.dname;
            setItt(im)
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        const starts = moment().format('MM/DD/YYYY hh:mm a');
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            sessionStorage.setItem("edureachumthisid", thisid)
                            const thisques = plays[0].questions;
                            if(thisques) {
                                const serializedArray = JSON.stringify(thisques);
                                sessionStorage.setItem("edureachumthisques", serializedArray)
                            }
                        }else{
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                score: 0,
                                thisgamescore: 0,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            sessionStorage.setItem("edureachumthisid", thistimerid)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);
    useEffect(() => {
        let start;
        const handleStartTime = () => {
            start = new Date();
        };
        const onClose = () => {
            const thisrid = sessionStorage.getItem("edureachumthisrid");
            const thisqueslist = sessionStorage.getItem("edureachumthisques");
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            const end = new Date();
            const timeDiff = end - start;
            const seconds = parseInt((timeDiff / 1000) % 60);
                const thisqq = {
                    rid: thisrid,
                    question: "Adobe Image",
                    duration: seconds
                }
                let qq;
                if (thisqueslist) {
                    const objectArray = JSON.parse(thisqueslist);
                    qq = [...objectArray, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: thisid,
                    questions: qq,
                    timerout: ends
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
        }
        window.addEventListener('load', handleStartTime);
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('load', handleStartTime);
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
                        <>
                        <div className="centerside">
                            {images.map((item, index) => (
                                <>
                                    <br/>
                                    <br/>
                                    <div key={index}><img src={item.data} className="adobedisplay" alt={itt}/></div>
                                    <br/>
                                    <br/>
                                </>
                            ))}
                        </div>
        </>
    )
}


export default Adobeshow;


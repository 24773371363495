import React, {useEffect,useState} from "react";
import { API } from 'aws-amplify';
import {getPlayslide, listLeaderboards} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import {Image} from "react-bootstrap";
import "../css/imgshowroom.css";
import moment from "moment";
import {createLeaderboard, updateLeaderboard} from "../../graphql/mutations";
function Gifshowroom() {
    const [jvfile, setVjfile] = useState();
    const { id, rid } = useParams();
    const [itt, setItt] = useState("");
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        fetchDatas();
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        const starts = moment().format('MM/DD/YYYY hh:mm a');
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            sessionStorage.setItem("edureachumthisid", thisid)
                            const thisques = plays[0].questions;
                            if(thisques) {
                                const serializedArray = JSON.stringify(thisques);
                                sessionStorage.setItem("edureachumthisques", serializedArray)
                            }
                        }else{
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                score: 0,
                                thisgamescore: 0,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            sessionStorage.setItem("edureachumthisid", thistimerid)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);
    async function fetchDatas() {
        const response = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = response.data.getPlayslide.playlistlinks;
        const newdatas = datas.filter(data => data.rid === rid);
        const gurl = newdatas[0].lurl;
        setVjfile(gurl);
        const tt = newdatas[0].title;
        setItt(tt);
        sessionStorage.setItem("edureachumthisquestion", tt)
    }
    useEffect(() => {
        let start;
        const handleStartTime = () => {
            start = new Date();
        };
        const onClose = () => {
            const thisrid = sessionStorage.getItem("edureachumthisrid");
            const thisques = sessionStorage.getItem("edureachumthisquestion");
            const thisqueslist = sessionStorage.getItem("edureachumthisques");
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            const end = new Date();
            const timeDiff = end - start;
            const seconds = parseInt((timeDiff / 1000) % 60);
            try {
                const thisqq = {
                    rid: thisrid,
                    question: thisques,
                    duration: seconds
                }
                let qq;
                if (thisqueslist) {
                    const objectArray = JSON.parse(thisqueslist);
                    qq = [...objectArray, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: thisid,
                    questions: qq,
                    timerout: ends
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            }catch (err) {
                console.log(err)
            }
        }
        window.addEventListener('load', handleStartTime);
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('load', handleStartTime);
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
        <>
            <div className="imgshows">
                <Image src={jvfile} fluid className="imgviewsite" width="480" alt={itt}/>
            </div>
        </>
    );
}
export default Gifshowroom;

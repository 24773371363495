import {Expression, GraphingCalculator} from "desmos-react";
import "../css/math.css";
function Desmos() {
    return (
        <GraphingCalculator
            attributes={{className: "calculatorclass"}}
            fontSize={18} keypad projectorMode
        >
            <Expression id="slider"  />
        </GraphingCalculator>
    );
}

export default Desmos

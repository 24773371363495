/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://edh4vu6ynfh4xf5wv3zeiwy2j4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2xw75opegfgfzjctmgsoickjra",
    "aws_cloud_logic_custom": [
        {
            "name": "eduwhereby",
            "endpoint": "https://6cka141211.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "educheckout",
            "endpoint": "https://fu56d8agaa.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:0bbb505f-4b84-4448-9df8-3976800029a4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_2CEcTbaOq",
    "aws_user_pools_web_client_id": "rg5sve48ko8or6uhliudbqm09",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "edureachum200445-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "edureachum-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1vv6cida4al9c.cloudfront.net"
};


export default awsmobile;

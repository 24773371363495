import React, {useEffect, useState} from "react";
import {API, graphqlOperation} from 'aws-amplify';
import {getPlayslide, listLeaderboards} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import {Table} from 'react-bootstrap';
import "./style.css";
import Correctimg from "../../img/view/check-mark.png";
import Incorrectimh from "../../img/view/close.png";
import Noanswer from "../../img/view/question-mark.png";
import Contents from "../../img/view/content-management.png"
import Accordion from 'react-bootstrap/Accordion';
import moment from "moment";
import Downloadlessonlog from "./leaderboard/downloadlessonlog";

function LessonLog() {
    const [myData, setMyData] = useState([]);
    const {id} = useParams();
    const [thislength, setThislength] = useState(0);
    const [roomname, setRoomname] = useState("");
    const [isSortedAlpha, setIsSortedAlpha] = useState(false);
    const [isSortedlth, setIsSortedlth] = useState(false);
    const [isSortedti, setIsSortedti] = useState(false);
    const [isSortedto, setIsSortedto] = useState(false);
    const [isSorteddura, setIsSorteddura] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [openrearchform, setOpenrearchform] = useState(false);
    const [nextToken, setNextToken] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const myroomname = datas.meetingname;
            setRoomname(myroomname);
        })()
    }, []);

    const fetchDas = async (token) => {
        setLoading(true);
        let dcounts = [];
        let scounts = [];
        try {
            const result = await API.graphql(graphqlOperation(listLeaderboards, { nextToken: token }));
            const { items: newItems, nextToken: newNextToken } = result.data.listLeaderboards;
            const filteredData = newItems.filter((item) => item.answerid === id);
            if (filteredData.length !== 0) {
                setThislength(filteredData.length)
                filteredData.forEach((data) => {
                    const listdata = data;
                    scounts.push(listdata)
                })
                scounts.sort((a, b) => {
                    return b.score - a.score;
                });
                scounts.forEach((e) => {
                    dcounts.push(e)
                });
                const result =  dcounts.map(item => {
                    const totalDuration = item.questions
                        ? item.questions.reduce((acc, question) => acc + question.duration, 0)
                        : 0;
                    return {
                        ...item,
                        dura: totalDuration,
                    };
                });
                setMyData(result);
                setNextToken(newNextToken);
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchDas(nextToken);
    }, [nextToken]);

    const renderreports = () => {
        return myData.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.meetemail}</td>
                        <td>
                            <Accordion>
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>Activities</Accordion.Header>
                                    <Accordion.Body>
                                        <Table bordered>
                                            <thead>
                                            <tr>
                                                <th>Activity</th>
                                                <th> Correct/ Incorrect Answer</th>
                                                <th> Score</th>
                                                <th>Duration(Sec)</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {item.questions && item.questions.map((item, index) => {
                                                const thisas = item.correctanswer;
                                                let thisanswer;
                                                if (thisas === "correct") {
                                                    thisanswer = `${Correctimg}`
                                                } else if (thisas === "wrong") {
                                                    thisanswer = `${Incorrectimh}`
                                                } else if (thisas === "No answer") {
                                                    thisanswer = `${Noanswer}`
                                                } else {
                                                    thisanswer = `${Contents}`
                                                }
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td>{item.question}</td>
                                                            <td><img src={thisanswer} width="24px"/></td>
                                                            <td>{item.thisscore}</td>
                                                            <td>{item.duration}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </td>
                        <td>{item.score}</td>
                        <td>{item.timerin}</td>
                        <td>{item.timerout}</td>
                        <td>
                            {item.dura > 60 ? Math.round(parseInt(item.dura) / 60) : 0}
                        </td>
                        <td> Higher</td>
                        <td> Reachum</td>
                    </tr>
                )
        })
    }
    const toggleEmail = () => {
        if (isSortedAlpha) {
            const tsorted = [...myData].sort((a, b) => b.meetemail.localeCompare(a.meetemail));
            setMyData(tsorted);
        }else {
            const sorted = [...myData].sort((a, b) => a.meetemail.localeCompare(b.meetemail));
            setMyData(sorted);
        }
        setIsSortedAlpha(prevState => !prevState);
    }
    const toggleScore = () => {
        if (isSortedlth) {
            const ssorted = [...myData].sort((a, b) => b.score - a.score);
            setMyData(ssorted);
        }else {
            const soorted = [...myData].sort((a, b) => a.score - b.score);
            setMyData(soorted);
        }
        setIsSortedlth(prevState => !prevState);
    }
    const toggleDura = () => {
        if (isSorteddura) {
            const sdorted = [...myData].sort((a, b) => b.duration - a.duration);
            setMyData(sdorted);
        }else {
            const sodorted = [...myData].sort((a, b) => a.duration - b.duration);
            setMyData(sodorted);
        }
        setIsSorteddura(prevState => !prevState);
    }
    const toggleTimein = () => {
        if (isSortedti) {
            const stdorted = [...myData].slice().sort((a, b) => {
                const timeA = moment(a.timerin, 'dddd  MMMM Do YYYY h:mm:ss a');
                const timeB = moment(b.timerin, 'dddd  MMMM Do YYYY h:mm:ss a');
                return timeA - timeB;
            });
            setMyData(stdorted);
        }else {
            const sotdorted = [...myData].slice().sort((a, b) => {
                const timeA = moment(a.timerin, 'dddd  MMMM Do YYYY h:mm:ss a');
                const timeB = moment(b.timerin, 'dddd  MMMM Do YYYY h:mm:ss a');
                return timeB - timeA;
            });
            setMyData(sotdorted);
        }
        setIsSortedti(prevState => !prevState);
    }
    const toggleTimeout = () => {
        if (isSortedto) {
            const stdoorted = [...myData].slice().sort((a, b) => {
                const timeA = moment(a.timerout, 'dddd  MMMM Do YYYY h:mm:ss a');
                const timeB = moment(b.timerout, 'dddd  MMMM Do YYYY h:mm:ss a');
                return timeA - timeB;
            });
            setMyData(stdoorted);
        }else {
            const sotdoroted = [...myData].slice().sort((a, b) => {
                const timeA = moment(a.timerout, 'dddd  MMMM Do YYYY h:mm:ss a');
                const timeB = moment(b.timerout, 'dddd  MMMM Do YYYY h:mm:ss a');
                return timeB - timeA;
            });
            setMyData(sotdoroted);
        }
        setIsSortedto(prevState => !prevState);
    }
    const handleSearch = () => {
        const results = myData.filter(item =>
            item.meetemail.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setSearchResults(results);
        setOpenrearchform(true)
    };
    const rendersearchreports = ()=> {
        return searchResults.map((item, index) => (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.meetemail}</td>
                    <td>
                        <Accordion>
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header>Activities</Accordion.Header>
                                <Accordion.Body>
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>Activity</th>
                                            <th> Correct/ Incorrect Answer</th>
                                            <th> Score</th>
                                            <th>Timer In</th>
                                            <th>Timer Out</th>
                                            <th>Duration(Sec)</th>
                                        </tr></thead>
                                        <tbody>
                                        {item.questions && item.questions.map((item, index) => {
                                            const thisas = item.correctanswer;
                                            let thisanswer;
                                            if(thisas === "correct"){
                                                thisanswer = `${Correctimg}`
                                            }else if(thisas === "wrong"){
                                                thisanswer = `${Incorrectimh}`
                                            }else if(thisas === "No answer"){
                                                thisanswer = `${Noanswer}`
                                            }else{
                                                thisanswer = `${Contents}`
                                            }
                                            return(
                                                <>
                                                    <tr key={index}>
                                                        <td>{item.question}</td>
                                                        <td><img src={thisanswer} width="24px"/></td>
                                                        <td>{item.thisscore}</td>
                                                        <td>{item.timerin}</td>
                                                        <td>{item.timerout}</td>
                                                        <td>{item.duration}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </td>
                    <td>{item.score}</td>
                    <td>{item.timerin}</td>
                    <td>{item.timerout}</td>
                    <td>
                        {item.duration > 60 ? Math.round(parseInt(item.duration) / 60) : 0}
                    </td>
                    <td> Higher </td>
                    <td> Reachum </td>
                </tr>
        ))
    }
    const resetdata = () => {
        setOpenrearchform(false);
        setSearchQuery("")
    }
    return (
        <>
            <div className="cccenter">
                <br/>
                <p>Lesson Log</p>
                <h4> {roomname} </h4>
                <Downloadlessonlog  data={id} />
                <div className="leftlessonlogform">
                    &nbsp; &nbsp;
                    <input
                        type="email"
                        placeholder="Search by email"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        className="searchlessonlogform"
                    />
                    {!openrearchform ?
                        <button onClick={handleSearch} className="searchbutton">Search</button>
                        :
                        <button onClick={resetdata} className="searchbutton">Clear</button>
                    }
                </div>
                <div className="rightsidethis">
                    <h5> Participants: {thislength} &nbsp; &nbsp;</h5>
                </div>
                <Table bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th> Name</th>
                        <th><span onClick={toggleEmail} className="togglemenu">{isSortedAlpha ? "Email" : "Email"}</span></th>
                        <th> Activity Log</th>
                        <th><span onClick={toggleScore} className="togglemenu">{isSortedlth ? "Score" : "Score"}</span></th>
                        <th><span onClick={toggleTimein} className="togglemenu">{isSortedti ? "Time In" : "Time In"}</span></th>
                        <th><span onClick={toggleTimeout} className="togglemenu">{isSortedto ? "Time Out" : "Time Out"}</span></th>
                        <th><span onClick={toggleDura} className="togglemenu"> {isSorteddura ? "Duration (min)" : "Duration (min)"}</span></th>
                        <th> Education Level</th>
                        <th> Source</th>
                    </tr>
                    </thead>
                    <tbody>
                    {openrearchform ?
                        <>
                            {rendersearchreports()}
                        </> :
                        <>
                            {renderreports()}
                        </>
                    }
                    </tbody>
                </Table>
            </div>

        </>

    )
}

export default LessonLog;

import React, {useEffect, useState} from "react";
import {API, Auth, Storage} from 'aws-amplify';
import awsExports from "../../../../aws-exports";
import {createVideooptionslist} from '../../../../graphql/mutations';
import { getPlayslide } from "../../../../graphql/queries";
import {useParams,useNavigate} from "react-router-dom";
import { UploadFiles } from './FileUpload';
import Navbars from "../../../Navbar";
import Foot from "../../../Foot";
import "../../../css/uploadimg.css";
import Goback from "../../../../img/dash/Go-Back-Button.png";
import {Card} from "react-bootstrap";
import {v4 as uuid} from "uuid";
import {Helmet} from "react-helmet";
import Grid from "@material-ui/core/Grid";

let vbucket = awsExports.aws_user_files_s3_bucket;
let vregion = awsExports.aws_user_files_s3_bucket_region;
function Uploadvideoques({id}) {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [processing, setProcessing] = useState("");
    const history = useNavigate();
    const [error, setError] = useState('');
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
    }, []);

    const upload = async (files) => {
        setError('');
        const MAX_FILE_SIZE = 20480;
        const file = files[0];
        const fileSizeKiloBytes = file.size / 1024;
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
            setError("File size is greater than maximum limit");
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        }
        const tl = uuid();
        const ext = file.name;
        const exts = ext.replace(/ /g,"_");
        const period = exts.lastIndexOf('.');
        const pluginName = exts.substring(0, period);
        const nfile = `videos/${tl}${exts}`;
        const crid = `${tl}${pluginName}`;
        await Storage.put(nfile, file, {
            contentType: "video/mp4,video/x-ms-wmv,video/webm,video/quicktime,video/3gpp,video/MOV,video/mpeg",
            progressCallback(progress) {
                const lods = progress.loaded;
                const done = progress.total;
                const loadin = Math.floor((lods / done) * 100);
                const per = loadin + "%";
                setProcessing(per)
                if (loadin === 100) {
                    setProcessing("Uploading...")
                }
            },
        }).then(() => {
            const input = {
              id:tl,
                vvideo: {
                    bucket: vbucket,
                    region: vregion,
                    key: nfile
                },
                videoid:crid
            }
           API.graphql({
                query: createVideooptionslist,
                variables: {input}
            });

            setTimeout(() => {
                setProcessing("Wait a minute");
                history(`/videoquizsetup/${id}/${tl}`)
            }, 1000)
        })

            .catch(err => console.log(err));
    }

    return (
        isOwner === isUser ?
        <>
            <div className="uploadpptx">
                <Card className="text-center">
                    <Card.Header as="p">Upload a video up to <span className="redthis">2 minutes</span> long. Avoid spaces or ' in filenames.
                        <br/>
                        Then choose where in the video to popup a related multiple choice question.
                    </Card.Header>
                    <Card.Body>
                    {error && <h5>{error}</h5>}
                    <UploadFiles onUpload={upload} />
                    <p className="redwar">{processing}</p>
                    </Card.Body>
                    <Card.Footer className="text-muted">
                        <Grid container spacing={0}>
                            <Grid item xs={12} className="cccenter">
                            </Grid>
                        </Grid>
                    </Card.Footer>
                </Card>
            </div>
        </>
            :""
    );
}

export default Uploadvideoques;

import React, {useEffect, useState} from "react";
import { useReactMediaRecorder } from 'react-media-recorder';
import {API, Storage} from "aws-amplify";
import {getPlayslide} from "../../../graphql/queries";
import {v4 as uuid} from "uuid";
import awsExports from "../../../aws-exports";
import {updatePlayslide} from "../../../graphql/mutations";
import {Button} from 'react-bootstrap';
import "./style.css";

let vbucket = awsExports.aws_user_files_s3_bucket;
let vregion = awsExports.aws_user_files_s3_bucket_region;
const Recordscreen = ({id, handleClose65}) => {
    const {
        startRecording,
        mediaBlobUrl
    } = useReactMediaRecorder({
        screen: true,
        audio: {
            noiseSuppression: true,
            echoCancellation: true, // Optional: Enable echo cancellation for clearer audio
            autoGainControl: true, // Optional: Stabilize audio volume levels
        }
    });
    const [images, setImages] = useState([]);
    const [processing, setProcessing] = useState("");
    const [openrecord, setOpenrecord] = useState(true);
    const [error, setError] = useState('');
    const tl = uuid();
    useEffect( () => {
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id},

            })
            const datas = response.data.getPlayslide;
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);
    const  addImageTopDB = async (input) => {
        try {
            await API.graphql({
                query: updatePlayslide,
                variables: {input},

            });
        } catch (error) {
            console.log(error)
        }
    }

    const upload = async () => {
        const nfile = `videos/${tl}.webm`;
        const blob = await fetch(mediaBlobUrl).then((r) => r.blob());
        const MAX_FILE_SIZE = 512000;
        const fileSizeKiloBytes = blob.size / 1024;
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
            setError("File size is greater than maximum limit");
            setTimeout(() => {
                window.location.reload()
            }, 500)
        }
        await Storage.put(nfile, blob, {
            contentType: "video/webm",
            progressCallback(progress) {
                const lods = progress.loaded;
                const done = progress.total;
                const loadin = Math.floor((lods / done) * 100);
                const per = loadin + "%";
                setProcessing(per)
                if (loadin === 100) {
                    setProcessing("Uploading...")
                }
            },
        }).then(() => {
            const ryrl = `https://edu.reachum.com/sshowroom/${id}/${tl}`;
            const eryrl = `https://edu.reachum.com/sedit/${id}/${tl}`;
            const vvv = {
                title: "record screen",
                file: {
                    bucket: vbucket,
                    region: vregion,
                    key: nfile
                },
                rid: tl,
                rurl: ryrl,
                eurl: eryrl
            }
            const allImages = [...images, vvv]
            const input = {
                id,
                playlistlinks: allImages
            }
            addImageTopDB(input);
            setTimeout(() => {
                handleClose65()
            }, 800)
        }).catch(err => console.log(err));
    }
function gotorecord(){
    startRecording()
}
    return (
        <>
            <div className="cccenter">
                <h4>Screen and Audio Recorder (2 minutes limit) </h4>
                <br/>
                {openrecord ?
                <p> 1. <span className="boldthis">Click "Start Recording"</span>: Select Screen<br/>
                2. <span className="boldthis">Stop Recording</span>: "Stop Sharing" (Top Left)<br/>
                3. <span className="boldthis">Review Your Recording</span>: Go to Content
                    Manager<br/>
                4. <span className="boldthis">Decide Next Steps</span>: Choose "Use It" or "Re-record" </p>
                    :""
                }
                {error && <h5>{error}</h5>}
                <div>
                    {mediaBlobUrl && (
                        <video
                            src={mediaBlobUrl}
                            controls
                            autoPlay
                            style={{width: '640px', height: '360px'}}
                        />
                    )}
                </div>
                <p>{processing}</p>
                <br/>
                {openrecord ?
                    <>
                        <Button onClick={() => {
                            startRecording();
                            setOpenrecord(false)
                        }} className="mr-10">Start Recording</Button>
                        <br/>
                    </>
                    :
                    <div className="toprecord">
                        <Button onClick={gotorecord} className="mr-5">Re-record</Button>
                        <Button onClick={upload} className="mr-5">Use it</Button>
                    </div>
                }
                <br/>
            </div>
        </>
    );
};

export default Recordscreen

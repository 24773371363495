import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateTextslide} from '../../graphql/mutations';
import {Button, Form, Table} from "react-bootstrap";
import {getPlayslide, getTextslide} from "../../graphql/queries";
import {useParams} from "react-router-dom";
import "../css/poll.css";
import DeleteIcon from "../../img/dash/Delete-Button-X.png";
import Add from "../../img/dash/Add-Button.png";
import Grid from "@material-ui/core/Grid";
import Collapse from "react-bootstrap/Collapse";
import {HexColorPicker} from "react-colorful";

function Textslideedit() {
	const { id,rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [color, setColor] = useState();
	const [backcolor, setBackcolor] = useState();
	const [listcolor, setListcolor] = useState();
	const [dtitle, setDtitle] = useState('')
	const [items1, setItems1] = useState([{ plist: '' }])
	const [open, setOpen] = useState(false);
	const [open1, setOpen1] = useState(false);
	const [open2, setOpen2] = useState(false);
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
		})()
	}, []);
	useEffect( () => {
		(async() => {
			const response = await API.graphql({
				query: getTextslide,
				variables:{id:rid}
			})
			const datas = response.data.getTextslide;
			const dn = datas.pfname;
			setDtitle(dn)
			const i1 = datas.ptest;
			if(i1 === null){
				console.log("none")
			}else {
				setItems1(i1)
			}
			const thisc = datas.titlecolor;
			document.getElementById("thiscolor").style.color = thisc;
			const thislistc = datas.listcolor;
			const allWithClass = Array.from(
				document.querySelectorAll('.editslidemessage')
			);
			allWithClass.forEach(element => {
				element.style.color = thislistc;
			});
			const thisbgcolor = datas.bgcolor;
			document.getElementById("textpageback").style.backgroundColor = thisbgcolor;
		})()
	}, []);

	function changedtitle(event){
		setDtitle(event.target.value)
	}
	function addItem1 () {
		setItems1(state => [...state, ''])
	}

	const items1View = items1.map((item, index) => {
		function deleteItem () {
			setItems1(state => {
				return state.filter((item, stateIndex) => index !== stateIndex)
			})
		}
		function changeItem (event) {
			event.persist()
			setItems1(state => {
				return state.map((item, stateIndex) => {
					const match = index === stateIndex
					if (match) {
						const newItem = { ...item, plist: event.target.value, id: stateIndex }
						return newItem
					}
					return item
				})
			})
		}
		return (
			<li key={index} className="listdistanse">
				<textarea
					value={item.plist}
					onChange={changeItem}
					className="editslidemessage"
						  rows="2"
						  cols="80"
				/>
				<img src={DeleteIcon} onClick={deleteItem} className="dragdropdele" />
			</li>
		)
	})
	function thisvalue(e){
		const c = e.target.value;
		setColor(c);
		document.getElementById("thiscolor").style.color = c
	}
	function thistextvalue(e){
		const cc = e.target.value;
		setListcolor(cc);
		const allWithClass = Array.from(
			document.querySelectorAll('textarea.slidemessage')
		);
		allWithClass.forEach(element => {
			element.style.color = cc;
		});
	}
	function thisbgvalue(e){
		const ce= e.target.value;
		setBackcolor(ce);
		document.getElementById("textpageback").style.backgroundColor = ce
	}
	function changeColor(e){
		setColor(e)
		document.getElementById("thiscolor").style.color = e
	}
	function changebackColor(e){
		setBackcolor(e)
		document.getElementById("textpageback").style.backgroundColor = e
	}
	function changelistColor(e){
		setListcolor(e)
		const allWithClass = Array.from(
			document.querySelectorAll('.editslidemessage')
		);
		allWithClass.forEach(element => {
			element.style.color = e;
		});
	}
	async function submitItems (event) {
		event.preventDefault()
		try {
			const input = {
				id: rid,
				titlecolor:color,
				listcolor:listcolor,
				bgcolor:backcolor,
				pfname:dtitle,
				ptest:items1
			}
			await API.graphql({
				query: updateTextslide,
				variables: {input}
			});
			setUploadedmessage("Updated!")

		} catch (err) {
			console.log(err)
		}
	}
	return (
		isOwner === isUser ?
		<div className="textlistpage">
			<Grid container spacing={0}>
				<Grid item xs={2}>
				</Grid>
				<Grid item xs={3}>
					<Button
						onClick={() => setOpen(!open)}
						aria-controls="example-collapse-text"
						aria-expanded={open}
						size="sm"
						variant="light"
					>
						Update Title Color
					</Button>
					<div style={{ minHeight: '100px' }}>
						<Collapse in={open} dimension="width">
							<div id="example-collapse-text">
								<div>
									<HexColorPicker color={color} onChange={changeColor}/>
									<input type="text" value={color} onChange={thisvalue} className="thiscolorchange" placeholder="#"/>
								</div>
							</div>
						</Collapse>
					</div>
				</Grid>
				<Grid item xs={3}>
					<Button
						onClick={() => setOpen1(!open1)}
						aria-controls="example-collapse-text1"
						aria-expanded={open1}
						size="sm"
						variant="light"
					>
						Update Body Text Color
					</Button>
					<div style={{ minHeight: '100px' }}>
						<Collapse in={open1} dimension="width">
							<div id="example-collapse-text1">
								<div>
									<HexColorPicker color={listcolor} onChange={changelistColor}/>
									<input type="text" value={listcolor} onChange={thistextvalue} className="thiscolorchange" placeholder="#"/>
								</div>
							</div>
						</Collapse>
					</div>
				</Grid>
				<Grid item xs={3}>
					<Button
						onClick={() => setOpen2(!open2)}
						aria-controls="example-collapse-text2"
						aria-expanded={open2}
						size="sm"
						variant="light"
					>
						Update Background Color
					</Button>
					<div style={{ minHeight: '100px' }}>
						<Collapse in={open2} dimension="width">
							<div id="example-collapse-text2">
								<div>
									<HexColorPicker color={backcolor} onChange={changebackColor} />
									<input type="text" value={backcolor} onChange={thisbgvalue} className="thiscolorchange" placeholder="#"/>
								</div>
							</div>
						</Collapse>
					</div>
				</Grid>
				<Grid item xs={1}>
				</Grid>
			</Grid>
			<form onSubmit={submitItems}>
				<div id="textpageback">
				<Form.Group className="mb-3">
					<Form.Label>Update Title:</Form.Label>
					<br/>
					<textarea
						value={dtitle}
						onChange={changedtitle}
						className="editslidecaption"
						id="thiscolor"
						rows="2"
						cols="70"
					/>
				</Form.Group>
				<Form.Group className="mb-3" >
					<Form.Label>Update Body Text:</Form.Label>
							<ul>
								{items1View}
							</ul>
				</Form.Group>
				</div>
				<div className="left20">
					<img src={Add} onClick={addItem1} />
				</div>
				<br/>
				<Table>
					<tr>
						<td>
						</td>
						<td className="orderright">
							{uploadedmessage} <input type="submit" value="Submit" id="summitb" />
						</td>
					</tr>
				</Table>
			</form>
		</div>
			:""
	);
}
export default Textslideedit;

import React, {useState, useMemo, useEffect} from 'react';
import {API} from "aws-amplify";
import {createEngagement, updateLeaderboard} from "../../../graphql/mutations";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Grid from "@material-ui/core/Grid";
import {Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../css/taptap.css";
import Thiswrong from "../../../img/view/close.png";
import Thiscorrect from "../../../img/view/check-mark.png";
import moment from "moment";

function shuffle(items) {
  const entries = items.map((item) => [Math.random(), item])
  entries.sort((a, b) => a[0] - b[0])
  return entries.map((entry) => entry[1])
}

export default function Showitemlist() {
  const [quest, setQuest] = useState();
  const [timeval, setTimeval] = useState(0);
  const { id, rid } = useParams();
  const [messages, setMessages] = useState();
  const [thissaved, setThissaved] = useState([]);
  const [isShows, setIsShows] = useState(true);
  const [isshowreset, setIsshowreset] = useState(false);
  const [playpause, setPlaypause] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [counter, setCounter] = useState();
  const [submittedmessage, setSubmittedmessage] = useState("");
  const [showclock, setShowclock] = useState(false);
  const [usertackingid, setUsertackingid] = useState("");
  const [usertotascore, setUsertotascore] = useState("");
  const [thisusername, setThisusername] = useState("");
  sessionStorage.setItem("edureachumthisrid", rid)
  useEffect(() => {
    const newCards=sessionStorage.getItem("sequencinggamesques");
    setQuest(newCards);
    const thisid = sessionStorage.getItem("edureachumthisid");
    setUsertackingid(thisid);
    const thisscore = sessionStorage.getItem("edureachumthistotalscore");
    setUsertotascore(thisscore);
    const newcounter=Number(sessionStorage.getItem("sequencinggamescounter"));
    const tuser = sessionStorage.getItem("edureachumthisuser");
    setThisusername(tuser);
    if(newcounter !== 0) {
      setCounter(newcounter);
      setShowclock(true);
    }else{
      setTimeval(40)
    }
    const submitrids = sessionStorage.getItem("submitrid");
    if(submitrids){
      setThissaved(submitrids)
      if(submitrids.includes(rid)){
        setDisabled(true);
        setSubmittedmessage("Submitted")
      }
    }
  }, []);
  const storedItems = useMemo(() => {
    const stored = sessionStorage.getItem('sequencinggames');
    if (stored == null) {
      return []
    }
    return JSON.parse(stored)
  }, [])
  const [items, setItems] = useState(() => {
    return shuffle(storedItems)
  })
  const defaultAnswers = items.map(() => {
    return {
      id: '',
      text: '',
      image: ''
    }
  })

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setTimeval(0)
      return <div className="timer">0</div>;
    }else{
      setTimeval(remainingTime)
    }
    return (
        <div className="timer">
          <div className="value">{remainingTime}</div>
        </div>
    );
  }
  const [activeItem, setActiveItem] = useState()

  const [answers, setAnswers] = useState(() => {
    return defaultAnswers
  })
  const answerViews = answers.map((answer, answerIndex) => {
    function handleClick () {
      if (activeItem == null) {
        return
      }
      setAnswers(currentAnswers => {
        const newAnswers = currentAnswers.map((currentAnswer, currentAnswerIndex) => {
          if (answerIndex === currentAnswerIndex) {
            return activeItem
          }
          return currentAnswer
        })
        return newAnswers
      })
      setActiveItem(undefined)
    }
    const key = answer.id !== '' ? answer.id : answerIndex
    const image = answer.image !== '' && (
        <img src={answer.image} style={{ width: '170px' }} />
    )
    const correctIcon = answer.correct === true && (<img className="correctandincorrect" src={Thiscorrect} width="20px"/>)
    const incorrectIcon = answer.correct === false && (<img className="correctandincorrect" src={Thiswrong} width="20px"/>)
    const correctAnswer = answer.correctAnswer != null && (
        <div style={{marginTop:"8px"}}><span style={{color: "#269b7b"}}> {answer.correctAnswer.text} </span> <img src={answer.correctAnswer.image} style={{ width: '100px' }} /></div>
    )
    return (
        <>
        <li key={key} onClick={handleClick} style={{fontSize: "20px", marginLeft:"8px", marginBottom:"8px", backgroundColor:"#f0f0f0", color:"#0c4128", padding:"5px"}}>
          <div className="centerthis">
            <>{correctIcon} {incorrectIcon}</>
            <span style={{marginLeft:"5px"}}>{answer.text}</span> &nbsp; &nbsp;
            {image}
            <br/>
            {correctAnswer}
          </div>
        </li>
          </>
    )
  })
  const remainingItems = items.filter(item => {
    const answered = answers.some(answer => answer.id === item.id)
    return !answered
  })
  const remainingViews = remainingItems.map(item => {
    function handleClick () {
      setActiveItem(item);
      setIsshowreset(true)
    }
    const isActive = activeItem != null && activeItem.id === item.id;
    const style = { margin: '5px', padding: '10px', fontSize:"20px", fontFamily: "forma-djr-micro, sans-serif", border:"1px solid #d2196e",borderRadius:"10px" }
    if (isActive) {
      style.background='#72b5a4';
      style.color="#ffffff";
      style.borderRadius="8px";
      style.transform="scale(1.5, 1.5)"
    }
    const image = item.image !== '' && (
        <img src={item.image} style={{ width: '170px' }} />
    )
    return (
        <div
            key={item.id}
            style={style}
            onClick={handleClick}
        >
          <span>{item.text}</span><br/>
          {image}
        </div>
    )
  })
  function handleReset () {
    setAnswers(defaultAnswers);
    setIsshowreset(false)
  }

  function handleSubmit() {
    const corrects = answers.filter((answer, answerIndex) => {
      const item = storedItems[answerIndex]
      return item.id === answer.id
    })
    const vv = corrects.length;
    const m = items.length;
    let tt = [];
    if (thissaved) {
      tt = [thissaved, rid]
    } else {
      tt = [rid]
    }
    sessionStorage.setItem("submitrid", tt);
    setPlaypause(false);
    const ttr = timeval * vv;
    const cm = ttr / m;
    const scre = Math.floor(cm);
    if (scre > 0) {
      sessionStorage.setItem("edureachumthiscorrcr", "correct")
      sessionStorage.setItem("edureachumthisscore", scre)
      const tts = scre + Number(usertotascore);
      try {
        const input = {
          id: usertackingid,
          name:thisusername,
          thisgamescore: scre,
          score: tts
        }
        API.graphql({
          query: updateLeaderboard,
          variables: {input}
        });
      } catch (err) {
        console.log(err)
      }
      setMessages(`You scored ${scre} points.`)
    } else {
      sessionStorage.setItem("edureachumthiscorrcr", "wrong")
      sessionStorage.setItem("edureachumthisscore", 0)
      try {
        const input = {
          id: usertackingid,
          name:thisusername,
          thisgamescore: 0
        }
        API.graphql({
          query: updateLeaderboard,
          variables: {input}
        });
      } catch (err) {
        console.log(err)
      }
      setMessages("You scored 0 points.")
    }
    try {
      const input = {
        questionid: id,
        engage: 1,
        people: 1
      }
      API.graphql({
        query: createEngagement,
        variables: {input}
      });
    } catch (err) {
      console.log(err)
    }
    const newAnswers = answers.map((currentAnswer, index) => {
      const correctAnswer = storedItems[index];
      const answeredCorrect = currentAnswer.text === correctAnswer.text;
      if (answeredCorrect) {
        return {
          ...currentAnswer,
          correct: true
        };
      } else {
        return {
          ...currentAnswer,
          correct: false,
          correctAnswer
        };
      }
    });
    setAnswers(newAnswers);
    setIsShows(false);
  }

  const getdatas = async () => {
    const mymeetemals = localStorage.getItem("vreachumneweremail");
    if (mymeetemals) {
      sessionStorage.setItem("edureachumthiscorrcr", "No Answer")
      sessionStorage.setItem("edureachumthisscore", 0)
      try {
        const input = {
          id: usertackingid,
          name:thisusername,
          thisgamescore: 0
        }
        API.graphql({
          query: updateLeaderboard,
          variables: {input}
        });
      } catch (err) {
        console.log(err)
      }
      setMessages("You scored 0 points.")
      setIsShows(false);
      try {
        const input = {
          questionid: id,
          engage: 0,
          people: 1
        }
        API.graphql({
          query: createEngagement,
          variables: {input}
        });
      } catch (err) {
        console.log(err)
      }
    } else {
      setMessages("Time is up.")
      setIsShows(false);
    }
  }
  useEffect(() => {
    const onClose = () => {
      const start = sessionStorage.getItem("edureachumthisstarttimer");
      const thisrid = sessionStorage.getItem("edureachumthisrid");
      const thisques = sessionStorage.getItem("edureachumthisquestion");
      const thisqueslist = sessionStorage.getItem("edureachumthisques");
      const thiscorrect = sessionStorage.getItem("edureachumthiscorrcr");
      const thisscore = sessionStorage.getItem("edureachumthisscore");
      const thisid = sessionStorage.getItem("edureachumthisid");
      const ends = moment().format('MM/DD/YYYY hh:mm a');
      const end = new Date();
      const timeDiff = end - new Date(start);
      const seconds = parseInt((timeDiff / 1000) % 60);
      try {
        const thisqq = {
          rid: thisrid,
          question: thisques,
          correctanswer: thiscorrect,
          thisscore: thisscore,
          duration: seconds
        }
        let qq;
        if (thisqueslist) {
          const objectArray = JSON.parse(thisqueslist);
          qq = [...objectArray, thisqq];
        } else {
          qq = [thisqq]
        }
        const input = {
          id: thisid,
          questions: qq,
          timerout: ends
        }
        API.graphql({
          query: updateLeaderboard,
          variables: {input}
        });
      }catch (err) {
        console.log(err)
      }
    }
    window.addEventListener('beforeunload', onClose)
    window.addEventListener('blur', onClose);
    window.addEventListener('pagehide', onClose);
    return () => {
      window.removeEventListener('beforeunload', onClose);
      window.removeEventListener('blur', onClose);
      window.removeEventListener('pagehide', onClose);
    }
  }, []);
  return (
      <div className="fastanswerspage2">
        <div className="greenback">
          <Grid container spacing={0}>
            <Grid item xs={2}>
              {showclock ?
                  <div className="lpollheadclock2">
                    <CountdownCircleTimer
                        isPlaying={playpause}
                        duration={counter}
                        size={50}
                        colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                        colorsTime={[10, 6, 3, 0]}
                        onComplete={getdatas}
                    >
                      {renderTime}
                    </CountdownCircleTimer>
                  </div>
                  : ""
              }
            </Grid>
            <Grid item xs={10}>
              <div className="fastanswes">
                <h2>{quest}</h2>
              </div>
            </Grid>
          </Grid>
        </div>
        <br/>
        <>
          {isShows ?
              <div style={{
                display: 'flex',
                flexWrap: "wrap",
                width: "100%",
                margin: "0 auto",
                padding: "10px",
                alignItems: "center",
                backgroundColor: "#f0f0f0",
                justifyContent: "center"
              }}>
                {remainingViews}
              </div>
              : ""
          }
                <br/>
                <br/>
                <ol style={{textAlign: "left", margin: "10px", width: "98%"}}>
                  {answerViews}
                </ol>
                <br/>
          {isShows ?
              <>
                <Grid container spacing={0}>
                  <Grid item xs={1}>
                  </Grid>
                  <Grid item xs={5} className="wordsleftside">
                    {isshowreset ?
                        <Button onClick={handleReset} variant="danger">Reset</Button>
                        : ""
                    }
                  </Grid>
                  <Grid item xs={5} className="rightside">
                      <Grid item xs={12}>
                        <p className="redthis"> {submittedmessage} </p>
                    <Button onClick={handleSubmit} variant="success"
                            disabled={isDisabled}>Submit</Button>
                      </Grid>
                  </Grid>
                  <Grid item xs={1}>
                  </Grid>
                </Grid>
                <br/>
                <br/>
                <br/>
              </> :
              <>
                <div className="cccenter">
                  <br/>
                  <p className="pollmaeeage">{messages}</p>
                  <br/>
                  <br/>
                  <br/>
                </div>
              </>
          }
        </>
      </div>


  )
}

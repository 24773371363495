import React, {useEffect} from "react";
import {Hub} from 'aws-amplify';
import Presignup from "./presignup";
import '@aws-amplify/ui-react/styles.css'
import "../css/signup.css";

const Signup = () => {
    useEffect(() => {
        Hub.listen('auth', (data) => {
            const { payload } = data;
            onAuthEvent(payload);
            window.location.replace("https://edu.reachum.com")
        })
    }, []);
    function onAuthEvent(payload)  {
        switch (payload.event) {
            case "signUp":
                break;
            default:
                return
        }
    }
    return (
        <>
            <Presignup />
        </>
    )
}

export default Signup

import React, {useEffect, useRef, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateDesmosfillin} from '../../../../graphql/mutations';
import { useForm } from "react-hook-form";
import {getDesmosfillin, getPlayslide} from "../../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../../css/fastaneditpage.css";
import DesmosCalculator from "../DesmosCalculator";
import Resizer from "react-image-file-resizer";
import Grid from "@material-ui/core/Grid";

const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			400,
			400,
			"JPEG",
			95,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});
function Mathfastansweredit() {
	const { id,rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [fname, setFname] = useState("");
	const [ftimers, setFtimers] = useState(0);
	const [fques, setFques] = useState("");
	const [thisimage, setThisimage] = useState('')
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [fansw, setFansw] = useState("");
	const [showtimerform, setShowtimerform] = useState(false)
	const addRef = useRef(null)
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
		})()
	}, []);
	useEffect( () => {
		(async() => {
			const responses = await API.graphql({
				query: getDesmosfillin,
				variables:{id:rid}
			})
			const datas = responses.data.getDesmosfillin;
			const ffname = datas.ffname;
			setFname(ffname)
			const ftimer = datas.ftimer;
			if(ftimer !== 0) {
				setFtimers(ftimer);
				setShowtimerform(true)
			}
			const fquestion = datas.fquestion;
			setFques(fquestion)
			const fanswesr = datas.fanswer;
			setFansw(fanswesr)
			const mimage = datas.image;
			if(mimage !== null){
				setThisimage(mimage)
			}
		})()
	}, [rid]);

	const handleFileChange = async (event) => {
		const img = event.target.files[0];
		const resized = await resizeFile(img);
		setThisimage(resized)
	};
	const {register, handleSubmit} = useForm({});
	const onSubmit = async (data) => {
		const calculatorState = addRef.current.getState()
		const latexExpressions = calculatorState.expressions.list.filter(expression => {
			return expression.latex != null
		})
		let resized;
		if (latexExpressions.length === 0) {
			resized = "";
		} else {
			const screenshot = addRef.current.screenshot()
			const response = await fetch(screenshot)
			const blob = await response.blob()
			resized = await resizeFile(blob)
		}
		try {
			const input = {
				id:rid,
				ftimer:ftimers,
				fquestion:data.fquestion,
				fanswer:data.fanswer,
				mathstate:resized,
				image:thisimage
			}
			await API.graphql({
				query: updateDesmosfillin,
				variables: {input}
			});
			setUploadedmessage(" Updated ")
		} catch (err) {
			console.log(err)
		}
	}
	return (
		isOwner === isUser ?
		<>
			<div className="mathfaspage">
				<br/>
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0}>
				<Grid item xs={2}>
				Game name:
				</Grid>
				<Grid item xs={10}>
					{fname}
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					Timer : &nbsp;
				</Grid>
				<Grid item xs={10}>
					<Grid container spacing={0}>
						<Grid item xs={7} className="cccenter">
							<input type="radio" name="timer" value="AddTimer" onChange={()=>{setShowtimerform(true)}}/> Add Timer (Seconds) &nbsp;
							{showtimerform ?
								<input
									name="ftimer"
									value={ftimers}
									className="timers"
									type="number"
									maxLength="3"
									onChange={(e)=>setFtimers(e.target.value)}
								/>
								: ""
							}
						</Grid>
						<Grid item xs={5} className="cccenter">
							<input type="radio" name="timer" value="NoTimer" onChange={()=>{
								setShowtimerform(false);
								setFtimers(0)
							}} /> No Timer
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={2}>
				Question :
				</Grid>
				<Grid item xs={10}>
				<textarea
					name="fquestion"
					defaultValue={fques}
					ref={register({ required: true, maxLength: 200})}
					className="quesmathinput"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={2} className="leftside">
					<p>Image:  </p>
				</Grid>
				<Grid item xs={10} className="leftside">
					<input type="file" onChange={handleFileChange} />
				</Grid>
			</Grid>
			<br/>
			<div className="mathcccenter">
				<img src={thisimage} className="pximages400" />
			</div>
			<br/>
			<DesmosCalculator ref={addRef} />
			<br/>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
					<p className="biggertext">Correct answer:</p>
				</Grid>
				<Grid item xs={9} className="leftside">
					<input
						name="fanswer"
						defaultValue={fansw}
						ref={register({ required: true, maxLength: 100})}
						className="fullline"
					/>
				</Grid>
			</Grid>
		<br/>
			<Grid container spacing={0}>
				<Grid item xs={11} className="rightside">
					<span className="redthis"> {uploadedmessage} </span> <input type="submit" value="Update" className="answersubmit"/>
				</Grid>
				<Grid item xs={1}>
				</Grid>
			</Grid>
		</form>
			</div>
			<br/>
			<br/>
			</>
			:""
	);
}
export default Mathfastansweredit;

import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import "../css/room.css";
import {Form, Input} from "element-react";
import {Button} from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {useNavigate, useParams} from "react-router-dom";
import logo from '../Black.png';
import {v4 as uuid} from "uuid";

function EmojiPicker({ onSelect }) {
    const emojis = ["(ง︡'-'︠)ง", "≧◉ᴥ◉≦", "ʕ•́ᴥ•̀ʔっ", "✍(◔◡◔)", "(っ＾▿＾)💨", "(❛‿❛✿̶̥̥)","(^◡^ )", "(ɔ◔‿◔)ɔ ♥", "(>‿◠)✌", "( ͡❛ ͜ʖ ͡❛)",
        "(.•̃ ᴗ•̃.)","(╥﹏╥)","(ᵔ̃ ⏥ᵔ̃)","( ◡́.◡̀)",
        "(ᵔ̃ ‿‿ᵔ̃)","(ᵔ̃ ‿ᵔ̃)","( ͠👁️ ͜ʖ ͡👁️)","( ͠ᵔ ͜ʖ ͡ᵔ)","(ᵔ̃ ͜ʖᵔ̃)","（ つ︣﹏╰）",
        "( ͡❛ ‿‿ ͡❛)","( ͡° ‿‿ ͡°)","( ͡ᵔ ‿‿ ͡ᵔ)",
        "(.•̃ ⏥•̃.)","(͡° ͜ʖ ͡°)","(.👁️̃ ⏥👁️̃.)","(◑_◑)","¯\\_( ͠👁️ ͜ʖ ͡👁️)_/¯","(̶◉͛‿◉̶)","(.•̃ ͜ʖ•̃.)","¯\\_( ͠~ ͜ʖ ͡°)_/¯","(.°̃ ͜ʖ°̃.)","(. •︠ ͜ʖ ︡•.)",
        "(. •︠ ⏥ ︡•.)","( ͡ᵔ ‿‿͡ᵔ )","(ᵔ̃ ‿‿ᵔ̃)","(👁️̃ ‿‿👁️̃)","ಥ_ಥ","ᕙ(`▿´)ᕗ",
        "¯\\_(•̃ ⏥•̃)_/¯","( •︣ ▿ •︣ )","( ͡👁️ ‿‿ ͡👁️)","(ㆆ_ㆆ)","(👁️̃ ‿‿👁️̃)","( ͠ᵔ ‿‿ ͡ᵔ)✌","( ͠ᵔ ͜ʖ ͡ᵔ)✌","( ˘︹˘ )","t(>.<t)","(≖_≖ )","(͠◉_◉᷅ )","(◑́_◑᷅ )", "( ͡• ▿ ͡•)"];
    return (
        <div>
            {emojis.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function EmojiPicker1({ onSelect }) {
    const emojis1 = ["🥪", "🥙", "🥯", "🍖", "☕", "🥛", "🥚", "🍠", "🍥", "🍓",
        "🍳","🍱", "🍤","🥤","🧋", "🍷","🍹","🍸","🍻","🍺","🍦",
        "🍨","🍧","🎂","🍰","🍯","🍫","🍬","🍭","🍡","🍩","🍪", "🍘","🍮","🍌","🍉","🍊","🍍","🍎","🍏","🍑","🍋",
        "🍈","🍐","🍒","🍇","🍅","🍆","🌽","🍣","🍚","🍛","🍙","🍲","🍜","🍝","🍢","🍞","🍗","🍟","🍔","🍕","🍄"];
    return (
        <div>
            {emojis1.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function EmojiPicker2({ onSelect }) {
    const emojis2 = ["🚚", "🚔", "🚑", "🚓", "🚒", "🚕", "🚖", "🚛",
        "🚐","🚗", "🚘","🚙","🚜", "🚉","🚊","🚇","🚥","🚆","🚞","🚲","🏍","🦽","🦼","🛺","🛵","🛻","🏎","🚍","⛽",
        "🚄","🚅","🚝","🚈","🚠","🚡","🚎","🚃","🚋","🚌","✈", "🚁","⚓","🚣","⛵","🏄","🏊","🚀","🛴"];
    return (
        <div>
            {emojis2.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function EmojiPicker3({ onSelect }) {
    const emojis3 = ["⚽", "(づ˶•༝•˶)づ  🏀", "🤺","⚾","🥎","🏐","🏈","🏉","🎾","🥏","🎳","🏏","🏑","🏒","🥍","🏓","🏸","🥊","🥋",
        "🥅","⛳","⛸️","🎣","🤿","🎽","🎿","🛷","🥌"];
    return (
        <div>
            {emojis3.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function Vform() {
    const [openemojis, setOpenemojis] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState(null);
    const history = useNavigate();
    const { id } = useParams();
    const t = uuid()
    const [stateData, setStateData] = useState({
        meetname:"",
        meetemail:""
    });
    const [isValid, setIsValid] = useState(true);
    const createfinalText = selectedEmoji ? `${stateData.meetname} ${selectedEmoji}` : stateData.meetname;
    const handleSubmit2 = async event => {
        event.preventDefault();
        if(isValid === true) {
        try {
            const ssname = stateData.meetname;
            const createfinalText = selectedEmoji ? `${ssname} ${selectedEmoji}` : ssname;
            const ssemail = stateData.meetemail;
            localStorage.setItem("vreachumnewername",createfinalText);
            localStorage.setItem("vreachumneweremail", ssemail);
            history(`/groupleader/${id}/${t}`);
            sessionStorage.setItem("updatedusername", "1")
        }catch(err){
            console.log(err)
        }
        }
    }
    function thisprivacy(){
        window.open("https://reachum.com/terms-and-conditions/", "_blank")
    }
    function openemoji(){
        if(openemojis === false) {
            setOpenemojis(true)
        }else{
            setOpenemojis(false)
        }
    }
    return (
            <>
                        <div className="enterfirstyournameform">
                            <img src={logo} alt="Reachum" className="logos" />
                                <Form className="leftside">
                                    <Form.Item>
                                        <span> Enter Email*</span>
                                        <br/>
                                        <Input
                                            type="email"
                                            value={stateData.meetemail}
                                            onChange={meetemail => {
                                                setStateData({
                                                    ...stateData,
                                                    meetemail: meetemail
                                                });
                                                const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                                const isValidemail = emailRegex.test(meetemail);
                                                setIsValid(isValidemail);
                                            }}
                                            maxLength={100}
                                            placeholder="Your Email"
                                            className="craeteroomgame"
                                        />
                                        {!isValid && <p style={{ color: '#d2196e' }}>Please enter a valid email address.</p>}
                                    </Form.Item>
                                    <Form.Item>
                                        <span> Create Display Name:</span>
                                        <br/>
                                        <Input
                                            type="text"
                                            value={stateData.meetname}
                                            onChange={meetname => setStateData({
                                                ...stateData,
                                                meetname: meetname
                                            })}
                                            maxLength={50}
                                            placeholder="Text"
                                            className="craeteroomgame"
                                        />
                                        <br/>
                                        Display Name Preview: <br/> {createfinalText}
                                    </Form.Item>
                                    <Form.Item>
                                        <Grid container spacing={0}>
                                            <Grid item xs={6}>
                                            </Grid>
                                            <Grid item xs={6} className="rightside">
                                                <Button
                                                    disabled={!stateData.meetemail || !stateData.meetname}
                                                    onClick={handleSubmit2}
                                                    size="sm"
                                                    className="ibuttnsform"
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form.Item>
                                </Form>
                            <div>
                                <p className="thisfontsize">Click <span className="underscored" onClick={openemoji}>here to</span> emojize your Display Name </p>
                                {openemojis ?
                                    <Tabs
                                        defaultActiveKey="Emoticons"
                                        id="fill-tab-example"
                                        className="mb-3"
                                        fill
                                    >
                                        <Tab eventKey="Emoticons" title="Emoticons">
                                            <EmojiPicker onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                        </Tab>
                                        <Tab eventKey="Food" title="Food">
                                            <EmojiPicker1 onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                        </Tab>
                                        <Tab eventKey="Car" title="car">
                                            <EmojiPicker2 onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                        </Tab>
                                        <Tab eventKey="Sport" title="Sport">
                                            <EmojiPicker3 onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                        </Tab>
                                    </Tabs>
                                    : ""
                                }
                                <br/>
                            </div>
                            <p className="privacytext">
                                We respect your privacy. Data is stored for game and progress tracking only. REACHUM does not share information with third parties. Read our full privacy policy <span
                                className="privacytextspan" onClick={thisprivacy}>here</span>.</p>
                        </div>

        </>
    );
}
export default Vform;

import {API, Auth} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide, listLeaderboards} from "../../graphql/queries";
import {createLeaderboard, updateLeaderboard, updatePlayslide} from "../../graphql/mutations";
import {useParams,useHistory} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';
import Login from "../../pages/login";
import Grid from '@material-ui/core/Grid';
import Right from "../../img/host/next-btn.png";
import Left from "../../img/host/back-btn.png";
import "../css/mroom.css";
import {Helmet} from "react-helmet";
import Timeron from "../../img/host/Timer.png";
import Noi from "../../img/no.png";
import Lists from "../../img/host/List.png";
import Modal from 'react-modal';
import Fullscreen from "../../img/host/fullscreen.png";
import Expand from "../../img/host/expand.png";
import Close from "../../img/host/close.png";
import Reacltimewhiteboard from "../../img/host/whiteboard.png"
import Math from "../../img/host/mathematics.png"
import moment from "moment";

Modal.setAppElement('#root');
const Popup = ({ src }) => {
    return (
        <div className="popupqr">
            <div className="popupqr-inner">
                <iframe src={src} title="QR code" className="thisqrcode" loading="lazy"></iframe>
            </div>
        </div>
    );
};
function Teamsmroom() {
    const [urls, setUrls] = useState([]);
    const [user, setUser] = useState();
    const [count, setCount] = useState(0);
    const [isShows, setIsShows] = useState(true);
    const [mylists, setMylists] = useState([]);
    const [isShowfulls, setIsShowfulls] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupqr, setShowPopupqr] = useState(false);
    const [showPopupwb, setShowPopupwb] = useState(false);
    const [showPopupmath, setShowPopupmath] = useState(false);
    const [showPopupto, setShowPopupto] = useState(false);
    const [totalslide, setTotalslide] = useState(1);
    const [currentslide, setCurrentslide] = useState(1);
    const { id } = useParams();
    sessionStorage.removeItem("reachumvideosilent")
    const rechumemail = localStorage.getItem("vreachumneweremail")
    if(rechumemail){
        localStorage.removeItem("vreachumnewername");
        localStorage.removeItem("vreachumneweremail")
    }
    useEffect(() => {
        (async () => {
            const input = {
                id,
                group: "noselfstudy"
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        })()
    }, []);
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user.attributes.email);
            })
            .catch((err) => console.log(err));
        async function fetchData() {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const vv = datas.vplaypause;
            if(vv === true){
                setIsShows(false)
            }else{
                setIsShows(true)
            };
            const cid = datas.id;
            const h = datas.playlistlinks;
            let lists = [];
            const ilists = h.length; // playlist length
            setTotalslide(ilists);
            for (let j = 0; j < ilists; j++) {
                const parray = h[j].rurl;
                lists.push(parray)
            };
            setMylists(lists);
            let i = ilists - 1;
            if (count <= i) {
                setUrls(h[count].rurl);
                setCurrentslide(count + 1)
                const input = {
                    id: cid,
                    aplaypause:false,
                    clink: h[count].rurl
                };
                await API.graphql({
                    query: updatePlayslide,
                    variables: {input}
                });
            }else {
                setCount(0)
            }
        }
        fetchData();
    }, [count,id]);
    useEffect(() => {
        const starts = moment().format('MM/DD/YYYY hh:mm a');
        (async () => {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: "instructor@ins.com"
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            sessionStorage.setItem("edureachumthisid", thisid)
                        }else{
                            const input = {
                                name: "Instructor",
                                meetemail: "instructor@ins.com",
                                answerid: id,
                                score: 0,
                                thisgamescore: 0,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            sessionStorage.setItem("edureachumthisid", thistimerid)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
        })()
    }, []);

    const renderSlides = () => {
        return (
            <>
                    {mylists.map((slideContent, index) => (
                        <div className="slides" key={index}>
                                <img src={Noi} className="noi" width="125" height="72" onClick={ () => {
                                    const input = {
                                        id,
                                        aplaypause:false,
                                        clink: slideContent
                                    };
                                    API.graphql({
                                        query: updatePlayslide,
                                        variables: {input}
                                    });
                                    const thelist = document.getElementById('theFrame');
                                    thelist.src = slideContent;
                                    setShowPopup(false);
                                }} />
                                <iframe src={slideContent} scrolling="no" className="editsframe"></iframe>
                            </div>
                    ))}
            </>
        );
    }

    useHotkeys('n',()=>{
        setCount(counts => counts + 1);
    })
    useHotkeys('b',()=>{
        setCount(counts => counts - 1);
    })
    useHotkeys('t',()=>{
        timeron()
    })
    function handlePopupButtonClick() {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }
        if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        }
        setShowPopup(true);
        setIsShowfulls(false)
    }
    function handlefullscreen(){
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }
        if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        }
    }
    function handleminiscreen(){
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }
    function handleFullscreenChange() {
        if (!document.fullscreenElement) {
            setShowPopup(false);
            setIsShowfulls(true)
        }
        if (!document.webkitFullscreenElement) {
            setShowPopup(false);
            setIsShowfulls(true)
        }
    }
    function openwhiteboard(){
        setShowPopupwb(!showPopupwb);
    }
    function handlePopupqrButtonClick(){
        setShowPopupqr(!showPopupqr);
    }
    const closePopups = () => {
        setShowPopupwb(false);
    };

    function openmath(){
        setShowPopupmath(!showPopupmath);
    }
    const closePopupmath = () => {
        setShowPopupmath(false);
    };

    function timeron(){
        setShowPopupto(!showPopupto);
    }
    const closePopupto = () => {
        setShowPopupto(false);
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    useEffect(() => {
        const onClose = (event) => {
            event.preventDefault();
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            try {
                const input = {
                    id: thisid,
                    timerout: ends
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            }catch (err) {
                console.log(err)
            }
            const confirmationMessage = 'Are you sure you want to leave?';
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        }
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
        <>
            <Helmet>
                <title>In-Person HOST</title>
            </Helmet>
            {!user ?
                <Login />
                :(
                        <>
                            <div>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        {urls !== "" && (
                                            <iframe
                                                className="mviframe"
                                                id="theFrame"
                                                src={urls}
                                                loading="lazy"
                                                width="100%"
                                                height="100vh"
                                                frameBorder="0"
                                                allow="fullscreen"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} className="bottoms">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={9} className="centerside">
                                        <>
                                            {isShowfulls ?
                                                <img src={Expand} onClick={()=>{handlefullscreen();setIsShowfulls(false)}} className="mimg" alt="Full Screen"/>
                                                :
                                                <img src={Fullscreen} onClick={() => {handleminiscreen();setIsShowfulls(true)}} className="mimg" alt="Mini Screen"/>
                                            }
                                                <img src={Lists} onClick={handlePopupButtonClick} className="mimg" alt="Content List" />

                                            {showPopupqr && <Popup src={`/qrcodepage/${id}`} />}
                                                <img src={Timeron} onClick={timeron} className="mimg" />
                                            {showPopupto && (
                                                <div className="popupwb" onClick={closePopupto}>
                                                    <iframe src="/timer" title="Time On" className="thiswb"></iframe>
                                                </div>)}
                                            <img src={Reacltimewhiteboard} onClick={openwhiteboard} className="mimg" alt="Whiteboard"/>
                                            {showPopupwb && (
                                                <div className="popupwb" onClick={closePopups}>
                                                    <iframe src="/realtimewhiteboard" title="White Board" className="thiswb"></iframe>
                                                </div>)}
                                            <img src={Math} onClick={openmath} className="mimg" />
                                            {showPopupmath && (
                                                <div className="popupwb" onClick={closePopupmath}>
                                                    <iframe src="/desmos" title="Desmo" className="thiswb"></iframe>
                                                </div>)}
                                                <img src={Left} className="mimg" onClick={() => {
                                                    setCount(count - 1);
                                                    if (count === 0) {
                                                        setCount(0)
                                                    }
                                                }} alt="Left"/>

                                                <img src={Right} onClick={() => setCount(count + 1)} className="mimg" alt="Right"/>

                                        </>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="ileftside">
                                            <span className="slidescount"> {currentslide} / {totalslide} </span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Modal isOpen={showPopup} ariaHideApp={false}>
                                <div className="rightside">
                                <img src={Close} onClick={() => setShowPopup(false)} alt="Close"/>
                                </div>
                                <br/>
                                {renderSlides()}
                            </Modal>
                    </>
                )
            }
        </>
    );
}
export default Teamsmroom;

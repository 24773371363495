import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getMultioptionsdesmoslist, listLeaderboards} from '../../../../graphql/queries';
import {useParams} from "react-router-dom";
import {useForm} from 'react-hook-form';
import "../../../css/optionshowroom.css";
import "../../../css/multioptions.css";
import {createLeaderboard, updateLeaderboard, createEngagement} from "../../../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import MathJax from "react-mathjax";
import Thiscorrect from "../../../../img/view/check-mark.png";
import Thiswrong from "../../../../img/view/close.png";
import moment from "moment";


function Multidesmosshowroom() {
    const {register, handleSubmit, errors} = useForm();
    const [isshowimage, setIsshowimage] = useState(false);
    const [isShow, setIsShow] = useState(true);
    const [messages, setMessages] = useState();
    const [isShows, setIsShows] = useState(true);
    const [clist, setClist] = useState([]);
    const [playpause, setPlaypause] = useState(true);
    const [timeval, setTimeval] = useState(0);
    const {id, rid} = useParams();
    const [counter, setCounter] = useState();
    const [mmname, setMmname] = useState("")
    const [ans, setAns] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    const [thissaved, setThissaved] = useState([]);
    const [answersthis, setAnswersthis] = useState([])
    const [thisimage, setThisimage] = useState("");
    const [submittedmessage, setSubmittedmessage] = useState("");
    const [isShowcorrects, setIsShowcorrects] = useState(true);
    const [showclock, setShowclock] = useState(false);
    const [usertackingid, setUsertackingid] = useState("");
    const [thisusername, setThisusername] = useState("");
    const [usertotascore, setUsertotascore] = useState(0);
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        fetchDas();
        const submitrids = sessionStorage.getItem("submitrid");
        if (submitrids) {
            setThissaved(submitrids)
            if (submitrids.includes(rid)) {
                setDisabled(true);
                setSubmittedmessage("Submitted")
            }
        }
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        setThisusername(vreachumnewerna);
        const reachumnname = localStorage.getItem("vreachumneweremail");
        const starts = moment().format('MM/DD/YYYY hh:mm a');
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            setUsertackingid(thisid);
                            sessionStorage.setItem("edureachumthisid", thisid)
                            const thisques = plays[0].questions;
                            if(thisques) {
                                const serializedArray = JSON.stringify(thisques);
                                sessionStorage.setItem("edureachumthisques", serializedArray)
                            }
                            const totalscore = plays[0].score;
                            if(totalscore) {
                                sessionStorage.setItem("edureachumthistotalscore", totalscore);
                                setUsertotascore(totalscore);
                            }else{
                                sessionStorage.setItem("edureachumthistotalscore", 0)
                            }
                        }else{
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                score: 0,
                                thisgamescore: 0,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            setUsertackingid(thistimerid);
                            sessionStorage.setItem("edureachumthisid", thistimerid);
                            setUsertotascore(0)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getMultioptionsdesmoslist,
                variables: {id: rid}
            })
            const datas = response.data.getMultioptionsdesmoslist;
            const timers = datas.mtimer;
            if(timers !== 0) {
                setCounter(timers);
                setShowclock(true);
            }else{
                setTimeval(40)
            }
            const mmf = datas.mquestion;
            setMmname(mmf);
            sessionStorage.setItem("edureachumthisquestion", mmf)
            const mimage = datas.image;
            if (mimage !== "") {
                setThisimage(mimage);
                setIsshowimage(true)
            }
            const tests = datas.mtest;
            setClist(tests);
            const ct = datas.manswer;
            const th = ct.length;
            let cts = [];
            let tl = [];
            for (let i = 0; i < th; i++) {
                const t = ct[i].checkans;
                cts.push(t);
            }
            for (let i = 0; i < cts.length; i++) {
                const t = cts[i];
                if (t === "true") {
                    tl.push(i);
                } else {
                    console.log(i)
                }
            }
            setAns(tl);

        } catch (err) {
            console.log(err)
        }
    }

    const isArrayIncluded = (array1, array2) => {
        return array1.every(item => array2.includes(item));
    }
    const onSubmit = async (data) => {
        let tt = [];
        if (thissaved) {
            tt = [thissaved, rid]
        } else {
            tt = [rid]
        }
        sessionStorage.setItem("submitrid", tt);
        setPlaypause(false);
        const thisdata = data.panswer;
        const newArray = thisdata.map(item => parseInt(item));
        const isIncluded = isArrayIncluded(newArray, ans);
        const thisansl = ans.length;
        let countMatchingValues;
        if (isIncluded === true) {
            const rr = thisdata.length;
            const tr = timeval * rr;
            const cm = tr / thisansl;
            countMatchingValues = Math.floor(cm);
        } else {
            countMatchingValues = 0
        }

        if (countMatchingValues > 0) {
            sessionStorage.setItem("edureachumthiscorrcr", "correct")
            sessionStorage.setItem("edureachumthisscore", countMatchingValues)
            const tts = countMatchingValues + usertotascore;
            try {
                const input = {
                    id: usertackingid,
                    name:thisusername,
                    thisgamescore: countMatchingValues,
                    score: tts
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.log(err)
            }
            setMessages(`You scored ${countMatchingValues} points.`)
        } else {
            sessionStorage.setItem("edureachumthiscorrcr", "wrong")
            sessionStorage.setItem("edureachumthisscore", 0)
            try {
                const input = {
                    id: usertackingid,
                    name:thisusername,
                    thisgamescore: 0
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.log(err)
            }
            setMessages("You scored 0 points.")
        }
        setIsShows(false);
        setIsShow(false);
        try {
            const input = {
                questionid: id,
                engage: 1,
                people: 1
            }
            API.graphql({
                query: createEngagement,
                variables: {input}
            });
        } catch (err) {
            console.log(err)
        }
        const convertedToNumbers = thisdata.map(Number)
        setAnswersthis(convertedToNumbers)
    };
    const renderTime = ({remainingTime}) => {
        if (remainingTime === 0) {
            setTimeval(0)
            return <div className="timer">0</div>;
        } else {
            setTimeval(remainingTime)
        }
        return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
        );
    }

    const getdatas = async () => {
        const mymeetemals = localStorage.getItem("vreachumneweremail");
        if (mymeetemals) {
            sessionStorage.setItem("edureachumthiscorrcr", "No Answer")
            sessionStorage.setItem("edureachumthisscore", 0)
            try {
                const input = {
                    id: usertackingid,
                    name:thisusername,
                    thisgamescore: 0
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.log(err)
            }
            setMessages("You scored 0 points.")
            setIsShows(false);
            setIsShow(false);
            try {
                const input = {
                    questionid: id,
                    engage: 0,
                    people: 1
                }
                API.graphql({
                    query: createEngagement,
                    variables: {input}
                });
            } catch (err) {
                console.log(err)
            }
            setIsShowcorrects(false)
            setIsShows(false);
        } else {
            setMessages("Time is up.")
            setIsShows(false);
            setIsShow(false);
            setIsShowcorrects(false)
        }
    }
    useEffect(() => {
        let start;
        const handleStartTime = () => {
            start = new Date();
        };
        const onClose = () => {
            const thisrid = sessionStorage.getItem("edureachumthisrid");
            const thisques = sessionStorage.getItem("edureachumthisquestion");
            const thisqueslist = sessionStorage.getItem("edureachumthisques");
            const thiscorrect = sessionStorage.getItem("edureachumthiscorrcr");
            const thisscore = sessionStorage.getItem("edureachumthisscore");
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            const end = new Date();
            const timeDiff = end - start;
            const seconds = parseInt((timeDiff / 1000) % 60);
            try {
                const thisqq = {
                    rid: thisrid,
                    question: thisques,
                    correctanswer: thiscorrect,
                    thisscore: thisscore,
                    duration: seconds
                }
                let qq;
                if (thisqueslist) {
                    const objectArray = JSON.parse(thisqueslist);
                    qq = [...objectArray, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: thisid,
                    questions: qq,
                    timerout: ends
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            }catch (err) {
                console.log(err)
            }
        }
        window.addEventListener('load', handleStartTime);
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('load', handleStartTime);
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
        <>
                    <div className="greenback">
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                {showclock ?
                                    <div className="lpollheadclock2">
                                        <CountdownCircleTimer
                                            isPlaying={playpause}
                                            duration={counter}
                                            size={50}
                                            colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                                            colorsTime={[7, 5, 2, 0]}
                                            onComplete={getdatas}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                    : ""
                                }
                            </Grid>
                            <Grid item xs={10}>
                                <div className="fastanswes">
                                    <h4>{mmname}</h4>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="cccenter">
                        {isshowimage ?
                            <img src={thisimage} className="fillinthimgshow" alt="this question"/>
                            : ""
                        }
                    </div>
                    <br/>
                    <div className="drawoptionpage">
                        {isShows ?
                            <>
                                <div className="centerthismath">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        {
                                            clist.map((item, index) => {
                                                    return (
                                                        <>
                                                            <br/>
                                                            <label key={index} className="muticontainer">
                                                                <input
                                                                    type="checkbox"
                                                                    value={item.id}
                                                                    name="panswer"
                                                                    ref={register({required: 'Please select'})}
                                                                />{''}
                                                                <span className="multicheckmark"></span>

                                                                <p> {item.panswer} </p>
                                                                {item.mathstate !== null ? (
                                                                        <>
                                                                            {item.mathstate !== "" &&
                                                                            <img src={item.mathstate} className="borderthis"
                                                                                 alt="graphics"/>}
                                                                        </>
                                                                    )
                                                                    : (
                                                                        <>
                                                                            {item.mathstate !== null && (
                                                                                <img src={item.mathstate}
                                                                                     className="borderthis"
                                                                                     alt="graphics"/>
                                                                            )}
                                                                        </>
                                                                    )
                                                                }
                                                            </label>
                                                        </>
                                                    )
                                                }
                                            )
                                        }

                                        <br/>
                                        <Grid container spacing={0} className="pollbottoms">
                                            <Grid item xs={12} className="centerside">
                                                <p className="redthis"> {submittedmessage} </p>
                                                <input type="submit" className="inputpolllist" disabled={isDisabled}
                                                       value="Submit"/>
                                            </Grid>
                                        </Grid>
                                    </form>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </> :
                            <>
                                {isShowcorrects ?
                                    <div className="reachumcheckbox-container">
                                        <ul className="nonheaderul">
                                            {
                                                clist.map((item, index) => {
                                                        const convertedTo = ans.map(Number)
                                                        const shouldBeChecked = convertedTo.includes(index)
                                                        const isChecked = answersthis.includes(index)
                                                        const icon = shouldBeChecked
                                                            ? `${Thiscorrect}`
                                                            : isChecked
                                                                ? `${Thiswrong}`
                                                                : null
                                                        return (
                                                            <>
                                                                <li key={index} className="reachumcheckbox-label">
                                                                    <img className="correctandincorrect" src={icon}
                                                                         width="20px"/>
                                                                    <p className="biggertext"> {item.panswer} </p>
                                                                    {item.mathstate !== null ? (
                                                                            <>
                                                                                {item.mathstate !== "" &&
                                                                                <img src={item.mathstate} className="borderthis"
                                                                                     alt="graphics"/>}
                                                                            </>
                                                                        )
                                                                        : (
                                                                            <>
                                                                                {item.mathstate !== null && (
                                                                                    <img src={item.mathstate}
                                                                                         className="borderthis"
                                                                                         alt="graphics"/>
                                                                                )}
                                                                            </>
                                                                        )
                                                                    }
                                                                </li>
                                                            </>
                                                        )
                                                    }
                                                )
                                            }
                                        </ul>
                                    </div>
                                    : ""
                                }
                                <div className="fastanswers">
                                    <p className="redthis">{messages}</p>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </>
                        }
                    </div>
        </>
    )
}

export default Multidesmosshowroom;

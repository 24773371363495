import React, {useEffect, useState} from "react";
import { API} from 'aws-amplify';
import {listPolls, getPolllist, listLeaderboards} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import ReactCountdownClock from "react-countdown-clock";
import {Button} from "react-bootstrap";
import "../css/poll.css";
import {createLeaderboard, createPolls, updateLeaderboard} from "../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {v4 as uuid} from "uuid";
import moment from "moment";

function Pollshowroom() {
    const [isOpen, setIsOpen] = useState(true);
    const [isShow, setIsShow] = useState(false);
    const [quest, setQuest] = useState();
    const [pchecked, setPchecked] = useState();
    const [messages, setMessages] = useState();
    const [answerlength, setAnswerlength] = useState();
    const [pcheckedpeople, setPcheckedpeople] = useState();
    const [clist, setClist] = useState([]);
    const [texts, setTexts] = useState();
    const { id, rid } = useParams();
    const [counter, setCounter] = useState();
    const ppid = uuid();
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        setTimeout(()=> {
            setIsShow(true);
        },500)
    }, []);
    useEffect(() => {
        fetchDas();
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        const starts = moment().format('MM/DD/YYYY hh:mm a');
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            sessionStorage.setItem("edureachumthisid", thisid)
                            const thisques = plays[0].questions;
                            if(thisques) {
                                const serializedArray = JSON.stringify(thisques);
                                sessionStorage.setItem("edureachumthisques", serializedArray)
                            }
                        }else{
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                score: 0,
                                thisgamescore: 0,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            sessionStorage.setItem("edureachumthisid", thistimerid)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getPolllist,
                variables: {id:rid}
            })
            const datas = response.data.getPolllist;
            const timers = datas.ptimer;
            setCounter(timers)
            const ques = datas.pquestion;
            setQuest(ques);
            sessionStorage.setItem("edureachumthisquestion", ques)
            const tests = datas.ptest;
            setClist(tests)
            const pt = datas.ptest;
            const pl = pt.length;
            setAnswerlength(pl)
        }catch(err){
            console.log(err)
        }
    }
    const psubmit = async (e) => {
        try {
            const input ={
                id:ppid,
                panswer: pchecked,
                PolllistId:rid
            }
            await API.graphql({
                query: createPolls,
                variables: {input}
            });
            if(counter > 0) {
                setMessages("Group results shown on time out.")
            }
            document.getElementById("pollsubmit").setAttribute("disabled", "true");
            document.getElementById("pollsubmit").style.backgroundColor = "#E24761";
        }catch (err) {
            console.log(err)
        }
    };
    const getdatas = async () =>{
        try{
            const response = await API.graphql({
                query: listPolls,
                variables: {
                    filter: {
                        "PolllistId": {
                            eq: rid
                        }
                    },
                    limit:10000
                }
            })
            const results = response.data.listPolls.items;
            const clength = results.length;
            setPcheckedpeople(clength);
            let text = [];
            let array=[];
            let iarray;
            for (let j = 0; j < clength; j++) {
                const parray = results[j].panswer;
                array.push(parray)
            }
            for (let i = 0; i < answerlength; i++) {
                iarray = array.filter(item => item === i);
                const pcents = (((iarray.length)/clength)*100).toFixed(0) + "%";
                text.push(pcents)
            }
            const listItems = text.map((val,index) =>
                <li className="polllist1" key={index}><p className="percentmiddle">{val}</p></li>
            )
            setTexts(listItems)
            setMessages("");
            document.getElementById("pollsubmit").setAttribute("disabled", "true");
            document.getElementById("pollsubmit").style.backgroundColor = "#E24761";
            setTimeout(()=> {
                setIsOpen(false);
                document.getElementById("pollsubmit").style.display = "none";
            },600)
            setTimeout(function() {getdatas()}, 5000);
        }catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        let start;
        const handleStartTime = () => {
            start = new Date();
        };
        const onClose = () => {
            const thisrid = sessionStorage.getItem("edureachumthisrid");
            const thisques = sessionStorage.getItem("edureachumthisquestion");
            const thisqueslist = sessionStorage.getItem("edureachumthisques");
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            const end = new Date();
            const timeDiff = end - start;
            const seconds = parseInt((timeDiff / 1000) % 60);
            try {
                const thisqq = {
                    rid: thisrid,
                    question: thisques,
                    duration: seconds
                }
                let qq;
                if (thisqueslist) {
                    const objectArray = JSON.parse(thisqueslist);
                    qq = [...objectArray, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: thisid,
                    questions: qq,
                    timerout: ends
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            }catch (err) {
                console.log(err)
            }
        }
        window.addEventListener('load', handleStartTime);
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('load', handleStartTime);
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
        <>
            <div className="pollshowroom2">
                <div className="purpleback">
                    <div className="pollheader2">
                    <Grid container spacing={0}>
                        <Grid item xs={2}>
                        <div className="lpollheadclock3">
                        <ReactCountdownClock seconds={counter}
                                             color="#bca0dc"
                                             alpha={1}
                                             size={50}
                                             weight={10}
                                             onComplete={getdatas} />
                        </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="fastanswes">
                            <h2>{quest}</h2>
                            </div>
                        </Grid>
                    </Grid>
                    </div>
                </div>
                <br/>
            </div>
                <div className="pollview1">
                <>
                    {!isOpen ?
                        <>
                            <p>{pcheckedpeople} Responses</p>
                            <p className="pollnoanswers">Thank you for participating!</p>
                            <ol className="pollcontainer1">
                                {
                                    texts

                                }
                            </ol>
                        </>
                        : ""
                    }
                </>
                <div>
                    <ol className="pollcontainer">
                        {
                            clist.map((item,  index) => {
                                return (
                                    <li key={item.id} className="polllist">
                                        <input
                                            type="radio"
                                            defaultValue={item.panswer}
                                            name="panswer"
                                            onChange={()=> {
                                                setPchecked(item.id)
                                            }}
                                            id={item.id}
                                        />
                                        <label htmlFor={item.id}>{item.panswer}</label>
                                        <div className="check"></div>
                                    </li>
                                )}

                            )
                        }

                    </ol>
                    <br/>
                    {isShow ?
                        <Grid container spacing={0} className="pollbottoms">
                            <Grid item xs={7}>
                                <p className="pollmaeeage">{messages}</p>
                            </Grid>
                            <Grid item xs={5} className="rightside">
                                <Button id="pollsubmit" onClick={psubmit}
                                        className="purplebutton">Submit</Button>
                            </Grid>
                        </Grid> : ""
                    }
                </div>
                </div>
        </>
    )
}

export default Pollshowroom;

import {API, Auth} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide} from "../graphql/queries";
import { useHotkeys } from 'react-hotkeys-hook';
import {useNavigate,useParams} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import "./css/preview.css";
import Right from "../img/host/next-btn.png";
import Left from "../img/host/back-btn.png";
import Full from "../img/host/Layout.png";
import {Helmet} from "react-helmet";
import Ending from "../img/host/Exit-Button.png";
import Login from "./login";
import {updatePlayslide} from "../graphql/mutations";


function Rehearse() {
    const history = useNavigate();
    const [urls, setUrls] = useState([]);
    const [lurl, setLurl] = useState("");
    const [count, setCount] = useState(0);
    const [lsize, setLsize] = useState(3);
    const [rsize, setRsize] = useState(9);
    const [isShow, setIsShow] = useState(true);
    const [isShows, setIsShows] = useState(true);
    const [isUser, setIsUser] = useState(false);
    const [isfull, setIsfull] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const { id } = useParams();
    sessionStorage.removeItem("reachumvideosilent");
    useEffect(() => {
        (async () => {
            const input = {
                id,
                group: "noselfstudy"
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        })()
    }, [id]);
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        async function fetchData() {
            const response = await API.graphql({
                query: getPlayslide,
                variables: { id },

            });
            const datas = response.data.getPlayslide;
            const m = datas.myrlink + "&embed&logo=on&screenshare=on&leaveButton=off&background=off&recording&breakout=on&roomIntegrations=on";
            setLurl(m);
            const h = datas.playlistlinks;
            let i = h.length - 1;
            if (count <= i) {
                setUrls(h[count].rurl)
            }else {
                setCount(0)
            }
        }
        fetchData();
    }, [count,id]);
    const handleplay = async () =>{
        const theFrame = document.getElementById('theFrame');
        const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document
        theFrameDocument.getElementById('frameBtn').click();
        setIsShows(false)
    }
    const handlepause = async () =>{
        const theFrame = document.getElementById('theFrame');
        const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document
        theFrameDocument.getElementById('frameBtn').click();
        setIsShows(true)
    }

    const handlefullscreen = async () =>{
        setLsize(12);
        setRsize(12);
        setIsShow(false);
        setIsfull(true);
        hidebuttons()
    }
    const handlesmallscreen = async () =>{
        setLsize(3);
        setRsize(9);
        setIsShow(true);
        setIsfull(false);
        showbuttons()
    }
    useHotkeys('n',()=>{
        setCount(counts => counts + 1);
    })
    useHotkeys('b',()=>{
        setCount(counts => counts - 1);
    })
    useHotkeys('.', () => {
        if (isShows === false) {
            handlepause()
        }else{
            handleplay()
        }
    },{}, [isShows])

    useHotkeys('/', () => {
        if (isfull === false) {
            handlefullscreen()
        }else{
            handlesmallscreen()
        }
    },{}, [isfull])

    function ends(){
        history(`/editroom/${id}`);
    }
    function hidebuttons() {
        setIsOpen(false)
    }
    function showbuttons() {
        setIsOpen(true)
    }
    return (
        isUser ?
        <>
            <Helmet>
                <title>REHEARSE</title>
            </Helmet>
        <div>
            <Grid container spacing={0}>
                <Grid item xs={lsize}>
                    {lurl !== "" && (
                        <iframe
                            className="preiframe"
                            src={lurl}
                            width="100%"
                            height="100vh"
                            title="mainurl"
                            frameBorder="0"
                            allow="camera; microphone; fullscreen; speaker; display-capture"
                        />
                    )}
                </Grid>
                <Grid item xs={rsize}>
                    {urls !== "" && (
                        <iframe
                            className="preiframe"
                            id="theFrame"
                            src={urls}
                            width="100%"
                            height="100vh"
                            title="maincontent"
                            frameBorder="0"
                            allow="fullscreen"
                        />
                    )}
                </Grid>
            </Grid>
                <Grid container spacing={0} className="bottoms">
                    <Grid item xs={2}>
                    </Grid>
                    {isShow ?
                        <Grid item xs={4} className="rightside">
                            <div className="upgradenew3">
                                <img src={Full} onClick={handlefullscreen} className="mimg" alt="full screen"/>
                                <span className="tooltiptext3">Screen Layout (or / key)</span>
                            </div>
                        </Grid>
                        :
                        <Grid item xs={4} className="rightside">
                            <div className="upgradenew3">
                                <img src={Full} onClick={handlesmallscreen} className="mimg" alt="small screen"/>
                                <span className="tooltiptext3">Screen Layout (or / key)</span>
                            </div>
                        </Grid>
                    }

                    <Grid item xs={4} className="leftside">
                        {isOpen ?
                            <>
                                <div className="upgradenew4" id="5">
                                    <img src={Left} className="mimg" onClick={() => {
                                        setCount(count - 1);
                                        if (count === 0) {
                                            setCount(0)
                                        }
                                    }} alt="left"/>
                                    <span className="tooltiptext4">Back (or b key)</span>
                                </div>
                                <div className="upgradenew4" id="6">
                                    <img src={Right} onClick={() => setCount(count + 1)} className="mimg" alt="right" />
                                    <span className="tooltiptext4">Next (or n key)</span>
                                </div>
                            </> : ""
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <div className="endmeetingb">
                            <img src={Ending} onClick={ends} alt="end"/>
                        </div>
                    </Grid>
                </Grid>
            </div>
            </>
            :<Login />
    );
}
export default Rehearse;

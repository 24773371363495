import {API} from "aws-amplify";
import React, {useEffect, useRef, useState} from "react";
import {useParams,useNavigate} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';
import {getPlayslide, listHosts, listLeaderboards, listSelfstudytimers} from "../../graphql/queries";
import {
    createLeaderboard,
    createSelfstudytimer,
    updateLeaderboard,
    updatePlayslide,
    updateSelfstudytimer
} from "../../graphql/mutations";
import { newUpdatePlayslide } from "../../graphql/subscriptions";
import Grid from '@material-ui/core/Grid';
import "../css/room.css";
import moment from "moment";
import Ending from "../../img/host/Exit-Button.png";
import {Helmet} from "react-helmet";
import Modal from "react-bootstrap/Modal";
import {Form, Input} from "element-react";
import {Button} from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";


function EmojiPicker({ onSelect }) {
    const emojis = ["(ง︡'-'︠)ง", "≧◉ᴥ◉≦", "ʕ•́ᴥ•̀ʔっ", "✍(◔◡◔)", "(っ＾▿＾)💨", "(❛‿❛✿̶̥̥)","(^◡^ )", "(ɔ◔‿◔)ɔ ♥", "(>‿◠)✌", "( ͡❛ ͜ʖ ͡❛)",
        "(.•̃ ᴗ•̃.)","(╥﹏╥)","(ᵔ̃ ⏥ᵔ̃)","( ◡́.◡̀)",
        "(ᵔ̃ ‿‿ᵔ̃)","(ᵔ̃ ‿ᵔ̃)","( ͠👁️ ͜ʖ ͡👁️)","( ͠ᵔ ͜ʖ ͡ᵔ)","(ᵔ̃ ͜ʖᵔ̃)","（ つ︣﹏╰）",
        "( ͡❛ ‿‿ ͡❛)","( ͡° ‿‿ ͡°)","( ͡ᵔ ‿‿ ͡ᵔ)",
        "(.•̃ ⏥•̃.)","(͡° ͜ʖ ͡°)","(.👁️̃ ⏥👁️̃.)","(◑_◑)","¯\\_( ͠👁️ ͜ʖ ͡👁️)_/¯","(̶◉͛‿◉̶)","(.•̃ ͜ʖ•̃.)","¯\\_( ͠~ ͜ʖ ͡°)_/¯","(.°̃ ͜ʖ°̃.)","(. •︠ ͜ʖ ︡•.)",
        "(. •︠ ⏥ ︡•.)","( ͡ᵔ ‿‿͡ᵔ )","(ᵔ̃ ‿‿ᵔ̃)","(👁️̃ ‿‿👁️̃)","ಥ_ಥ","ᕙ(`▿´)ᕗ",
        "¯\\_(•̃ ⏥•̃)_/¯","( •︣ ▿ •︣ )","( ͡👁️ ‿‿ ͡👁️)","(ㆆ_ㆆ)","(👁️̃ ‿‿👁️̃)","( ͠ᵔ ‿‿ ͡ᵔ)✌","( ͠ᵔ ͜ʖ ͡ᵔ)✌","( ˘︹˘ )","t(>.<t)","(≖_≖ )","(͠◉_◉᷅ )","(◑́_◑᷅ )", "( ͡• ▿ ͡•)"];
    return (
        <div>
            {emojis.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function EmojiPicker1({ onSelect }) {
    const emojis1 = ["🥪", "🥙", "🥯", "🍖", "☕", "🥛", "🥚", "🍠", "🍥", "🍓",
        "🍳","🍱", "🍤","🥤","🧋", "🍷","🍹","🍸","🍻","🍺","🍦",
        "🍨","🍧","🎂","🍰","🍯","🍫","🍬","🍭","🍡","🍩","🍪", "🍘","🍮","🍌","🍉","🍊","🍍","🍎","🍏","🍑","🍋",
        "🍈","🍐","🍒","🍇","🍅","🍆","🌽","🍣","🍚","🍛","🍙","🍲","🍜","🍝","🍢","🍞","🍗","🍟","🍔","🍕","🍄"];
    return (
        <div>
            {emojis1.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function EmojiPicker2({ onSelect }) {
    const emojis2 = ["🚚", "🚔", "🚑", "🚓", "🚒", "🚕", "🚖", "🚛",
        "🚐","🚗", "🚘","🚙","🚜", "🚉","🚊","🚇","🚥","🚆","🚞","🚲","🏍","🦽","🦼","🛺","🛵","🛻","🏎","🚍","⛽",
        "🚄","🚅","🚝","🚈","🚠","🚡","🚎","🚃","🚋","🚌","✈", "🚁","⚓","🚣","⛵","🏄","🏊","🚀","🛴"];
    return (
        <div>
            {emojis2.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function EmojiPicker3({ onSelect }) {
    const emojis3 = ["⚽", "(づ˶•༝•˶)づ  🏀", "🤺","⚾","🥎","🏐","🏈","🏉","🎾","🥏","🎳","🏏","🏑","🏒","🥍","🏓","🏸","🥊","🥋",
        "🥅","⛳","⛸️","🎣","🤿","🎽","🎿","🛷","🥌"];
    return (
        <div>
            {emojis3.map((emoji) => (
                <button key={emoji} onClick={() => onSelect(emoji)} className="spanspace">
                    {emoji}
                </button>
            ))}
        </div>
    );
}
function Iroom() {
    const [curl, setCurl] = useState("");
    const { id } = useParams();
    const [isopen, setIsopen] = useState(true);
    const [yourusername, setYourusername] = useState('');
    const history = useNavigate();
    const [openemojis, setOpenemojis] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState(null);
    const [showthis, setShowthis] = useState(true);
    const ref = useRef(null);
    const [thisstart, setThisstart] = useState(new Date());
    const [usertrackingid, setUsertackingid] =useState("");
    const [durationtime, setDurationtime] = useState(0)
    const [stateData, setStateData] = useState({
        meetname:"",
        meetemail:""
    });
    const [isValid, setIsValid] = useState(true);
    const finalText = selectedEmoji ? `${yourusername} ${selectedEmoji}` : yourusername;
    const createfinalText = selectedEmoji ? `${stateData.meetname} ${selectedEmoji}` : stateData.meetname;
    sessionStorage.setItem("reachumvideosilent", "true");
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        setYourusername(vreachumnewerna)
        if(reachumnname){
            setIsopen(false);
        }
        const thisss = sessionStorage.getItem("updatedusername");
        if(thisss){
            setShowthis(false);
        }
        const starts = moment().format('MM/DD/YYYY hh:mm a');
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            setUsertackingid(thisid)
                            sessionStorage.setItem("edureachumthisid", thisid)
                        }else{
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                score: 0,
                                thisgamescore: 0,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            setUsertackingid(thistimerid)
                            sessionStorage.setItem("edureachumthisid", thistimerid)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);
    useEffect(  () => {
        fetchvlick();
        const subscription = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchData()
        })
        return()=>{
            subscription.unsubscribe()
        }
    }, []);
    async function fetchvlick() {
        try{
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            });
            const datas = response.data.getPlayslide;
            setCurl(datas.clink);
            const user = datas.owner;
            API.graphql({
                query: listHosts,
                variables: {
                    filter: {
                        "owner": {
                            eq: user
                        }
                    },
                    limit:10000
                }
            }).then(datas =>{
                const players = datas.data.listHosts.items;
                const sids = players[0].status;
                const options = {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                        ApiKey: 'click-video_6598e22a7257474591cab3ad5b4211ff'
                    }
                };
                fetch(`https://public.billsby.com/api/v1/rest/core/reachum/subscriptions/${sids}`, options)
                    .then(response => response.json())
                    .then(response => {
                            const rs = response.status;
                            if (rs === "Cancelled") {
                                history("/");
                            }
                        }
                    )
                    .catch(err => console.error(err));
            })
        }catch(error){
            console.log("Cannot display")
        }
    }
    async function fetchData() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            setCurl(datas.clink);
            const v = datas.aplaypause;
            if(v === true){
                const thetimer = document.getElementById('theFrame');
                thetimer.src =  "/timer";
            }else{
                setCurl(datas.clink);
            }
            const isplay = datas.vplaypause;
            if(isplay === true){
                const theFrame = document.getElementById('theFrame');
                const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                theFrameDocument.getElementById('reactplayer').setAttribute("playing", true);
            }else{
                const theFrame = document.getElementById('theFrame');
                const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                theFrameDocument.getElementById('reactplayer').setAttribute("playing", false);
            }
        }catch(error){
            console.log("hi")
        }
    }
    const infobuttons = () => {document.getElementById("myNavs").style.width = "100%";}
    useHotkeys('/', () => {
        infobuttons()
    })
    async function ends(){
        const ends = moment().format('MM/DD/YYYY hh:mm a');
        try {
            const input = {
                id: usertrackingid,
                timerout: ends
            }
            API.graphql({
                query: updateLeaderboard,
                variables: {input}
            });
        }catch (err) {
            console.log(err)
        }
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history(`/`);
        }
    }
    const handleSubmit2 = async event => {
        event.preventDefault();
        if(isValid === true) {
            try {
                const ssname = stateData.meetname;
                const createfinalText = selectedEmoji ? `${ssname} ${selectedEmoji}` : ssname;
                const ssemail = stateData.meetemail;
                localStorage.setItem("vreachumnewername", createfinalText);
                localStorage.setItem("vreachumneweremail", ssemail);
                setShowthis(false);
                sessionStorage.setItem("updatedusername", "1")
            } catch (err) {
                console.log(err)
            }
        }
    }
    const handleChange222 = event => {
        const ev = event.target.value;
        setYourusername(ev);
    };
    function thisprivacy(){
        window.open("https://reachum.com/terms-and-conditions/", "_blank")
    }
    function openemoji(){
        if(openemojis === false) {
            setOpenemojis(true)
        }else{
            setOpenemojis(false)
        }
    }
    useEffect(() => {
        const onClose = (event) => {
            event.preventDefault();
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            try {
                const input = {
                    id: thisid,
                    timerout: ends
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            }catch (err) {
                console.log(err)
            }
            const confirmationMessage = 'Are you sure you want to leave?';
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        }
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
        <>
            <Helmet>
                <title>MEETING</title>
            </Helmet>
                <div>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {curl !== "" && (
                                <iframe
                                    className="viframe"
                                    id="theFrame"
                                    src={curl}
                                    loading="lazy"
                                    width="100%"
                                    height="100vh"
                                    frameBorder="0"
                                    allow="autoplay;fullscreen;payment,camera; microphone;speaker; display-capture"
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className="bottoms">
                        <Grid item xs={7}>
                            <div className="whitethis">
                                <i>Screen blank, please tap.</i>
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <img src={Ending} onClick={ends} className="viewbimg" alt="exit"/>
                        </Grid>
                    </Grid>
                </div>
                <Modal
                    show={showthis}
                    centered
                >
                    <Modal.Body>
                        <div className="enteryournameform">
                            {isopen ?
                                <Form className="market-header">
                                    <Form.Item>
                                        <span> Enter Email* </span>
                                        <br/>
                                        <Input
                                            type="email"
                                            value={stateData.meetemail}
                                            onChange={meetemail => {
                                                setStateData({
                                                    ...stateData,
                                                    meetemail: meetemail
                                                });
                                                const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                                const isValidemail = emailRegex.test(meetemail);
                                                setIsValid(isValidemail);
                                            }}
                                            maxLength={100}
                                            placeholder="Your Email"
                                            className="craeteroomgame"
                                        />
                                        {!isValid && <p style={{ color: '#d2196e' }}>Please enter a valid email address.</p>}
                                    </Form.Item>
                                    <Form.Item>
                                        <span> Create Display Name:</span>
                                        <br/>
                                        <Input
                                            type="text"
                                            value={stateData.meetname}
                                            onChange={meetname => setStateData({
                                                ...stateData,
                                                meetname: meetname
                                            })}
                                            maxLength={50}
                                            placeholder="Text"
                                            className="craeteroomgame"
                                        />
                                        <br/>
                                        Display Name Preview: <br/> {createfinalText}
                                    </Form.Item>
                                    <Form.Item>
                                        <Grid container spacing={0}>
                                            <Grid item xs={6}>
                                            </Grid>
                                            <Grid item xs={6} className="rightside">
                                                <Button
                                                    disabled={!stateData.meetemail || !stateData.meetname}
                                                    onClick={handleSubmit2}
                                                    size="sm"
                                                    className="ibuttnsform"
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form.Item>
                                </Form>
                                :
                                <div className="viewright">
                                    <span>Current Display Name:</span>
                                    <br/>
                                    <input type="text" className="updatenameform" value={yourusername} name="message"
                                           onChange={handleChange222}/>
                                    <br/>
                                    <br/>
                                    <p className="thisfontsize">New Display Name: <br/> {finalText}</p>
                                    <Button className="updateformbutton" onClick={() => {
                                        localStorage.setItem("vreachumnewername", finalText);
                                        setShowthis(false);
                                        sessionStorage.setItem("updatedusername", "1")

                                    }} size="sm">Submit</Button>
                                    <Button variant="secondary" onClick={() => {
                                        setShowthis(false);
                                        sessionStorage.setItem("updatedusername", "1")
                                    }
                                    } className="updateformbutton" size="sm">Cancel</Button>
                                    <br/>
                                    <br/>
                                </div>
                            }
                                <div>
                                    <p className="thisfontsize">Click <span className="underscored" onClick={openemoji}>here to</span> emojize your Display Name </p>
                                    {openemojis ?
                                    <Tabs
                                        defaultActiveKey="Emoticons"
                                        id="fill-tab-example"
                                        className="mb-3"
                                        fill
                                    >
                                        <Tab eventKey="Emoticons" title="Emoticons">
                                            <EmojiPicker onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                        </Tab>
                                        <Tab eventKey="Food" title="Food">
                                            <EmojiPicker1 onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                        </Tab>
                                        <Tab eventKey="Car" title="car">
                                            <EmojiPicker2 onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                        </Tab>
                                        <Tab eventKey="Sport" title="Sport">
                                            <EmojiPicker3 onSelect={(emoji) => setSelectedEmoji(emoji)} />
                                        </Tab>
                                    </Tabs>
                                        : ""
                                    }
                                    <br/>
                                </div>
                            <p className="privacytext">
                                We respect your privacy. Data is stored for game and progress tracking only. REACHUM does not share information with third parties. Read our full privacy policy <span
                                className="privacytextspan" onClick={thisprivacy}>here</span>.</p>
                        </div>
                    </Modal.Body>
                </Modal>
        </>
    )
}
export default Iroom;

import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './logo.png';
import "./css/Navbar.css"

function Navbars() {
    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="https://reachum.com"><img src={logo} alt="Reachum Logo" className="logos"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav defaultActiveKey="/" className="navmenus">
                            <Nav className="me-auto">
                                <Nav.Link href="/"><p className="navthissub">Dashboard</p></Nav.Link>
                                <Nav.Link href="/tutorials"><p className="navthissub">Tutorials</p></Nav.Link>
                                <Nav.Link href="/support"><p className="navthissub">Support</p></Nav.Link>
                                <Nav.Link href="/profile"><p className="navthissub">My Account</p></Nav.Link>
                                <Nav.Link href="/pricing"><p className="redthissub">Subscribe</p></Nav.Link>
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Navbars;

import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {getPlayslide, listHosts, listLeaderboards, listSelfstudytimers} from '../../graphql/queries';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Modal, Table, Accordion} from 'react-bootstrap';
import "../css/leaderboard.css";
import {Helmet} from "react-helmet";
import Deleteroom from "../../img/dash/Delete-Button-X.png";
import {deleteLeaderboard, updateHost} from "../../graphql/mutations";
import Navbars from "../Navbar";
import Grid from "@material-ui/core/Grid";
import Leftarrow from "../../img/left-arrow.png";
import Foot from "../Foot";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Hostleaderboard from "./leaderboard/hostleaderboard";
import Overview from "./overview/overview"
import Cdleaderboard from "./leaderboard/cdleaderboard";
import Cdlessonlog from "./leaderboard/cdlessonlog";
import Morereports from "./leaderboard/morereports";
import Morereponsereports from "./responserate/morereponsereports";
import Newinfo from "../../img/info.png";
import Correctimg from "../../img/view/check-mark.png";
import Incorrectimh from "../../img/view/close.png";
import Noanswer from "../../img/view/question-mark.png";
import Contents from "../../img/view/content-management.png"
import moment from "moment";
import {v4 as uuid} from "uuid";

function Reports() {
    const [myData, setMyData] = useState([]);
    const {id} = useParams();
    const history = useNavigate();
    const [isOwner, setIsOwner] = useState("");
    const [isUser, setIsUser] = useState("");
    const [roomname, setRoomname] = useState("");
    const [thisdatas, setThisdatas] = useState([]);
    const [timetrackingr, setTimetrackingr] = useState(true);
    const [leadersr, setLeadersr] = useState(false);
    const [responser, setResponser] = useState(false);
    const [lessonlogr, setLessonlogr] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Time Tracking');
    const [openleader ,setOpenleader] = useState(false);
    const [openrespnserate, setOpenrespnserate] = useState(false);
    const [showroomname, setShowroomname] = useState(true);
    const [aggregater, setAggregater] = useState(false);
    const [show, setShow] = useState(false);
    const [lessontracking, setLessontracking] =useState(false);
    const [cionopied, setCionopied] = useState("")
    const [showcopy, setShowcopy] = useState(false);
    const [sharefull, setSharefull] = useState(true);
    const [thisshareid, setThisshareid] = useState("")
    const handleClose = () => setShow(false);
    const [thislength, setThislength] = useState(0)
    const [passemailvalue, setPassemailvalue] = useState("")
    const [passemailvalue2, setPassemailvalue2] = useState("")
    const [sharedemails, setSharedemails] = useState([]);
    const [sharedecode, setSharedecode] = useState("");
    const [isSortedAlpha, setIsSortedAlpha] = useState(false);
    const [isSortedlth, setIsSortedlth] = useState(false);
    const [isSortedti, setIsSortedti] = useState(false);
    const [isSortedto, setIsSortedto] = useState(false);
    const [isSorteddura, setIsSorteddura] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [openrearchform, setOpenrearchform] = useState(false);
    const d = uuid();
    const rechumemail = localStorage.getItem("vreachumneweremail");
    if(rechumemail){
        localStorage.removeItem("vreachumnewername");
        localStorage.removeItem("vreachumneweremail")
    }
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async () => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const myroomname = datas.meetingname;
            setRoomname(myroomname);
            const responses = await API.graphql({
                query: listHosts,
                variables: {
                    filter: {
                        "owner": {
                            eq: iowner
                        }
                    },
                    limit: 10000
                }
            })
            const datass = responses.data.listHosts.items;
            if(datass.length !== 0) {
                const shateid = datass[0].id;
                setThisshareid(shateid);
                const tt = datass[0].shareemail;
                const tcode = datass[0].sharecode;
               if(tt && tcode){
                   setSharedemails(tt);
                   setSharedecode(tcode);
                   setSharefull(false)
               }
            }
        })()
        fetchDas();
    }, []);

    async function fetchDas() {
        const scounts = [];
        const dcounts = [];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "answerid": {
                            eq: id
                        }
                    },
                    limit: 10000
                }
            })
            const datas = response.data.listLeaderboards.items;
            setThisdatas(datas);
            setThislength(datas.length)
            datas.forEach((data) => {
                const listdata = data;
                scounts.push(listdata)
            })
            scounts.sort((a, b) => {
                return b.score - a.score;
            });
            scounts.forEach((e) => {
                dcounts.push(e)
            });
            const result =  dcounts.map(item => {
                const totalDuration = item.questions
                    ? item.questions.reduce((acc, question) => acc + question.duration, 0)
                    : 0;
                return {
                    ...item,
                    dura: totalDuration,
                };
            });
            setMyData(result);
        } catch (err) {
            console.log(err)
        }
    }

    const handleDeleteperson = async (itemId) => {
        let text = "Are you sure you want to delete this player? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            const input = {
                id: itemId
            };
            await API.graphql({
                query: deleteLeaderboard,
                variables: {input}
            });
            window.location.reload();
        }
    };
    const toggleEmail = () => {
        if (isSortedAlpha) {
            const tsorted = [...myData].sort((a, b) => b.meetemail.localeCompare(a.meetemail));
            setMyData(tsorted);
        }else {
            const sorted = [...myData].sort((a, b) => a.meetemail.localeCompare(b.meetemail));
            setMyData(sorted);
        }
        setIsSortedAlpha(prevState => !prevState);
    }
    const toggleScore = () => {
        if (isSortedlth) {
            const ssorted = [...myData].sort((a, b) => b.score - a.score);
            setMyData(ssorted);
        }else {
            const soorted = [...myData].sort((a, b) => a.score - b.score);
            setMyData(soorted);
        }
        setIsSortedlth(prevState => !prevState);
    }
    const toggleDura = () => {
        if (isSorteddura) {
            const sdorted = [...myData].sort((a, b) => b.duration - a.duration);
            setMyData(sdorted);
        }else {
            const sodorted = [...myData].sort((a, b) => a.duration - b.duration);
            setMyData(sodorted);
        }
        setIsSorteddura(prevState => !prevState);
    }
    const toggleTimein = () => {
        if (isSortedti) {
            const stdorted = [...myData].slice().sort((a, b) => {
                const timeA = moment(a.timerin, 'dddd  MMMM Do YYYY h:mm:ss a');
                const timeB = moment(b.timerin, 'dddd  MMMM Do YYYY h:mm:ss a');
                return timeA - timeB;
            });
            setMyData(stdorted);
        }else {
            const sotdorted = [...myData].slice().sort((a, b) => {
                const timeA = moment(a.timerin, 'dddd  MMMM Do YYYY h:mm:ss a');
                const timeB = moment(b.timerin, 'dddd  MMMM Do YYYY h:mm:ss a');
                return timeB - timeA;
            });
            setMyData(sotdorted);
        }
        setIsSortedti(prevState => !prevState);
    }
    const toggleTimeout = () => {
        if (isSortedto) {
            const stdoorted = [...myData].slice().sort((a, b) => {
                const timeA = moment(a.timerout, 'dddd  MMMM Do YYYY h:mm:ss a');
                const timeB = moment(b.timerout, 'dddd  MMMM Do YYYY h:mm:ss a');
                return timeA - timeB;
            });
            setMyData(stdoorted);
        }else {
            const sotdoroted = [...myData].slice().sort((a, b) => {
                const timeA = moment(a.timerout, 'dddd  MMMM Do YYYY h:mm:ss a');
                const timeB = moment(b.timerout, 'dddd  MMMM Do YYYY h:mm:ss a');
                return timeB - timeA;
            });
            setMyData(sotdoroted);
        }
        setIsSortedto(prevState => !prevState);
    }
    const renderreports = () => {
        return myData.map((item, index) => (
            isOwner === isUser ?
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.meetemail}</td>
                    <td>
                        <Accordion>
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header>Activities</Accordion.Header>
                                <Accordion.Body>
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>Activity</th>
                                            <th> Correct/ Incorrect Answer</th>
                                            <th> Score</th>
                                            <th>Duration(Sec)</th>
                                        </tr></thead>
                                        <tbody>
                                        {item.questions && item.questions.map((item, index) => {
                                            const thisas = item.correctanswer;
                                            let thisanswer;
                                            if(thisas === "correct"){
                                                thisanswer = `${Correctimg}`
                                            }else if(thisas === "wrong"){
                                                thisanswer = `${Incorrectimh}`
                                            }else if(thisas === "No answer"){
                                                thisanswer = `${Noanswer}`
                                            }else{
                                                thisanswer = `${Contents}`
                                            }
                                            return(
                                                <>
                                                    <tr key={index}>
                                                        <td>{item.question}</td>
                                                        <td><img src={thisanswer} width="24px"/></td>
                                                        <td>{item.thisscore}</td>
                                                        <td>{item.duration}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </td>
                    <td>{item.score}</td>
                    <td>{item.timerin}</td>
                    <td>{item.timerout}</td>
                    <td>
                        {item.dura > 60 ? Math.round(parseInt(item.dura) / 60) : 0}
                    </td>
                    <td> Higher </td>
                    <td> Reachum </td>
                    <td>
                        <img src={Deleteroom} onClick={() => {
                            handleDeleteperson(item.id)
                        }} className="dropbtn" alt="delete a person"/></td>
                </tr> : ""
        ))
    }
    const rendertimereports = () => {
        return myData.map((item, index) => (
            isOwner === isUser ?
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.meetemail}</td>
                    <td>
                        {item.dura > 60 ? Math.round(parseInt(item.dura) / 60) : 0}
                    </td>
                    <td>{item.timerin}</td>
                    <td>{item.timerout}</td>
                    <td>
                        <img src={Deleteroom} onClick={() => {
                            handleDeleteperson(item.id)
                        }} className="dropbtn" alt="delete a person"/></td>
                </tr> : ""
        ))
    }
    function gobackthis() {
        history(`/`);
    }
    function timetfunction() {
        setTimetrackingr(true)
        setShowroomname(true)
        setLessontracking(false)
        setLeadersr(false)
        setResponser(false)
        setAggregater(false)
        setLessonlogr(false)
    }
    function lessonfunction(){
        setLessontracking(true)
        setShowroomname(true);
        setLessonlogr(true);
        setTimetrackingr(false)
        setLeadersr(false)
        setResponser(false)
        setAggregater(false)
    }
    function leaderfunction(){
        setLeadersr(true)
        setShowroomname(true)
        setTimetrackingr(false)
        setLessontracking(false)
        setResponser(false)
        setAggregater(false)
        setLessonlogr(false)
    }
    function responsefunction(){
        setShowroomname(true)
        setResponser(true)
        setTimetrackingr(false)
        setLessontracking(false)
        setLeadersr(false);
        setAggregater(false)
        setLessonlogr(false)
    }
    function aggregatefunction(){
        setAggregater(true)
        setShowroomname(false)
        setLessontracking(false)
        setResponser(false)
        setTimetrackingr(false)
        setLeadersr(false);
        setLessonlogr(false)
    }
    const handleOptionChange = (event) => {
        const cc = event.target.value;
        if(cc === "Response Rate"){;
            setOpenleader(false)
            setOpenrespnserate(true);
            setSelectedOption("Response Rate")
        }else{
            setOpenrespnserate(false);
            setOpenleader(true);
            setSelectedOption("LessonLog")
        }
    };
    async function removethis() {
        const input = {
            id: thisshareid,
            shareemail: [],
            sharecode: ""
        }
        await API.graphql({
            query: updateHost,
            variables: {input}
        });
        setSharefull(true);
        setShowcopy(false);
        setPassemailvalue("");
    }

    async function sendtodata() {
        try {
            let thisemailss;
           const thisemails = {
                email: passemailvalue
            }
           const thisemails2 = {
                email: passemailvalue2
            }
            if(passemailvalue2 !== ""){
                thisemailss = [thisemails, thisemails2]
            }else{
                thisemailss = thisemails
            }
            const input = {
                id: thisshareid,
                sharecode: d,
                shareemail: thisemailss
            }
            await API.graphql({
                query: updateHost,
                variables: {input}
            });
            setSharefull(false);
        } catch (err) {
            console.log(err)
        }
    }

    const handleChangeemail = e => {
        const tse = e.target.value;
        if (tse !== "") {
            setPassemailvalue(tse);
            setShowcopy(true)
        }
    }
    const handleChangeemail2 = e => {
        const tse2 = e.target.value;
        if (tse2 !== "") {
            setPassemailvalue2(tse2);
        }
    }
    const handleSearch = () => {
        const results = myData.filter(item =>
            item.meetemail.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setSearchResults(results);
        setOpenrearchform(true)
    };
   const rendersearchreports = ()=> {
       return searchResults.map((item, index) => (
           isOwner === isUser ?
               <tr key={index}>
                   <td>{index + 1}</td>
                   <td>{item.name}</td>
                   <td>{item.meetemail}</td>
                   <td>
                       <Accordion>
                           <Accordion.Item eventKey={index}>
                               <Accordion.Header>Activities</Accordion.Header>
                               <Accordion.Body>
                                   <Table bordered>
                                       <thead>
                                       <tr>
                                           <th>Activity</th>
                                           <th> Correct/ Incorrect Answer</th>
                                           <th> Score</th>
                                           <th>Duration(Sec)</th>
                                       </tr></thead>
                                       <tbody>
                                       {item.questions && item.questions.map((item, index) => {
                                           const thisas = item.correctanswer;
                                           let thisanswer;
                                           if(thisas === "correct"){
                                               thisanswer = `${Correctimg}`
                                           }else if(thisas === "wrong"){
                                               thisanswer = `${Incorrectimh}`
                                           }else if(thisas === "No answer"){
                                               thisanswer = `${Noanswer}`
                                           }else{
                                               thisanswer = `${Contents}`
                                           }
                                           return(
                                               <>
                                                   <tr key={index}>
                                                       <td>{item.question}</td>
                                                       <td><img src={thisanswer} width="24px"/></td>
                                                       <td>{item.thisscore}</td>
                                                       <td>{item.duration}</td>
                                                   </tr>
                                               </>
                                           )
                                       })}
                                       </tbody>
                                   </Table>
                               </Accordion.Body>
                           </Accordion.Item>
                       </Accordion>
                   </td>
                   <td>{item.score}</td>
                   <td>{item.timerin}</td>
                   <td>{item.timerout}</td>
                   <td>
                       {item.dura > 60 ? Math.round(parseInt(item.dura) / 60) : 0}
                   </td>
                   <td> Higher </td>
                   <td> Reachum </td>
                   <td>
                       <img src={Deleteroom} onClick={() => {
                           handleDeleteperson(item.id)
                       }} className="dropbtn" alt="delete a person"/></td>
               </tr> : ""
       ))
   }
    const resetdata = () => {
        setOpenrearchform(false);
        setSearchQuery("")
    }
    return (
        <>
            <Helmet>
                <title>Reports</title>
            </Helmet>
            <>
                <Navbars/>
                {isOwner === isUser ?
                    <>
                        <div className="leaderpage">
                            <div className="reportpageheadview">
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <h5 className="sharedropbtn" onClick={gobackthis}>
                                            <img src={Leftarrow}
                                                 width="13"
                                                 height="13"/>
                                            &nbsp; Lesson List
                                        </h5>
                                    </Grid>
                                    <Grid item xs={10} className="cccenter">
                                        {showroomname ?
                                            <h3>{roomname}</h3>
                                            : ""
                                        }
                                    </Grid>
                                </Grid>
                                <br/>
                                <br/>
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <div className="cccenter">
                                            <h4>Report Menu</h4>
                                        </div>
                                        <br/>
                                        <p onClick={timetfunction} className="hoverreportmenu">Overview</p>
                                        <p onClick={lessonfunction} className="hoverreportmenu">Lesson Log</p>
                                        <p onClick={leaderfunction} className="hoverreportmenu">Leaderboard</p>
                                        <p onClick={aggregatefunction} className="hoverreportmenu">Report by Date</p>
                                        <hr/>
                                        <br/>
                                        {lessonlogr ?
                                            <Cdlessonlog data={id}/>
                                            :""
                                        }
                                        {leadersr ?
                                            <Cdleaderboard data={id}/>
                                            : ""
                                        }
                                        {aggregater ?
                                            <>
                                                {sharefull ?
                                                    <p className="hoverreportmenu" onClick={() => setShow(true)}>Share
                                                        Reports</p>
                                                    :
                                                    <p className="grays">Share Reports</p>
                                                }
                                                {!sharefull ?
                                                    <>
                                                        <ul className="leftside">
                                                            {sharedemails.map((item, index) => (
                                                                <li key={index}>
                                                                    {item.email}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        <CopyToClipboard
                                                            text={`https://edu.reachum.com/shareReports?p=${sharedecode}`}
                                                            onCopy={() => {
                                                                setCionopied(true);
                                                                setTimeout(() => {
                                                                    setCionopied(false);
                                                                }, 5000);
                                                            }}>
                                                            <p>Copy Share Link</p>
                                                        </CopyToClipboard>
                                                        {cionopied ? <span style={{color: 'red'}}>Copied</span> : null}
                                                        <br/>
                                                        <br/>
                                                        <p onClick={removethis}>Stop Sharing</p>
                                                    </>
                                                    : ""
                                                }
                                            </>
                                            : ""
                                        }
                                    </Grid>
                                    <Grid item xs={10} className="cccenter">
                                        <div className="dashvl"></div>
                                        {lessontracking ?
                                            <div className="cccenter">
                                                <h4> Lesson Log</h4>
                                                <br/>
                                                    <div className="leftlessonlogform">
                                                        &nbsp; &nbsp;
                                                        <input
                                                            type="email"
                                                            placeholder="Search by email"
                                                            value={searchQuery}
                                                            onChange={e => setSearchQuery(e.target.value)}
                                                            className="searchlessonlogform"
                                                        />
                                                        {!openrearchform ?
                                                            <button onClick={handleSearch} className="searchbutton">Search</button>
                                                            :
                                                            <button onClick={resetdata} className="searchbutton">Clear</button>
                                                        }
                                                    </div>
                                                    <div className="rightsidethis">
                                                    <h5> Participants: {thislength} &nbsp; &nbsp;</h5>
                                                    </div>
                                                <Table bordered hover size="sm">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th> Name</th>
                                                        <th><span onClick={toggleEmail} className="togglemenu">{isSortedAlpha ? "Email" : "Email"}</span></th>
                                                        <th> Activity Log</th>
                                                        <th><span onClick={toggleScore} className="togglemenu">{isSortedlth ? "Score" : "Score"}</span></th>
                                                        <th><span onClick={toggleTimein} className="togglemenu">{isSortedti ? "Time In" : "Time In"}</span></th>
                                                        <th><span onClick={toggleTimeout} className="togglemenu">{isSortedto ? "Time Out" : "Time Out"}</span></th>
                                                        <th><span onClick={toggleDura} className="togglemenu"> {isSorteddura ? "Duration (min)" : "Duration (min)"}</span></th>
                                                        <th> Education Level</th>
                                                        <th> Source</th>
                                                        <th> Delete</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {openrearchform ?
                                                        <>
                                                            {rendersearchreports()}
                                                        </> :
                                                        <>
                                                        {renderreports()}
                                                        </>
                                                    }
                                                    </tbody>
                                                </Table>
                                            </div>
                                            : ""
                                        }
                                        {timetrackingr ?
                                            <div className="cccenter">
                                                <h4> Overview </h4>
                                                <br/>
                                                <Overview data={id}/>
                                            </div>
                                            : ""
                                        }
                                        {leadersr ?
                                            <div className="cccenter">
                                                <h4> Leaderboard
                                                    <div className="upgradenew2">
                                                        <img src={Newinfo} alt="info"/>
                                                        <span className="tooltiptext2">
View a list of Leaderboard scores for all participants in a Lesson. This data can then be exported as a CSV file if desired.
                                                        </span>
                                                    </div></h4>
                                                <br/>
                                                <Hostleaderboard data={id}/>
                                            </div>
                                            : ""
                                        }
                                        {aggregater ?
                                            <div className="cccenter">
                                                <h5>Choose a report type:</h5>
                                                <br/>
                                                <Grid container spacing={0} className="topspace1">
                                                    <Grid item xs={6} className="cccenter">
                                                        <input
                                                            type="radio"
                                                            name="options"
                                                            value="Leaderboard"
                                                            checked={selectedOption === 'LessonLog'}
                                                            onChange={handleOptionChange}
                                                            className="newinviteradio"
                                                        />
                                                        <label className="newinviteradiolabel">
                                                            Lesson Log
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={6} className="cccenter">
                                                        <input
                                                            type="radio"
                                                            name="options"
                                                            value="Response Rate"
                                                            checked={selectedOption === "Response Rate"}
                                                            onChange={handleOptionChange}
                                                            className="newinviteradio"
                                                        />
                                                        <label className="newinviteradiolabel">
                                                            Response Rate
                                                        </label>
                                                    </Grid>
                                                </Grid>
                                                <hr/>
                                                <br/>
                                                {openleader ?
                                                    <Morereports data={id}/>
                                                    : ""
                                                }
                                                {openrespnserate ?
                                                    <Morereponsereports data={id}/>
                                                    : ""
                                                }
                                            </div>
                                            : ""
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                               size="lg" centered>
                            <Modal.Header closeButton>
                                <span>Share Reports</span>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="cccenter">
                                    <h4> Send your reports to the administrator?</h4>
                                    <p>Enter the email of the recipient <br/> then click 'Copy
                                        Link' to share. </p>
                                    <input type="email" placeholder="Enter Email " value={passemailvalue}
                                           onChange={handleChangeemail} maxLength="60" required={true} className="sendreportform"/>
                                    <br/>
                                    <br/>
                                    <input type="email" placeholder="Enter Email (option) " value={passemailvalue2}
                                           onChange={handleChangeemail2} maxLength="60" className="sendreportform"/>
                                    <br/>
                                    <br/>
                                    {showcopy ?
                                        <div>
                                            <br/>
                                            <span>The code will expire when you click 'Stop Sharing'.</span>
                                            <br/>
                                            <br/>
                                            <CopyToClipboard
                                                text={`https://edu.reachum.com/shareReports?p=${d}`}
                                                onCopy={() => {
                                                    setCionopied(true);
                                                    setTimeout(() => {
                                                        setCionopied(false);
                                                    }, 5000);
                                                }}>
                                                <Button onClick={sendtodata} className="copys">Copy Link</Button>
                                            </CopyToClipboard>
                                            <br/>
                                            {cionopied ? <span style={{color: 'red'}}>Copied</span> : null}
                                        </div>
                                        : ""
                                    }
                                </div>
                                <br/>
                                <br/>
                            </Modal.Body>
                        </Modal>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </> : ""
                }
                <Foot/>
            </>
        </>
    )
}

export default Reports;

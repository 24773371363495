import React, {useEffect,useState} from "react";
import { API} from 'aws-amplify';
import {getMultioptionsdesmoslist, listLeaderboards} from "../../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../../css/correctanswerpage.css";
import moment from "moment";
import {createLeaderboard, updateLeaderboard} from "../../../../graphql/mutations";

function Multidesmoscorrctanswer() {
    const [ans, setAns] = useState("");
    const [cans, setCans] = useState([]);
    const [thisimage, setThisimage] = useState("");
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        const starts = moment().format('MM/DD/YYYY hh:mm a');
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            sessionStorage.setItem("edureachumthisid", thisid)
                            const thisques = plays[0].questions;
                            if(thisques) {
                                const serializedArray = JSON.stringify(thisques);
                                sessionStorage.setItem("edureachumthisques", serializedArray)
                            }
                        }else{
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            sessionStorage.setItem("edureachumthisid", thistimerid)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);
    async function fetchDatas() {
        const response = await API.graphql({
            query: getMultioptionsdesmoslist,
            variables:{id:rid}
        })
        const datas = response.data.getMultioptionsdesmoslist;
        const aa = datas.mquestion;
        sessionStorage.setItem("edureachumthisquestion", aa)
        setAns(aa)
        const tests = datas.mtest;
        const ct = datas.manswer;
        const mimage = datas.image;
        if(mimage !== "") {
            setThisimage(mimage);
        }
        const mergedArray = [];

        for (let i = 0; i < Math.max(tests.length, ct.length); i++) {
            const obj1 = tests[i] || {};
            const obj2 = ct[i] || {};

            const mergedObj = { ...obj1, ...obj2 };
            mergedArray.push(mergedObj);
        }
        const filteredArray = mergedArray.filter(item => item.checkans === 'true');
        setCans(filteredArray);
    }

    const displayedItems = cans.map(item => (
        <div key={item.id}>
            <span> {item.panswer} </span>
            {item.mathstate !== null && (
                <img src={item.mathstate} className="borderthis" alt="graphics" />
            )
            }
            <br/>
            <br/>
        </div>
    ));
    useEffect(() => {
        let start;
        const handleStartTime = () => {
            start = new Date();
        };
        const onClose = () => {
            const thisrid = sessionStorage.getItem("edureachumthisrid");
            const thisqueslist = sessionStorage.getItem("edureachumthisques");
            const thisques = sessionStorage.getItem("edureachumthisquestion");
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            const end = new Date();
            const timeDiff = end - start;
            const seconds = parseInt((timeDiff / 1000) % 60);
            try {
                const thisqq = {
                    rid: thisrid,
                    question: thisques,
                    duration: seconds
                }
                let qq;
                if (thisqueslist) {
                    const objectArray = JSON.parse(thisqueslist);
                    qq = [...objectArray, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: thisid,
                    questions: qq,
                    timerout: ends
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            }catch (err) {
                console.log(err)
            }
        }
        window.addEventListener('load', handleStartTime);
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('load', handleStartTime);
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
            <div className="correctanswerpage">
            <div className="corrctcentermath">
                <br/>
                <h2>{ans}</h2>
                <img src={thisimage} className="fillinthimgshow" />
                <br/>
                <br/>
                <p>The correct answer is: </p>
                <div className="redthis">
                    {displayedItems}
                </div>
                <br/>
                <br/>
                <br/>
            </div>
            </div>
    );
}

export default Multidesmoscorrctanswer;

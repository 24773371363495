import React, {useEffect, useState, useRef} from 'react'
import {API, Auth} from "aws-amplify";
import {getPlayslide, getSequencingdesmos} from "../../../../graphql/queries";
import { v4 as uuid } from 'uuid';
import {useParams} from "react-router-dom";
import {updateSequencingdesmos} from "../../../../graphql/mutations";
import {Button} from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from '../../../../img/dash/Delete-Button-X.png';
import Moveup from "../../../../img/reorder/scroll-up.png";
import Movedown from "../../../../img/reorder/scroll-down.png";
import DesmosExporter from "./DesmosExporter";
import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
          file,
          400,
          400,
          "JPEG",
          95,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
      );
    });

export default function Desmossequencingedit() {
  const resizersRef = useRef([])
  const { id,rid } = useParams();
  const [isUser, setIsUser] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const ref = useRef(null);
  const [dname, setDname] = useState('')
  const [dtimer, setDtimer] = useState(0)
  const [dquestion, setDquestion] = useState('')
  const [message, setMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState("");
  const [showtimerform, setShowtimerform] = useState(false)
  useEffect( () => {
    Auth.currentAuthenticatedUser()
        .then(user => (setIsUser(user.attributes.email)))
        .catch(err => console.log(err));
    (async() => {
      const response = await API.graphql({
        query: getPlayslide,
        variables:{id}
      })
      const datas = response.data.getPlayslide;
      const iowner = datas.owner;
      setIsOwner(iowner);
    })()
  }, []);
  useEffect( () => {
    (async() => {
      const response = await API.graphql({
        query: getSequencingdesmos,
        variables:{id:rid}
      })
      const datas = response.data.getSequencingdesmos;
      const dns = datas.dname;
      setDname(dns)
      const t = datas.dtimer
      if(t !== 0) {
        setDtimer(t)
        setShowtimerform(true)
      }
      const q = datas.dquestion
      setDquestion(q);
      const mimage = datas.image;
      if(mimage !== null){
        setSelectedFile(mimage)
      }
    })()
  }, []);
  const [items, setItems] = useState(() => {
    const stored = localStorage.getItem('desmomathreorder')
    if (stored == null) {
      return [
        { id: uuid(), text: '' }
      ]
    }
    const parsed = JSON.parse(stored)
    return parsed
  })
  function changedtimer(event){
    setDtimer(event.target.value)
  }
  function changedquestion(event){
    setDquestion(event.target.value)
  }
  const handleFileChange = async (event) => {
    const img = event.target.files[0];
    const resized = await resizeFile(img);
    setSelectedFile(resized)
  };
  const [resizers, setResizers] = useState({});
  useEffect(() => {
    const elt = document.getElementById('calculator');
    const calculator = window.Desmos.GraphingCalculator(elt);
    calculator.observeEvent('change', function(eventName, event) {
      calculator.screenshot();
      calculator.getState();
      calculator.asyncScreenshot(
          {
            mode: 'preserveX',
            width: 800,
            height: 400
          },
          (data) => console.log(data)
      );
    })
  }, []);
  function move(items, item, afterIndex, targetIndex) {
    const currentIndex = items.findIndex(currentItem => currentItem.id === item.id)
    if (currentIndex === targetIndex) {
      return items
    }
    const before = items.slice(0, afterIndex)
    const beforeFiltered = before.filter(beforeItem => beforeItem.id !== item.id)
    const after = items.slice(afterIndex)
    const afterFiltered = after.filter(afterItem => afterItem.id !== item.id)
    const newItems = [...beforeFiltered, item, ...afterFiltered]
    return newItems
  }
  const itemViews = items.map((item, itemIndex) => {
    function handleDelete () {
      let text = "Are you sure you want to delete this item? \nEither OK or Cancel.";
      if (window.confirm(text) === true) {
        setItems(currentItems => {
          const newItems = currentItems.filter(currentItem => {
            return currentItem.id !== item.id
          })
          return newItems
        })
      }
    }

    function handleMoveUp() {
      setItems(currentItems => {
        const newIndex = Math.max(0, itemIndex - 1)
        return move(currentItems, item, newIndex, newIndex)
      })
    }
    function handleMoveDown() {
      setItems(currentItems => {
        const newIndex = Math.min(currentItems.length, itemIndex + 2)
        return move(currentItems, item, newIndex, newIndex -1)
      })
    }
    function handleItemChange(key, value) {
      setItems(currentItems => {
        const newItems = currentItems.map(currentItem => {
          const match = currentItem.id === item.id
          if (match) {
            return { ...currentItem, [key]: value }
          }
          return currentItem
        })
        return newItems
      })
    }
    function handleItemTextChange(event) {
      handleItemChange('text', event.target.value)
    }
    function initializeResizer (resizer, ref) {
      setResizers(current => {
        return {
          ...current, [item.id]: { id: item.id, resizer, ref }
        }
      })
    }
    const resizer = resizers[item.id];
    function setDesmosState(itemId, newState) {
      setResizers(current => {
        const currentResizer = current[itemId]
        const newResizer = { ...currentResizer, state: newState }
        return {
          ...current,
          [itemId]: newResizer
        }
      })
    }
    return (
        <li key={itemIndex}>
          <Grid container spacing={0} className="topspace">
            <Grid item xs={8} className="cccenter">
              <input value={item.text} onChange={handleItemTextChange} placeholder='Item text'  className="sefullwidth"/>
            </Grid>
            <Grid item xs={1} className="centerthis">
              <img src={Moveup} onClick={handleMoveUp} width="30px" />
            </Grid>
            <Grid item xs={1}>
              <img src={Movedown} onClick={handleMoveDown} width="30px" />
            </Grid>
            <Grid item xs={1} className="cccenter">
            </Grid>
            <Grid item xs={1}>
              <img src={DeleteIcon} onClick={handleDelete} width="30px"/>
            </Grid>
          </Grid>

          <DesmosExporter
              initialize={initializeResizer}
              desmosState={resizer?.state}
              setDesmosState={setDesmosState}
              itemId={item.id}
          />
          <br/>
        </li>
    )
  })

  function handleAdd() {
    setItems(currentItems => {
      const newItem = {
        id: uuid(),
        text: ''
      }
      return [...currentItems, newItem]
    })
  }
  async function handleSave() {
    const itemdata = []
    for (const item of items) {
      const resizer = resizers[item.id]
      const image = await resizer.resizer()
      itemdata.push({...item, mathstate: image })
    }
    try {
      const input = {
        id: rid,
        dtimer: dtimer,
        dquestion: dquestion,
        orders:itemdata,
        image:selectedFile
      }
      await API.graphql({
        query: updateSequencingdesmos,
        variables: {input}
      });
      setMessage("Updated")
    } catch (err) {
      console.log(err)
    }
  }
  return (
      isOwner === isUser ?
          <>
            <div className="dragdroppage">
              <br/>
    <div style={{  width: '100%' }}>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
        Game Name: &nbsp;
        </Grid>
        <Grid item xs={10}>
          {dname}
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
          Timer : &nbsp;
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={0}>
            <Grid item xs={7} className="cccenter">
              <input type="radio" name="timer" value="AddTimer" onChange={()=>{setShowtimerform(true)}}/> Add Timer (Seconds) &nbsp;
              {showtimerform ?
                  <input
                      value={dtimer}
                      className="dragdroptimer"
                      type="number"
                      maxLength="3"
                      onChange={(e)=>setDtimer(e.target.value)}
                  />
                  : ""
              }
            </Grid>
            <Grid item xs={5} className="cccenter">
              <input type="radio" name="timer" value="NoTimer" onChange={()=>{
                setShowtimerform(false);
                setDtimer(0)
              }} /> No Timer
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
       Question: &nbsp;
        </Grid>
        <Grid item xs={10}>
    <textarea type="text" value={dquestion} onChange={changedquestion} className="dragdropques" maxLength="150" />
        </Grid>
      </Grid>
      <br/>
      <div>
        <br/>
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <p>Upload image:(option) </p>
          </Grid>
          <Grid item xs={10} className="leftside">
            <input type="file" onChange={handleFileChange}/>
          </Grid>
        </Grid>
        <div className="mathcccenter">
          <img src={selectedFile} className="pximages400"/>
        </div>
        <br/>
        <br/>
        <h5>Put items in the correct sequence.</h5>
        <br/>
        <ol>{itemViews}</ol>
        <Button onClick={handleAdd} size="sm" variant="secondary">Add item</Button>
      </div>
      <br/>
      <hr/>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={7}>
        </Grid>
        <Grid item xs={4} className="rightside">
      <span className="redthis"> {message} &nbsp;</span><button onClick={handleSave} className="answersubmit">Update</button>
        </Grid>
        <Grid item xs={1}>
        </Grid>
      </Grid>
    </div>
            </div>
            <br/>
            <br/>
            <br/>
            </>
          :""
  )
}

import React, {useState, useEffect} from 'react'
import Showitemlist from './showitemlist'
import {API} from "aws-amplify";
import {getSequencingdesmos, listLeaderboards} from "../../../../graphql/queries";
import {Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../../css/taptap.css";
import moment from "moment";
import {createLeaderboard} from "../../../../graphql/mutations";

export default function Showdesmossequencing() {
  const [isgameopen, setIsgameopen] = useState(false)
  const [quest, setQuest] = useState("");
  const { id, rid } = useParams();
  const [thiscate, setThiscate] = useState([]);
  const [counter, setCounter] = useState();
  const [thisimage, setThisimage] = useState("");
  const starts = moment().format('MM/DD/YYYY hh:mm a');
  sessionStorage.setItem("edureachumthisstartstimer", starts)
  const start = new Date();
  sessionStorage.setItem("edureachumthisstarttimer", start.toString())
  useEffect(() => {
    fetchDas();
  }, []);
  useEffect(() => {
    const vreachumnewerna = localStorage.getItem("vreachumnewername");
    const reachumnname = localStorage.getItem("vreachumneweremail");
    (async () => {
      if (reachumnname) {
        try {
          await API.graphql({
            query: listLeaderboards,
            variables: {
              filter: {
                "meetemail": {
                  eq: reachumnname
                },
                "answerid": {
                  eq: id
                }
              },
              limit: 10000
            }
          }).then(datas => {
            const plays = datas.data.listLeaderboards.items;
            const playss = plays.length;
            if (playss > 0) {
              const thisid = plays[0].id;
              sessionStorage.setItem("edureachumthisid", thisid)
              const thisques = plays[0].questions;
              if(thisques) {
                const serializedArray = JSON.stringify(thisques);
                sessionStorage.setItem("edureachumthisques", serializedArray)
              }
              const totalscore = plays[0].score;
              if(totalscore) {
                sessionStorage.setItem("edureachumthistotalscore", totalscore);
              }else{
                sessionStorage.setItem("edureachumthistotalscore", 0)
              }
              sessionStorage.setItem("edureachumthisuser", vreachumnewerna)
            }else{
              const input = {
                name: vreachumnewerna,
                meetemail: reachumnname,
                answerid: id,
                score: 0,
                thisgamescore: 0,
                timerin:starts
              }
              const responsee = API.graphql({
                query: createLeaderboard,
                variables: {input}
              });
              const thistimerid = responsee.id;
              sessionStorage.setItem("edureachumthisid", thistimerid);
              sessionStorage.setItem("edureachumthistotalscore", 0)
            }
          })
        } catch (err) {
          console.log(err)
        }
      }
    })()
  }, []);
  async function fetchDas() {
    try {
      const response = await API.graphql({
        query: getSequencingdesmos,
        variables: {id:rid}
      })
      const datas = response.data.getSequencingdesmos;
      const timers = datas.dtimer;
      setCounter(timers)
      const ques = datas.dquestion;
      setQuest(ques);
      sessionStorage.setItem("edureachumthisquestion", ques)
      const caes = datas.orders;
      setThiscate(caes);
      const mimage = datas.image;
      if(mimage !== null) {
        setThisimage(mimage)
      }
    }catch(err){
      console.log(err)
    }
  }
  function savethisdata(){
    sessionStorage.setItem("desmosequencinggamesarray",JSON.stringify(thiscate));
    sessionStorage.setItem("desmosequencinggamescounter", counter);
    sessionStorage.setItem("desmosequencinggamesques", quest);
    sessionStorage.setItem("desmosequencinggamesqueimage", thisimage);
    setIsgameopen(true);
  }

  return (
      <div>
              {isgameopen ?
                  <>
                 <Showitemlist />
                  </>
                  :
                  <div className="centerthis">
                    <br/>
                    <br/>
                    <h2> Sequencing </h2>
                    <br/>
                    <p className="biggertext">Tap to select an item,</p>
                      <p className="biggertext">then tap again to place the item
                      in the proper slot in the sequence.</p>

                      <p className="biggertext">Tapping an occupied slot replaces the
                      earlier selection.</p>

                     <p className="biggertext"> To clear all slots, click the “Reset” button.
                    </p>
                    <br/>
                    <Button onClick={savethisdata}>CLICK TO START</Button>
                  </div>
              }
      </div>

  )
}

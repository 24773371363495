import React, {useEffect, useRef, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePlayslide} from '../../graphql/mutations';
import {getPlayslide} from "../../graphql/queries";
import { v4 as uuid } from 'uuid';
import "../css/poll.css";
import  Draws  from "./draw";
import Grid from "@material-ui/core/Grid";


function Blackboard({id, handleClose51}) {
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const [imgdata, setImgdata] = useState("");
	const [smessage, setSmessage] = useState("")
	const [opensmessage, setOpensmessage] = useState(true)
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, []);
	const handleChildData = (data) => {
		setImgdata(data)
	};
	const saveData = async () => {
		const riurl = `https://edu.reachum.com/blackboardshowroom/${id}/${d}`
		const eiurl = `https://edu.reachum.com/blackboardedit/${id}/${d}`
			try {
				const urldata = {
					rid:d,
					title: "whiteboard",
					lurl:imgdata,
					eurl:eiurl,
					rurl: riurl
				}
				const allImages = [...images, urldata]
				const input = {
					id,
					playlistlinks: allImages
				}
				await API.graphql({
					query: updatePlayslide,
					variables: {input}
				});
				setOpensmessage(false);
				setSmessage("Submitted");
				setTimeout(() => {
					handleClose51()
				}, 1000)
			} catch (err) {
				console.log(err)
			}
	}

	return (
		<>
			{isOwner === isUser ?
				<>
				<div className="orderlist">
					<div className="thiscenter">
						<h3>Draw</h3>
						<br/>
					<Draws onChildData={handleChildData} />
					<br/>
						<Grid container spacing={0} className="topspace1">
							<Grid item xs={7}>
							</Grid>
							<Grid item xs={5} className="centerthis">
								{opensmessage ?
									<button className="answersubmit" onClick={saveData}>Submit</button>
									:
										<p className="redthis"> {smessage} </p>
								}
							</Grid>
						</Grid>
					</div>
				</div>
				</>
				:""
			}
			</>
	);
}
export default Blackboard;

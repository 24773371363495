import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePolllist, deletePolls} from '../../graphql/mutations';
import {Form,Table} from "react-bootstrap";
import {getPlayslide, getPolllist, listPolls} from "../../graphql/queries";
import {useParams} from "react-router-dom";
import "../css/poll.css";
import DeleteIcon from "../../img/dash/Delete-Button-X.png";
import Add from "../../img/dash/Add-Button.png";

function Pollsedit() {
	const { id,rid } = useParams();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [dname, setDname] = useState('')
	const [polltimer, setPolltimer] = useState('')
	const [dquestion, setDquestion] = useState('')
	const [clearmessage, setClearmessage] = useState('')
	const [items1, setItems1] = useState([{ panswer: '' }])
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
		})()
	}, []);
	useEffect( () => {
		(async() => {
			const response = await API.graphql({
				query: getPolllist,
				variables:{id:rid}
			})
			const datas = response.data.getPolllist;
			console.log("datas", datas)
			const dn = datas.pfname
			setDname(dn)
			const t = datas.ptimer
			setPolltimer(t)
			const q = datas.pquestion
			setDquestion(q)
			const i1 = datas.ptest
			setItems1(i1)
		})()
	}, []);

	function changedtimer(event){
		setPolltimer(event.target.value)
	}
	function changedquestion(event){
		setDquestion(event.target.value)
	}
	function addItem1 () {
		setItems1(state => [...state, ''])
	}

	const items1View = items1.map((item, index) => {
		function deleteItem () {
			setItems1(state => {
				return state.filter((item, stateIndex) => index !== stateIndex)
			})
		}
		function changeItem (event) {
			event.persist()
			setItems1(state => {
				return state.map((item, stateIndex) => {
					const match = index === stateIndex
					if (match) {
						const newItem = { ...item, panswer: event.target.value, id: stateIndex }
						return newItem
					}
					return item
				})
			})
		}
		return (
			<li key={index} className="listdistanse">
				<input value={item.panswer} onChange={changeItem} placeholder="" className="optionsullwidth"/>
				<img src={DeleteIcon} onClick={deleteItem} className="dragdropdele" />
			</li>
		)
	})

	async function submitItems (event) {
		event.preventDefault()
		try {
			const input = {
				id: rid,
				pfname:dname,
				ptimer: polltimer,
				pquestion: dquestion,
				ptest:items1
			}
			console.log("input", input)
			await API.graphql({
				query: updatePolllist,
				variables: {input}
			});
			setUploadedmessage("Updated!")

		} catch (err) {
			console.log(err)
		}
	}
	async function deleteresults(){
		try {
			const response = await API.graphql({
				query: listPolls,
				variables: {
					filter: {
						"PolllistId": {
							eq: rid
						}
					},
					limit:10000
				}
			})
			const datas = response.data.listPolls.items;
			const datalength = datas.length;
			for(var j=0; j<datalength; j++) {
				const input = {
					id: datas[j].id
				};
				await API.graphql({
					query: deletePolls,
					variables: {input}
				});
			}
			setClearmessage("Cleared")
		}catch(err){
			console.log(err)
		}
	}
	return (
		isOwner === isUser ?
		<div className="orderlist">
			<button onClick={deleteresults} className="clearresub">Clear Results</button> <span className="redthis">{clearmessage}</span>
			<br/>
			<form onSubmit={submitItems}>
				<Form.Group className="mb-3" controlId="formBasicEmail">
					<Form.Label>Poll Name: {dname}</Form.Label>
				</Form.Group>
				<Form.Group className="mb-3" >
					<Form.Label>Update Timer (Seconds)</Form.Label>
					<br/>
					<input
						value={polltimer}
						type="number"
						onChange={changedtimer}
						className="dragdroptimer"
					/>
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label>Update Question</Form.Label>
					<br/>
					<textarea type="text" value={dquestion} onChange={changedquestion} className="thiseditque" />
				</Form.Group>
				<Form.Group className="mb-3" >
					<Form.Label>Update Answers</Form.Label>
							<ol>
								{items1View}
							</ol>
							<div>
								<img src={Add} onClick={addItem1} />
							</div>
				</Form.Group>
				<hr/>
				<br/>
				<Table>
					<tr>
						<td>
						</td>
						<td className="orderright">
							{uploadedmessage} <input type="submit" value="Update" id="summitb" />
						</td>
					</tr>
				</Table>
			</form>
		</div>
			:""
	);
}
export default Pollsedit;

import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "./css/menus.css";
import {Modal} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Info from "../img/dash/pop-up-icon.svg";
import Textslidedis from "../img/menu/dis/1-text-slide.png";
import Imagedis from "../img/menu/dis/2-image.png";
import Videodis from "../img/menu/dis/3-video.png";
import Audiodis from "../img/menu/dis/audio.jpg";
import Pptxdis from "../img/menu/dis/4-pptx.png";
import Pdfdis from "../img/menu/dis/5-pdf.png";
import Gifdis from "../img/menu/dis/6-gif.png";
import Videolinkdis from "../img/menu/dis/8-video-link.png";
import Drawdis from "../img/menu/dis/9-draw.png";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Recordvideo1dis from "../img/menu/dis/10-1-record-video.png";
import Recordvideo2dis from "../img/menu/dis/10-2-record-video.png";
import Giphydis from "../img/menu/dis/gif.gif";
import Pollsdis from "../img/menu/dis/Polls.gif";
import Openended1dis from "../img/menu/dis/eazy-ask-response-submission.gif";
import Openended2dis from "../img/menu/dis/eazyask-highlight-a-response.gif";
import Openended3dis from "../img/menu/dis/eazyask-viewer-experience.gif";
import Singleselectdis from "../img/menu/dis/Single-Select.gif";
import Multiselectdis from "../img/menu/dis/Multi-Select.gif";
import Fillindis from "../img/menu/dis/Fill-in-the-Blank.gif";
import Drawquizedis from "../img/menu/dis/Draw-Quiz.gif";
import Videoquiz1dis from "../img/menu/dis/Video-Quiz.gif";
import Mmgdis from "../img/menu/dis/Memory-Matching-Game.gif";
import Taptapdis from "../img/menu/dis/Tap-Tap.gif";
import Sequencingdis from "../img/menu/dis/sequrncing.gif";
import Rankingpoll from "../img/menu/dis/Ranking-Poll.gif";
import Adobeoverview from "../img/menu/dis/overview-express.jpg";
import Flashcardsgif from "../img/menu/dis/flashcards.gif";
import Mathslidegif from "../img/menu/dis/math-slide.gif";
import Mathfillgif from "../img/menu/dis/math-fill-in-the-blank.gif";
import Mathmultigif from "../img/menu/dis/math-multi-select.gif";
import Mathsequgif from "../img/menu/dis/math-sequencing.gif";
import Wordcloudoverview from "../img/menu/dis/word-cloud.gif";
import Answer from "../components/answers/answer";
import Blackboard from "../components/blackboard/blackboard";
import Createflipcards from "../components/flashcards/createflipcards";
import Giphy from "../components/gif/giphy";
import Drawcreateoptions from "../components/leaderboardgames/drawoptions/drawcreateoptions";
import Fastanswer from "../components/leaderboardgames/fastanswer/fastanswer";
import Flipcards from "../components/leaderboardgames/flipcard/flipcards";
import Mathfastanswer from "../components/leaderboardgames/mathgames/fastanswer/mathfastanswer";
import Createdesmosmultioptions
    from "../components/leaderboardgames/mathgames/multipleoptions/createdesmosmultioptions";
import Createdesmossequencing from"../components/leaderboardgames/mathgames/sequencing/createdesmossequencing";
import Createmultioptions from "../components/leaderboardgames/multipleoptions/createmultioptions";
import Createoptions from "../components/leaderboardgames/options/createoptions";
import Createsequencing from "../components/leaderboardgames/sequencing/createsequencing";
import Categorydragdrop from "../components/leaderboardgames/taptap/categorydragdrop";
import Videoquizfile from "../components/leaderboardgames/videoquiz/videoquizfile"
import Recordscreen from "../components/uploadfiles/recorderscreen/recordscreen";
import Polls from "../components/poll/polls"
import Createranking from "../components/ranking/createranking";
import Textslide from "../components/slides/textslide";
import Uploadimg from "../components/uploadfiles/img/uploadimg";
import Uploadpdf from "../components/uploadfiles/pdf/uploadpdf";
import Uploadpptx from "../components/uploadfiles/pptx/uploadpptx";
import Uploadgifs from "../components/uploadfiles/uploadgif/uploadgifs";
import Uploadvideo from "../components/uploadfiles/vid/uploadvideo";
import Uploadmp3 from "../components/uploadfiles/uploadmp3/uploadmp3";
import Uploadurl from "../components/uploadurl/uploadurl";
import Uploadvimeo from "../components/vimeo/uploadvimeo";
import Words from "../components/wordcloud/words";
import Recordscreenimg from "../img/menu/dis/screenrecord.jpg";

const Menus = ({ id })  => {
    const history = useNavigate();
    const [show01, setShow01] = useState(false);
    const handleClose01 = () => {setShow01(false)};
    const [show02, setShow02] = useState(false);
    const handleClose02 = () => {setShow02(false)};
    const [show31, setShow31] = useState(false);
    const handleClose31 = () => {setShow31(false)};
    const [show32, setShow32] = useState(false);
    const handleClose32 = () => {setShow32(false)};
    const [show33, setShow33] = useState(false);
    const handleClose33 = () => {setShow33(false)};
    const [show34, setShow34] = useState(false);
    const handleClose34 = () => {setShow34(false)};
    const [show35, setShow35] = useState(false);
    const handleClose35 = () => {setShow35(false)};
    const [show36, setShow36] = useState(false);
    const handleClose36 = () => {setShow36(false)};
    const [show37, setShow37] = useState(false);
    const handleClose37 = () => {setShow37(false)};
    const [show38, setShow38] = useState(false);
    const handleClose38 = () => {setShow38(false)};
    const [show39, setShow39] = useState(false);
    const handleClose39 = () => {setShow39(false)};
    const [show40, setShow40] = useState(false);
    const handleClose40 = () => {setShow40(false)};
    const [show10, setShow10] = useState(false);
    const handleClose10 = () => {setShow10(false)};
    const [show11, setShow11] = useState(false);
    const handleClose11 = () => {setShow11(false)};
    const [show12, setShow12] = useState(false);
    const handleClose12 = () => {setShow12(false)};
    const [show13, setShow13] = useState(false);
    const handleClose13 = () => {setShow13(false)};
    const [show14, setShow14] = useState(false);
    const handleClose14 = () => {setShow14(false)};
    const [show15, setShow15] = useState(false);
    const handleClose15 = () => {setShow15(false)};
    const [show16, setShow16] = useState(false);
    const handleClose16 = () => {setShow16(false)};
    const [show17, setShow17] = useState(false);
    const handleClose17 = () => {setShow17(false)};
    const [show18, setShow18] = useState(false);
    const handleClose18 = () => {setShow18(false)};
    const [show20, setShow20] = useState(false);
    const handleClose20 = () => {setShow20(false)};
    const [show21, setShow21] = useState(false);
    const handleClose21 = () => {setShow21(false)};
    const [show22, setShow22] = useState(false);
    const handleClose22 = () => {setShow22(false)};
    const [show23, setShow23] = useState(false);
    const handleClose23 = () => {setShow23(false)};
    const [show41, setShow41] = useState(false);
    const handleClose41 = () => {setShow41(false)};
    const [show42, setShow42] = useState(false);
    const handleClose42 = () => {setShow42(false)};
    const [show43, setShow43] = useState(false);
    const handleClose43 = () => {setShow43(false)};
    const [show44, setShow44] = useState(false);
    const handleClose44 = () => {setShow44(false)};
    const [show45, setShow45] = useState(false);
    const handleClose45 = () => {setShow45(false)};
    const [show46, setShow46] = useState(false);
    const handleClose46 = () => {setShow46(false)};
    const [show24, setShow24] = useState(false);
    const handleClose24 = () => {setShow24(false)};
    const [show50, setShow50] = useState(false);
    const handleClose50 = () => {setShow50(false)};
    const [show51, setShow51] = useState(false);
    const handleClose51 = () => {setShow51(false)};
    const [show52, setShow52] = useState(false);
    const handleClose52 = () => {setShow52(false)};
    const [show53, setShow53] = useState(false);
    const handleClose53 = () => {setShow53(false)};
    const [show54, setShow54] = useState(false);
    const handleClose54 = () => {setShow54(false)};
    const [show55, setShow55] = useState(false);
    const handleClose55 = () => {setShow55(false)};
    const [show56, setShow56] = useState(false);
    const handleClose56 = () => {setShow56(false)};
    const [show57, setShow57] = useState(false);
    const handleClose57 = () => {setShow57(false)};
    const [show58, setShow58] = useState(false);
    const handleClose58 = () => {setShow58(false)};
    const [show59, setShow59] = useState(false);
    const handleClose59 = () => {setShow59(false)};
    const [show60, setShow60] = useState(false);
    const handleClose60 = () => {setShow60(false)};
    const [show61, setShow61] = useState(false);
    const handleClose61 = () => {setShow61(false)};
    const [show62, setShow62] = useState(false);
    const handleClose62 = () => {setShow62(false)};
    const [show63, setShow63] = useState(false);
    const handleClose63 = () => {setShow63(false)};
    const [show64, setShow64] = useState(false);
    const handleClose64 = () => {setShow64(false)};
    const [show65, setShow65] = useState(false);
    const handleClose65 = () => {setShow65(false)};
    const [show66, setShow66] = useState(false);
    const handleClose66 = () => {setShow66(false)};
    const [show67, setShow67] = useState(false);
    const handleClose67 = () => {setShow67(false)};
    const [show68, setShow68] = useState(false);
    const handleClose68 = () => {setShow68(false)};
    const [show69, setShow69] = useState(false);
    const handleClose69 = () => {setShow69(false)};
    const [show70, setShow70] = useState(false);
    const handleClose70 = () => {setShow70(false)};
    const [show71, setShow71] = useState(false);
    const handleClose71 = () => {setShow71(false)};
    const [show72, setShow72] = useState(false);
    const handleClose72 = () => {setShow72(false)};
    const [show73, setShow73] = useState(false);
    const handleClose73 = () => {setShow73(false)};
    const [show74, setShow74] = useState(false);
    const handleClose74 = () => {setShow74(false)};
    const [show75, setShow75] = useState(false);
    const handleClose75 = () => {setShow75(false)};
    const [show76, setShow76] = useState(false);
    const handleClose76 = () => {setShow76(false)};
    const [show77, setShow77] = useState(false);
    const handleClose77 = () => {setShow77(false)};


    const videofunctions = async () => {
        setShow73(true);
    }
    const pdffunctions = async () => {
        setShow70(true);
    }
    const imgfunctions = async () => {
        setShow69(true);
    }
    const urlfunctions = async () => {
        setShow74(true)
    }
    const vimeofunctions = async () => {
        setShow75(true)
    }
    const pptxfunctions = async () => {
        setShow71(true);
    }
    const giffunctions = async () => {
        setShow53(true);
    }
    const pollfunctions = async () => {
        setShow66(true)
    }
    const cosgiffunctions = async () => {
        setShow72(true);
    }
    const fastfunctions = async () => {
        setShow55(true)
    }
    const optionsfunctions = async () => {
        setShow61(true);
    }
    const flipcardfunctions = async () => {
        setShow56(true)
    }
    const videoquizefunctions = async () => {
        setShow64(true)
    }
    const recordfunctions = async () => {
        history(`/ideorecordr/${id}`);
    }
    function godragdrop(){
        setShow63(true);
    }
    function gomultiple(){
        setShow60(true);
    }
    function textfunctions(){
        setShow68(true)
    }
    function blackboardfunctions(){
        setShow51(true)
    }
    function gowhiteboard(){
        setShow54(true)
    }
    function answeraquestionfunctions(){
        setShow50(true)
    }
    function sequecingfunctions(){
        setShow62(true);
    }
    function rankingfunctions(){
        setShow67(true)
    }
    function adobefunction(){
        history(`/adobe/${id}`);
    }
    function desmossequcing(){
        setShow59(true);
    }
    function desmosmulti(){
        setShow58(true);
    }
    function mathfillinfunctions(){
        setShow57(true);
    }
    function rescreenfunctions(){
        setShow65(true)
    }
    function flashcardsfunctions(){
        setShow52(true)
    }
    function wordcloudfunctions(){
        setShow76(true)
    }
    function audiofunctions(){
        setShow77(true)
    }
    function gocontactpage(){
        window.open("https://reachum.com/contact/")
    }
    return(
        <>
                    <Table responsive bordered>
                        <tbody>
                        <tr>
                            <td>
                                <div className="cccenter">
                                    <h4 className="underlinemedia">Media</h4>
                                </div>
                                <div className="menuslide">
                                    <p>Upload existing content or create new assets without leaving the platform.</p>
                                </div>
                                <hr className="smallhr"/>
                                <br/>
                                <div className="menuslides">
                                    <div className="menuslide">
                                        <span onClick={pptxfunctions}>Upload PowerPoint </span>
                                        <img src={Info} onClick={() => {
                                            setShow34(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={urlfunctions}>Show Webpage </span>
                                        <img src={Info} onClick={() => {
                                            setShow37(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={textfunctions}>Text Slide </span>
                                        <img src={Info} onClick={() => {
                                            setShow31(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={imgfunctions}>Upload Image </span>
                                        <img src={Info} onClick={() => {
                                            setShow32(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={videofunctions}>Upload Video </span>
                                        <img src={Info} onClick={() => {
                                            setShow33(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={vimeofunctions}>Video Link </span>
                                        <img src={Info} onClick={() => {
                                            setShow38(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={recordfunctions}>Record a Video </span>
                                        <img src={Info} onClick={() => {
                                            setShow10(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={rescreenfunctions}>Record Screen </span>
                                        <img src={Info} onClick={() => {
                                            setShow42(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={audiofunctions}>Upload Audio </span>
                                        <img src={Info} onClick={() => {
                                            setShow46(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={pdffunctions}>Upload PDF </span>
                                        <img src={Info} onClick={() => {
                                            setShow35(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={cosgiffunctions}>Upload GIF </span>
                                        <img src={Info} onClick={() => {
                                            setShow36(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={giffunctions}>Giphy Library </span>
                                        <img src={Info} onClick={() => {
                                            setShow11(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={blackboardfunctions}>Draw </span>
                                        <img src={Info} onClick={() => {
                                            setShow39(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={flashcardsfunctions}>Flashcard </span>
                                        <img src={Info} onClick={() => {
                                            setShow41(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={adobefunction}>Adobe Express </span>
                                        <img src={Info} onClick={() => {
                                            setShow23(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="cccenter">
                                    <h4 className="underlinediscussion">Discussion Tools</h4>
                                </div>
                                <div className="menuslide">
                                    <p>Stimulate animated conversations using Discussion Tools. Identities protected.</p>
                                </div>
                                <hr className="smallhr"/>
                                <br/>
                                <div className="menuslides">
                                    <div className="menuslide">
                                        <span onClick={pollfunctions}>Poll </span>
                                        <img src={Info} onClick={() => {
                                            setShow12(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={answeraquestionfunctions}>SafeAsk </span>
                                        <img src={Info} onClick={() => {
                                            setShow13(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={wordcloudfunctions}>Word Cloud </span>
                                        <img src={Info} onClick={() => {
                                            setShow24(true)
                                        }} width="18px"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={rankingfunctions}>Ranking Poll </span>
                                        <img src={Info} onClick={() => {
                                            setShow40(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <hr className="roleplaycontent" />
                                <br/>
                                <div className="cccenter">
                                    <h4 className="underlinecustome">Customized activities</h4>
                                </div>
                                <div className="menuslide">
                                    <p>You are unique, and so are your needs.</p>
                                </div>
                                <div className="menuslides">
                                <div className="menuslide">
                                    <div onClick={() => {
                                        setShow01(true)
                                    }}>
                                        <span> Roleplay </span>
                                        <img src={Info} width="18px" alt="Info"/>
                                    </div>
                                </div>
                                    <div className="menuslide">
                                        <div onClick={() => {
                                            setShow02(true)
                                        }}>
                                            <span> Visual content </span>
                                            <img src={Info} width="18px" alt="Info"/>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="cccenter">
                                    <h4 className="underlineleaderboard">Leaderboard Games</h4>
                                </div>
                                <div className="menuslide">
                                    <p>Create leaderboard game competition using time-based or untimed scoring.</p>
                                </div>
                                <hr className="smallhr"/>
                                <br/>
                                <div className="menuslides">
                                    <div className="menuslide">
                                        <span onClick={optionsfunctions}>Single - Select </span>
                                        <img src={Info} onClick={() => {
                                            setShow14(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={gomultiple}>Multi - Select </span>
                                        <img src={Info} onClick={() => {
                                            setShow15(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={fastfunctions}>Fill in the Blank </span>
                                        <img src={Info} onClick={() => {
                                            setShow16(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={gowhiteboard}>Draw Quiz </span>
                                        <img src={Info} onClick={() => {
                                            setShow17(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={videoquizefunctions}>Video Quiz </span>
                                        <img src={Info} onClick={() => {
                                            setShow18(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={godragdrop}>Tap Tap </span>
                                        <img src={Info} onClick={() => {
                                            setShow21(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={sequecingfunctions}>Sequencing </span>
                                        <img src={Info} onClick={() => {
                                            setShow22(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={flipcardfunctions}>Memory Matching Game </span>
                                        <img src={Info} onClick={() => {
                                            setShow20(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={mathfillinfunctions}>Math Fill in the Blank </span>
                                        <img src={Info} onClick={() => {
                                            setShow43(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={desmosmulti}>Math Select </span>
                                        <img src={Info} onClick={() => {
                                            setShow44(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                    <div className="menuslide">
                                        <span onClick={desmossequcing}>Math Sequencing </span>
                                        <img src={Info} onClick={() => {
                                            setShow45(true)
                                        }} width="18px" alt="Info"/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
            <Modal show={show01} onHide={handleClose01} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Roleplay</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="center">
                        <iframe src="https://game.clickchainz.com/info-reachum/Roleplay2/index.html"  className="disroleplayiframe" />
                        <br/>
                        <br/>
                        <p>Roleplay is an interactive activity where participants take on specific roles and act out scenarios. It's used for education, training, therapy, and entertainment, helping people understand, practice, or explore various situations and perspectives.</p>
                        <p>We can customize roleplay content to fit your needs.</p>
                        <button className="newcalendarbuttoncolor" onClick={gocontactpage}>Contact the Reachum Team</button>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show02} onHide={handleClose02} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis"> Visual content </span>
                </Modal.Header>
                <Modal.Body>
                    <div className="center">
                        <iframe src="https://game.clickchainz.com/info-reachum/webpage/index.html"  className="disroleplayiframe" />
                        <br/>
                        <br/>
                        <p>Visual content includes images, videos, graphics, and interactive elements used to convey information or tell a story. It's engaging and effective for communication, marketing, and education.</p>
                        <p>We can customize visual content to meet your needs.</p>
                        <button className="newcalendarbuttoncolor" onClick={gocontactpage}>Contact the Reachum Team</button>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show31} onHide={handleClose31} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Text Slide</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Textslidedis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Create an editable text slide with our rich text editor. Use this tool to create a simple slide or a complex navigation menu.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show32} onHide={handleClose32} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Image</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Imagedis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Upload a jpeg or png image.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show33} onHide={handleClose33} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Video</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Videodis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Upload a video for precise playback control across all student devices.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show46} onHide={handleClose46} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Audio</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Audiodis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Upload an audio file that everyone can control during playback.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show34} onHide={handleClose34} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">PowerPoint</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Pptxdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Upload a PowerPoint deck. PowerPoint files are converted to separate static images that can be reordered or deleted within the REACHUM platform. Please ensure that the fonts are embedded in the PPTX file before uploading.</p>
                       <p>Recognized font library</p>
                        <ul className="leftsidemake">
                            <li>Academy Engraved LET</li>
                            <li>AcadEref</li>
                            <li>Adobe Garamond Pro</li>
                            <li>Agency FB</li>
                            <li>Aharoni</li>
                            <li>AIGDT</li>
                            <li>Akzidenz-Grotesk Pro Bold Cnd</li>
                            <li>Akzidenz-Grotesk Pro Cnd</li>
                            <li>Akzidenz-Grotesk Pro ExtraBold</li>
                            <li>Akzidenz-Grotesk Pro Light Cnd</li>
                            <li>Akzidenz-Grotesk Pro Med Cnd</li>
                            <li>Akzidenz-Grotesk Pro XBd Cnd</li>
                            <li>Aldhabi</li>
                            <li>Alef</li>
                            <li>Algerian</li>
                            <li>AmdtSymbols</li>
                            <li>American Typewriter</li>
                            <li>AMGDT</li>
                            <li>Amiri</li>
                            <li>Amiri Quran</li>
                            <li>AmpleSoft</li>
                            <li>Andalus</li>
                            <li>Angsana New</li>
                            <li>Arabic Typesetting</li>
                            <li>Arial</li>
                            <li>Arial Black</li>
                            <li>Arial Narrow</li>
                            <li>Arial Nova</li>
                            <li>Arial Nova Cond</li>
                            <li>Arial Nova Cond Light</li>
                            <li>Arial Nova Light</li>
                            <li>Arial Rounded MT Bold</li>
                            <li>Arimo</li>
                            <li>Arrow</li>
                            <li>Avenir</li>
                            <li>Avenir Black</li>
                            <li>Avenir Book</li>
                            <li>Avenir Heavy</li>
                            <li>Avenir LT Std 35 Light</li>
                            <li>Avenir LT Std 45 Book</li>
                            <li>Avenir LT Std 55 Roman</li>
                            <li>Avenir LT Std 65 Medium</li>
                            <li>Avenir Next</li>
                            <li>AvenirNext LT Pro Bold</li>
                            <li>AvenirNext LT Pro Cn</li>
                            <li>AvenirNext LT Pro HeavyCn</li>
                            <li>AvenirNext LT Pro MediumCn</li>
                            <li>AvenirNext LT Pro Regular</li>
                            <li>AvenirNext LT Pro UltLightCn</li>
                            <li>Axiforma</li>
                            <li>Axiforma Black</li>
                            <li>Axiforma Black Italic</li>
                            <li>Axiforma Book</li>
                            <li>Axiforma Book Italic</li>
                            <li>Axiforma ExtraBold</li>
                            <li>Axiforma ExtraBold Italic</li>
                            <li>Axiforma Heavy</li>
                            <li>Axiforma Heavy Italic</li>
                            <li>Axiforma Light</li>
                            <li>Axiforma Light Italic</li>
                            <li>Axiforma Medium</li>
                            <li>Axiforma Medium Italic</li>
                            <li>Axiforma SemiBold</li>
                            <li>Axiforma SemiBold Italic</li>
                            <li>Axiforma Thin</li>
                            <li>Axiforma Thin Italic</li>
                            <li>Bahnschrift</li>
                            <li>Bahnschrift Condensed</li>
                            <li>Bahnschrift Light</li>
                            <li>Bahnschrift Light Condensed</li>
                            <li>Bahnschrift Light SemiCondensed</li>
                            <li>Bahnschrift SemiBold</li>
                            <li>Bahnschrift SemiBold Condensed</li>
                            <li>Bahnschrift SemiBold SemiConden</li>
                            <li>Bahnschrift SemiCondensed</li>
                            <li>Bahnschrift SemiLight</li>
                            <li>Bahnschrift SemiLight Condensed</li>
                            <li>Bahnschrift SemiLight SemiConde</li>
                            <li>BankGothic Lt BT</li>
                            <li>BankGothic Md BT</li>
                            <li>Baskerville</li>
                            <li>Baskerville BT</li>
                            <li>Baskerville Old Face</li>
                            <li>Batang</li>
                            <li>BatangChe</li>
                            <li>Bauhaus 93</li>
                            <li>Beatrice</li>
                            <li>Beatrice Extrabold</li>
                            <li>Beatrice Light</li>
                            <li>Beatrice Medium</li>
                            <li>Beatrice Semibold</li>
                            <li>Beatrice Thin</li>
                            <li>Bebas Neue</li>
                            <li>Bell MT</li>
                            <li>Berlin Sans FB</li>
                            <li>Berlin Sans FB Demi</li>
                            <li>Bernard MT Condensed</li>
                            <li>Binner Gothic</li>
                            <li>BIZ UDGothic</li>
                            <li>BIZ UDMincho Medium</li>
                            <li>BIZ UDPGothic</li>
                            <li>BIZ UDPMincho Medium</li>
                            <li>Blackadder ITC</li>
                            <li>Bodoni</li>
                            <li>Bodoni Bd BT</li>
                            <li>Bodoni BdCn BT</li>
                            <li>Bodoni Bk BT</li>
                            <li>Bodoni BT</li>
                            <li>Bodoni MT</li>
                            <li>Bodoni MT Black</li>
                            <li>Bodoni MT Condensed</li>
                            <li>Bodoni MT Poster Compressed</li>
                            <li>Book Antiqua</li>
                            <li>Bookman Old Style</li>
                            <li>Bookshelf Symbol 7</li>
                            <li>Bosch Office Sans</li>
                            <li>Botanical</li>
                            <li>Bradley Hand ITC</li>
                            <li>Bree Serif</li>
                            <li>Britannic Bold</li>
                            <li>Broadway</li>
                            <li>Brush Script</li>
                            <li>Brush Script MT</li>
                            <li>Caladea</li>
                            <li>Calibri</li>
                            <li>Calibri Light</li>
                            <li>Californian FB</li>
                            <li>Calisto MT</li>
                            <li>Cambria</li>
                            <li>Cambria Math</li>
                            <li>Campton Book</li>
                            <li>Campton Medium</li>
                            <li>Candara</li>
                            <li>Candara Light</li>
                            <li>Carlito</li>
                            <li>Castellar</li>
                            <li>Centaur</li>
                            <li>Century</li>
                            <li>Century Gothic</li>
                            <li>Century Schoolbook</li>
                            <li>ChalkDust</li>
                            <li>Chiller</li>
                            <li>CityBlueprint</li>
                            <li>Cochin LT</li>
                            <li>Colonna MT</li>
                            <li>Comfortaa</li>
                            <li>Comfortaa Light</li>
                            <li>Comic Sans MS</li>
                            <li>CommercialPi BT</li>
                            <li>CommercialScript BT</li>
                            <li>Complex</li>
                            <li>Consolas</li>
                            <li>Constantia</li>
                            <li>Cooper Black</li>
                            <li>Copperplate Gothic Bold</li>
                            <li>Copperplate Gothic Light</li>
                            <li>Corbel</li>
                            <li>Corbel Light</li>
                            <li>Cordia New</li>
                            <li>CountryBlueprint</li>
                            <li>Courier New</li>
                            <li>Curlz MT</li>
                            <li>David</li>
                            <li>David CLM</li>
                            <li>David Libre</li>
                            <li>Daytona Condensed</li>
                            <li>DejaVu Math TeX Gyre</li>
                            <li>DejaVu Sans</li>
                            <li>DejaVu Sans Condensed</li>
                            <li>DejaVu Sans Light</li>
                            <li>DejaVu Sans Mono</li>
                            <li>DejaVu Serif</li>
                            <li>DejaVu Serif Condensed</li>
                            <li>DengXian</li>
                            <li>DengXian Light</li>
                            <li>DFKai-SB</li>
                            <li>Didot</li>
                            <li>DIN Alternate</li>
                            <li>DIN Condensed</li>
                            <li>DINPro-Light</li>
                            <li>Dotum</li>
                            <li>DotumChe</li>
                            <li>Dubai</li>
                            <li>Dubai Light</li>
                            <li>Dubai Medium</li>
                            <li>Dutch801 Rm BT</li>
                            <li>Dutch801 XBd BT</li>
                            <li>Ebrima</li>
                            <li>Edwardian Script ITC</li>
                            <li>Elephant</li>
                            <li>EmojiOne Color</li>
                            <li>Engravers MT</li>
                            <li>Eras Bold ITC</li>
                            <li>Eras Demi ITC</li>
                            <li>Eras Light ITC</li>
                            <li>Eras Medium ITC</li>
                            <li>Estrangelo Edessa</li>
                            <li>EuroRoman</li>
                            <li>Fabrikat</li>
                            <li>Fabrikat Black</li>
                            <li>Fabrikat Bold</li>
                            <li>Fabrikat Bold Italic</li>
                            <li>Fabrikat Hairline Italic</li>
                            <li>Fabrikat Italic</li>
                            <li>Fabrikat Light</li>
                            <li>Fabrikat Light Italic</li>
                            <li>Fabrikat Medium</li>
                            <li>Fabrikat Medium Italic</li>
                            <li>Fabrikat Thin</li>
                            <li>Fabrikat Thin Italic</li>
                            <li>FangSong</li>
                            <li>Felix Titling</li>
                            <li>Fira Sans</li>
                            <li>Fira Sans Black</li>
                            <li>Fira Sans ExtraBold</li>
                            <li>Fira Sans ExtraLight</li>
                            <li>Fira Sans Light</li>


                        <li>Fira Sans Medium</li>


                        <li>Fira Sans SemiBold</li>


                        <li>Fira Sans Thin</li>


                            <li>Footlight MT Light</li>


                            <li>Forte</li>


                            <li>Frank Ruehl CLM</li>


                            <li>Frank Ruhl Hofshi</li>


                            <li>Franklin Gothic Book</li>


                            <li>Franklin Gothic Demi</li>


                            <li>Franklin Gothic Demi Cond</li>


                            <li>Franklin Gothic Heavy</li>


                            <li>Franklin Gothic Medium</li>


                            <li>Franklin Gothic Medium Cond</li>


                            <li>FrankRuehl</li>


                            <li>Freestyle Script</li>


                            <li>French Script MT</li>


                            <li>Frutiger 55</li>


                            <li>Frutiger 56</li>


                            <li>Frutiger 57 Condensed</li>


                            <li>Frutiger 65</li>


                            <li>Frutiger 66</li>


                            <li>Frutiger 67 Condensed</li>


                            <li>Frutiger 75 Black</li>


                        <li>Frutiger 76 Black</li>


                            <li>Frutiger 77 Black Condensed</li>


                            <li>Frutiger LT Com 47 LightCn</li>


                            <li>Frutiger LT Com 55 Roman</li>


                            <li>Frutiger LT Com 57 Condensed</li>


                            <li>Frutiger LT Pro 45 Light</li>


                            <li>Frutiger LT Pro 55 Roman</li>


                            <li>Futura</li>


                            <li>Futura BdCn BT</li>


                            <li>Futura Bk BT</li>


                            <li>Futura CondensedLight</li>


                            <li>Futura Hv BT</li>


                            <li>Futura Lt BT</li>


                            <li>Futura LtCn BT</li>


                            <li>Futura Md BT</li>


                            <li>Futura MdCn BT</li>


                            <li>Futura XBlk BT</li>


                            <li>Futura XBlkCnIt BT</li>


                            <li>Futura-Black</li>


                            <li>Futura-Bold</li>


                            <li>Gabriola</li>


                            <li>Gadugi</li>


                            <li>Galano Grotesque</li>


                            <li>Galano Grotesque ExtraLight</li>


                            <li>Galano Grotesque Heavy</li>


                            <li>Galano Grotesque Medium</li>


                            <li>Garamond</li>


                            <li>GDT</li>


                            <li>GENISO</li>


                            <li>Gentium Basic</li>


                            <li>Gentium Book Basic</li>


                            <li>Georgia</li>


                            <li>Georgia Pro</li>


                            <li>Georgia Pro Black</li>


                            <li>Georgia Pro Cond</li>


                            <li>Georgia Pro Cond Black</li>


                            <li>Georgia Pro Cond Light</li>


                            <li>Georgia Pro Cond Semibold</li>


                            <li>Georgia Pro Light</li>


                            <li>Georgia Pro Semibold</li>


                            <li>Gigi</li>


                            <li>Gill Sans</li>

                            <li>Gill Sans Condensed</li>


                            <li>Gill Sans MT</li>


                            <li>Gill Sans MT Condensed</li>


                            <li>Gill Sans MT Ext Condensed Bold</li>


                            <li>Gill Sans Nova</li>


                            <li>Gill Sans Nova Cond</li>


                            <li>Gill Sans Nova Cond Lt</li>


                            <li>Gill Sans Nova Cond Ultra Bold</li>


                            <li>Gill Sans Nova Cond XBd</li>


                            <li>Gill Sans Nova Light</li>


                            <li>Gill Sans Nova Ultra Bold</li>


                            <li>Gill Sans Ultra Bold</li>


                            <li>Gill Sans Ultra Bold Condensed</li>


                        <li>Gisha</li>


                            <li>Gloucester MT Extra Condensed</li>


                            <li>Gotham Bold</li>


                            <li>Gotham Book</li>


                            <li>Gotham Light</li>


                            <li>Gotham Medium</li>


                            <li>GothicE</li>


                            <li>GothicG</li>


                            <li>GothicI</li>


                            <li>Goudy Old Style</li>


                            <li>Goudy Stout</li>


                            <li>GreekC</li>


                            <li>GreekS</li>


                            <li>Gulim</li>


                            <li>GulimChe</li>


                            <li>Gungsuh</li>


                            <li>GungsuhChe</li>


                            <li>Haettenschweiler</li>


                            <li>Harlow Solid Italic</li>


                            <li>Harrington</li>


                            <li>High Tower Text</li>


                            <li>Hoefler Text</li>


                            <li>HoloLens MDL2 Assets</li>


                            <li>IBM Plex Sans</li>


                            <li>IBM Plex Serif</li>


                            <li>IDAHC39M Code 39 Barcode</li>


                            <li>Impact</li>


                            <li>Imprint MT Shadow</li>


                            <li>Informal Roman</li>


                            <li>Ink Free</li>


                            <li>IowanOldSt BT</li>


                            <li>ISOCP</li>


                            <li>ISOCP2</li>


                            <li>ISOCP3</li>


                            <li>ISOCPEUR</li>


                            <li>ISOCT</li>


                            <li>ISOCT2</li>


                            <li>ISOCT3</li>


                            <li>ISOCTEUR</li>


                            <li>Italic</li>


                            <li>ItalicC</li>


                            <li>ItalicT</li>


                            <li>Javanese Text</li>


                            <li>Jokerman</li>


                            <li>Juice ITC</li>


                            <li>KacstBook</li>


                            <li>KacstOffice</li>


                            <li>KaiTi</li>


                            <li>Kartika</li>


                            <li>Klavika Bold</li>


                            <li>Klavika Bold Condensed</li>


                            <li>Klavika Condensed</li>


                            <li>Klavika Light</li>


                            <li>Klavika Light Condensed</li>


                            <li>Klavika Lt</li>


                            <li>Klavika Md</li>


                            <li>Klavika Medium Condensed</li>


                            <li>Klavika Regular</li>


                            <li>Klavika Rg</li>


                            <li>Kokila</li>


                            <li>Kristen ITC</li>


                            <li>Kunstler Script</li>


                            <li>Lato</li>


                            <li>Lato Black</li>


                            <li>Lato Hairline</li>


                            <li>Lato Heavy</li>


                            <li>Lato Light</li>


                            <li>Lato Medium</li>


                            <li>Lato Semibold</li>


                            <li>Lato Thin</li>


                            <li>Lavigne</li>


                            <li>Leelawadee</li>


                            <li>Leelawadee UI</li>


                            <li>Leelawadee UI Semilight</li>


                            <li>Letter Gothic</li>


                            <li>Levenim MT</li>


                            <li>Liberation Mono</li>


                            <li>Liberation Sans</li>


                            <li>Liberation Sans Narrow</li>


                            <li>Liberation Serif</li>


                            <li>Libre Baskerville</li>


                            <li>Libre Franklin</li>


                            <li>Libre Franklin Black</li>


                            <li>Libre Franklin ExtraBold</li>


                            <li>Libre Franklin ExtraLight</li>


                            <li>Libre Franklin Light</li>


                            <li>Libre Franklin Medium</li>


                            <li>Libre Franklin SemiBold</li>


                            <li>Libre Franklin Thin</li>


                            <li>Linux Biolinum G</li>


                            <li>Linux Libertine Display G</li>


                            <li>Linux Libertine G</li>


                            <li>Lotus Postal Barcode</li>


                            <li>LotusWP Box</li>


                            <li>LotusWP Icon</li>


                            <li>LotusWP Int A</li>


                            <li>LotusWP Int B</li>


                            <li>LotusWP Type</li>


                            <li>Lucia BT</li>


                            <li>Lucida Bright</li>


                            <li>Lucida Calligraphy</li>


                            <li>Lucida Console</li>


                            <li>Lucida Fax</li>


                            <li>Lucida Handwriting</li>


                        <li>Lucida Sans</li>


                            <li>Lucida Sans Typewriter</li>


                            <li>Lucida Sans Unicode</li>


                            <li>Lydian</li>


                            <li>Magneto</li>


                            <li>Maiandra GD</li>


                            <li>Malgun Gothic</li>


                            <li>Malgun Gothic Semilight</li>


                            <li>Mangal</li>


                            <li>Marcellus</li>


                            <li>Marker Felt</li>


                            <li>Marlett</li>


                            <li>Math A</li>


                            <li>Math B</li>


                            <li>Math C</li>


                            <li>Matura MT Script Capitals</li>


                            <li>Meiryo</li>


                            <li>Meiryo UI</li>


                            <li>Merriweather</li>


                            <li>Merriweather Light</li>


                        <li>Microsoft Himalaya</li>

                            <li>Microsoft JhengHei</li>


                            <li>Microsoft JhengHei Light</li>


                            <li>Microsoft JhengHei UI</li>


                            <li>Microsoft JhengHei UI Light</li>


                            <li>Microsoft New Tai Lue</li>


                            <li>Microsoft PhagsPa</li>


                            <li>Microsoft Sans Serif</li>


                            <li>Microsoft Tai Le</li>


                            <li>Microsoft Uighur</li>


                            <li>Microsoft YaHei</li>


                            <li>Microsoft YaHei Light</li>


                            <li>Microsoft YaHei UI</li>


                            <li>Microsoft YaHei UI Light</li>


                            <li>Microsoft Yi Baiti</li>


                            <li>MingLiU</li>


                            <li>MingLiU-ExtB</li>


                            <li>MingLiU_HKSCS</li>


                            <li>MingLiU_HKSCS-ExtB</li>


                            <li>Minion Pro</li>


                        <li>Miriam</li>


                            <li>Miriam CLM</li>


                            <li>Miriam Fixed</li>


                            <li>Miriam Libre</li>


                            <li>Miriam Mono CLM</li>


                            <li>Mistral</li>


                            <li>Modern No. 20</li>


                            <li>Mongolian Baiti</li>


                            <li>Monospac821 BT</li>


                            <li>Monotxt</li>


                            <li>Monotype Corsiva</li>


                            <li>Monotype Sorts</li>


                            <li>Montserrat</li>


                            <li>Montserrat Black</li>


                            <li>Montserrat ExtraBold</li>


                            <li>Montserrat ExtraLight</li>


                            <li>Montserrat Light</li>


                            <li>Montserrat Medium</li>


                            <li>Montserrat SemiBold</li>


                            <li>Montserrat Thin</li>


                            <li>MS Gothic</li>


                            <li>MS Mincho</li>


                            <li>MS PGothic</li>


                            <li>MS PMincho</li>


                            <li>MS Reference Sans Serif</li>


                            <li>MS Reference Specialty</li>


                            <li>MS UI Gothic</li>


                            <li>MT Extra</li>


                            <li>Muli</li>


                            <li>MV Boli</li>


                            <li>Myanmar Text</li>


                            <li>Myriad Pro</li>


                            <li>Myriad Pro Light</li>


                            <li>Nachlieli CLM</li>


                            <li>Narkisim</li>


                            <li>Neue Haas Grotesk Text Pro</li>


                            <li>Neue Haas Grotesk Text Pro Medi</li>


                            <li>News Gothic</li>


                            <li>News Gothic Condensed</li>


                            <li>News Gothic MT</li>


                            <li>Niagara Engraved</li>


                            <li>Niagara Solid</li>


                            <li>Nimrod</li>


                            <li>Nirmala UI</li>


                            <li>Nirmala UI Semilight</li>


                            <li>Noto Kufi Arabic</li>


                            <li>Noto Mono</li>


                            <li>Noto Naskh Arabic</li>


                            <li>Noto Naskh Arabic UI</li>


                            <li>Noto Sans</li>


                            <li>Noto Sans Arabic</li>


                            <li>Noto Sans Arabic UI</li>


                            <li>Noto Sans Armenian</li>


                            <li>Noto Sans Cond</li>


                            <li>Noto Sans Georgian</li>


                            <li>Noto Sans Hebrew</li>


                            <li>Noto Sans Lao</li>


                            <li>Noto Sans Light</li>


                            <li>Noto Sans Lisu</li>


                            <li>Noto Sans Symbols</li>


                            <li>Noto Serif</li>


                            <li>Noto Serif Armenian</li>


                            <li>Noto Serif Cond</li>


                            <li>Noto Serif Georgian</li>


                            <li>Noto Serif Hebrew</li>


                            <li>Noto Serif Lao</li>


                            <li>Noto Serif Light</li>


                            <li>NSimSun</li>


                            <li>Nunito</li>


                            <li>Nunito Black</li>


                            <li>Nunito ExtraBold</li>


                            <li>Nunito ExtraLight</li>


                            <li>Nunito Light</li>


                            <li>Nunito Medium</li>


                            <li>Nunito SemiBold</li>


                            <li>NYTFranklin ExtraBold</li>


                            <li>NYTFranklin Light</li>


                            <li>NYTFranklin Medium</li>


                            <li>NYTFranklin Semibold</li>


                            <li>NYTImperial Regular</li>


                            <li>NYTImperial Semibold</li>


                            <li>OCR A Extended</li>


                            <li>Old English Text MT</li>


                            <li>Olde English</li>


                            <li>Onyx</li>


                            <li>Open Sans</li>


                            <li>Open Sans Extrabold</li>


                            <li>Open Sans Light</li>


                            <li>Open Sans Semibold</li>

                            <li>OpenSymbol</li>


                            <li>OPTICopperplate</li>


                            <li>OPTICopperplate Heavy</li>


                            <li>OPTICopperplate-Light</li>


                            <li>Optima</li>


                            <li>Oswald</li>


                            <li>Palace Script MT</li>


                            <li>Palatino</li>


                            <li>Palatino Linotype</li>


                            <li>PanRoman</li>


                            <li>Papyrus</li>


                            <li>Parchment</li>


                            <li>Perpetua</li>


                            <li>Perpetua Titling MT</li>


                            <li>Photina Casual Black</li>


                            <li>Playbill</li>


                            <li>PMingLiU</li>


                            <li>PMingLiU-ExtB</li>


                            <li>Poor Richard</li>


                            <li>Poppins</li>


                            <li>Poppins Black</li>


                            <li>Poppins ExtraBold</li>


                            <li>Poppins ExtraLight</li>


                            <li>Poppins Light</li>


                            <li>Poppins Medium</li>


                            <li>Poppins SemiBold</li>


                            <li>Poppins Thin</li>


                            <li>Pristina</li>


                            <li>Proxima Nova Bl</li>


                            <li>Proxima Nova Lt</li>


                            <li>Proxima Nova Rg</li>


                            <li>Proxima Nova Th</li>


                            <li>Proxy 1</li>


                            <li>Proxy 2</li>


                            <li>Proxy 3</li>


                            <li>Proxy 4</li>


                            <li>Proxy 5</li>


                            <li>Proxy 6</li>


                            <li>Proxy 7</li>


                            <li>Proxy 8</li>


                            <li>Proxy 9</li>


                            <li>PT Serif</li>


                            <li>Rage Italic</li>


                            <li>Raleway</li>


                            <li>Raleway Black</li>


                            <li>Raleway ExtraBold</li>


                            <li>Raleway ExtraLight</li>


                            <li>Raleway Light</li>


                            <li>Raleway Medium</li>


                            <li>Raleway SemiBold</li>


                            <li>Raleway Thin</li>


                            <li>Ravie</li>


                            <li>Reem Kufi</li>


                            <li>Restora ExtraLight</li>


                            <li>Restora Neue</li>


                            <li>Ridley Grotesk</li>


                            <li>Ridley Grotesk Black</li>


                            <li>Ridley Grotesk Black Italic</li>


                            <li>Ridley Grotesk Bold</li>


                            <li>Ridley Grotesk Bold Italic</li>


                            <li>Ridley Grotesk ExtraBold</li>


                            <li>Ridley Grotesk ExtraBold Italic</li>


                            <li>Ridley Grotesk Italic</li>


                            <li>Ridley Grotesk Light</li>


                            <li>Ridley Grotesk Light Italic</li>


                            <li>Ridley Grotesk Medium</li>


                            <li>Ridley Grotesk Medium Italic</li>


                            <li>Ridley Grotesk SemiBold</li>


                            <li>Ridley Grotesk SemiBold Italic</li>


                            <li>Ridley Grotesk Thin</li>


                            <li>Ridley Grotesk Thin Italic</li>


                            <li>Ridley Grotesk UltraLight</li>


                            <li>Ridley Grotesk UltraLight It</li>


                            <li>Roboto</li>


                            <li>Roboto Black</li>


                            <li>Roboto Condensed Light</li>


                            <li>Roboto Light</li>


                            <li>Roboto Medium</li>


                            <li>Roboto Thin</li>


                            <li>Rockwell</li>


                            <li>Rockwell Condensed</li>


                            <li>Rockwell Extra Bold</li>


                            <li>Rockwell Light</li>


                            <li>Rockwell Nova</li>


                            <li>Rockwell Nova Cond</li>


                            <li>Rockwell Nova Cond Light</li>


                            <li>Rockwell Nova Extra Bold</li>


                            <li>Rockwell Nova Light</li>


                            <li>Rod</li>


                            <li>RomanC</li>


                            <li>RomanD</li>


                            <li>RomanS</li>


                            <li>RomanT</li>


                            <li>Romantic</li>


                            <li>Rubik</li>


                            <li>Sakkal Majalla</li>


                            <li>Salesforce Sans</li>


                            <li>Salesforce Sans Book</li>


                            <li>Salesforce Sans Light</li>


                            <li>Salesforce Sans Semibold</li>


                            <li>Salesforce Sans Thin</li>


                            <li>SansSerif</li>


                            <li>Scheherazade</li>


                            <li>Script MT Bold</li>


                            <li>ScriptC</li>


                            <li>ScriptS</li>


                            <li>Segoe MDL2 Assets</li>


                            <li>Segoe Print</li>


                            <li>Segoe Script</li>


                            <li>Segoe UI</li>


                            <li>Segoe UI Black</li>


                            <li>Segoe UI Emoji</li>


                            <li>Segoe UI Historic</li>


                            <li>Segoe UI Light</li>


                            <li>Segoe UI Semibold</li>


                            <li>Segoe UI Semilight</li>


                            <li>Segoe UI Symbol</li>


                            <li>Seriffic Grunge</li>


                            <li>Sharp Grotesk Book 19</li>


                            <li>Sharp Grotesk Book Italic 19</li>


                            <li>Sharp Grotesk Medium 12</li>


                            <li>Sharp Grotesk Medium 15</li>


                            <li>Sharp Grotesk Medium 19</li>


                            <li>Sharp Grotesk Medium 25</li>


                            <li>Showcard Gothic</li>


                            <li>SimHei</li>


                            <li>Simplex</li>


                            <li>Simplified Arabic</li>


                            <li>Simplified Arabic Fixed</li>


                            <li>SimSun</li>


                            <li>SimSun-ExtB</li>


                            <li>Sini</li>


                            <li>Sitka Banner</li>


                            <li>Sitka Display</li>


                            <li>Sitka Heading</li>


                            <li>Sitka Small</li>


                            <li>Sitka Subheading</li>


                            <li>Sitka Text</li>


                            <li>Snap ITC</li>


                            <li>Soho Gothic Pro</li>


                            <li>Soho Gothic Pro Light</li>


                            <li>Source Code Pro</li>


                            <li>Source Code Pro Black</li>


                            <li>Source Code Pro ExtraLight</li>


                            <li>Source Code Pro Light</li>


                            <li>Source Code Pro Medium</li>


                            <li>Source Code Pro Semibold</li>


                            <li>Source Sans Pro</li>


                            <li>Source Sans Pro Black</li>


                            <li>Source Sans Pro ExtraLight</li>


                            <li>Source Sans Pro Light</li>


                            <li>Source Sans Pro Semibold</li>


                            <li>Source Serif Pro</li>


                            <li>Source Serif Pro Black</li>


                            <li>Source Serif Pro ExtraLight</li>


                            <li>Source Serif Pro Light</li>


                            <li>Source Serif Pro Semibold</li>


                            <li>Stencil</li>


                            <li>Stylus BT</li>


                            <li>Superclarendon Rg</li>


                            <li>SuperFrench</li>


                            <li>Swis721 BdCnOul BT</li>


                            <li>Swis721 BdOul BT</li>


                            <li>Swis721 Blk BT</li>


                            <li>Swis721 BlkCn BT</li>


                            <li>Swis721 BlkEx BT</li>


                            <li>Swis721 BlkOul BT</li>


                            <li>Swis721 BT</li>


                            <li>Swis721 Cn BT</li>


                            <li>Swis721 Ex BT</li>


                            <li>Swis721 Lt BT</li>


                            <li>Swis721 LtCn BT</li>


                            <li>Swis721 LtEx BT</li>


                            <li>Syastro</li>


                            <li>Sylfaen</li>


                            <li>Symap</li>


                            <li>Symath</li>


                            <li>Symbol</li>


                            <li>Symeteo</li>


                            <li>Symusic</li>


                            <li>Tahoma</li>


                            <li>Technic</li>


                            <li>TechnicBold</li>


                            <li>TechnicLite</li>


                            <li>Tempus Sans ITC</li>


                            <li>Times New Roman</li>


                            <li>Toyota Type</li>


                            <li>Traditional Arabic</li>


                            <li>Trebuchet MS</li>


                            <li>Tunga</li>


                            <li>Tw Cen MT</li>


                            <li>Tw Cen MT Condensed</li>


                            <li>Tw Cen MT Condensed Extra Bold</li>


                            <li>Txt</li>


                            <li>Ubuntu</li>


                            <li>Ubuntu Condensed</li>


                            <li>Ubuntu Light</li>


                            <li>Ubuntu Mono</li>


                            <li>UD Digi Kyokasho N-B</li>


                            <li>UD Digi Kyokasho N-R</li>


                            <li>UD Digi Kyokasho NK-B</li>


                            <li>UD Digi Kyokasho NK-R</li>


                            <li>UD Digi Kyokasho NP-B</li>


                            <li>UD Digi Kyokasho NP-R</li>


                            <li>UniversalMath1 BT</li>


                            <li>Urdu Typesetting</li>


                            <li>Verdana</li>


                            <li>Verdana Pro</li>


                            <li>Verdana Pro Black</li>


                            <li>Verdana Pro Cond</li>


                            <li>Verdana Pro Cond Black</li>


                            <li>Verdana Pro Cond Light</li>


                            <li>Verdana Pro Cond Semibold</li>


                            <li>Verdana Pro Light</li>


                            <li>Verdana Pro Semibold</li>


                            <li>Vijaya</li>


                            <li>Viner Hand ITC</li>


                            <li>Vineta BT</li>


                            <li>Vivaldi</li>


                            <li>Vladimir Script</li>


                            <li>Vrinda</li>


                            <li>Webdings</li>


                            <li>Wide Latin</li>


                            <li>Wingdings</li>


                            <li>Wingdings 2</li>


                            <li>Wingdings 3</li>


                            <li>Work Sans</li>


                            <li>Work Sans Black</li>


                            <li>Work Sans ExtraBold</li>


                            <li>Work Sans ExtraLight</li>


                            <li>Work Sans Light</li>


                            <li>Work Sans Medium</li>


                            <li>Work Sans SemiBold</li>


                            <li>Work Sans Thin</li>


                            <li>Wotfard Bold</li>


                            <li>Wotfard Bold Italic</li>


                            <li>Wotfard ExtraLight</li>


                            <li>Wotfard ExtraLight Italic</li>


                            <li>Wotfard Light</li>


                            <li>Wotfard Light Italic</li>


                            <li>Wotfard Medium</li>


                            <li>Wotfard Medium Italic</li>


                            <li>Wotfard Regular</li>


                            <li>Wotfard Regular Italic</li>


                            <li>Wotfard SemiBold</li>


                            <li>Wotfard SemiBold Italic</li>


                            <li>Wotfard Thin</li>


                            <li>Wotfard Thin Italic</li>


                            <li>Yu Gothic</li>


                            <li>Yu Gothic Light</li>


                            <li>Yu Gothic Medium</li>


                            <li>Yu Gothic UI</li>


                            <li>Yu Gothic UI Light</li>


                            <li>Yu Gothic UI Semibold</li>


                            <li>Yu Gothic UI Semilight</li>
                            <li>Yu Mincho</li>
                            <li>Yu Mincho Demibold</li>
                            <li>Yu Mincho Light</li>
                            <li>Zapfino</li>
                        </ul>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show35} onHide={handleClose35} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">PDF</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Pdfdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Upload a PDF. PDF pages are converted to individual slides that can be reordered or deleted within the REACHUM platform.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show36} onHide={handleClose36} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">GIF</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Gifdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Upload your own animated gifs in REACHUM.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show37} onHide={handleClose37} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Show Webpage</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="center">
                        <iframe src="https://game.clickchainz.com/wilkes-psy101/DevelopmentalPsychology/TheBasicsofFreud/index.html"  className="disiframe" />
                        <br/>
                        <br/>
                        <p>Anything available by link is shareable in REACHUM, with full collaboration possible. Work together on documents and spreadsheets, or send a 3rd party quiz, a poll, or a shopping cart for secure, in-class transactions!</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show38} onHide={handleClose38} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Video Link</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Videolinkdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>YouTube and Vimeo links are shareable in REACHUM.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show39} onHide={handleClose39} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Draw</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Drawdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Teachers can share freehand drawings and include poll questions.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show10} onHide={handleClose10} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Record a Video</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <Tabs
                            defaultActiveKey="videoss"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="videoss" title="Record video">
                                <img src={Recordvideo1dis}  className="disfull"/>
                            </Tab>
                            <Tab eventKey="displayvideos" title="Play video">
                                <img src={Recordvideo2dis}  className="disfull"/>
                            </Tab>
                        </Tabs>
                        <br/>
                        <br/>
                        <p>REACHUM hosts can record a video up to 2 minutes long.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show11} onHide={handleClose11} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Giphy Library</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="centerthisgiphy">
                        <img src={Giphydis}  width="360px"/>
                        <br/>
                        <br/>
                        <p>Import a gif to set the  mood using GIPHY, the largest library of animated gifs on the internet.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show12} onHide={handleClose12} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Polls</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Pollsdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Measure your audience’s attitude, sentiment, or opinion with a poll.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show13} onHide={handleClose13} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">SafeAsk</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <Tabs
                            defaultActiveKey="teacher"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="teacher" title="Teacher view">
                                <img src={Openended1dis}  className="disfull"/>
                            </Tab>
                            <Tab eventKey="highlight" title="Spotlight a response">
                                <img src={Openended2dis}  className="disfull"/>
                            </Tab>
                            <Tab eventKey="student" title="Student view">
                                <img src={Openended3dis}  className="disfull"/>
                            </Tab>
                        </Tabs>
                        <br/>
                        <br/>
                        <p>SafeAsk streamlines teaching by offering real-time feedback and instant resolution of queries, saving teachers time and enhancing student understanding.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show14} onHide={handleClose14} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Single - Select</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Singleselectdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Teachers ask a question and learners select the single correct answer.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show15} onHide={handleClose15} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Multi - Select</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Multiselectdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Use for questions with more than one correct answer. Scores are weighted by the number of correct answers.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show16} onHide={handleClose16} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Fill in the Blank</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Fillindis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Students type their answer in a single input field. Perfect matches score points.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show17} onHide={handleClose17} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Draw Quiz</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Drawquizedis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Teachers draw a picture and ask learners a single select question based on their freehand drawing. Learners select the single correct answer.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show18} onHide={handleClose18} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Video Quiz</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Videoquiz1dis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Record or upload a video and insert  a timed quiz at a specified time.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show20} onHide={handleClose20} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Memory Matching Game</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Mmgdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Click one of 12 cards to flip it over and reveal its value. Click another card to try and find a match. When a match is revealed, the pair disappears. The game is successfully completed when all pairs are matched.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show21} onHide={handleClose21} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Tap Tap</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Taptapdis}  className="disfull"/>
                        <br/>
                        <br/>
                        <p>Users tap a list item to select it and tap the  corresponding category to add. Scores are weighted by the number of correct answers.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show22} onHide={handleClose22} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Sequecing</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Sequencingdis}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>Sequencing is useful for assessing timeline knowledge, number sense, process steps, paragraph structures, and more.</p>
                        <p>Use this game for activities that require putting items in a particular order.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show40} onHide={handleClose40} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Ranking Poll</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Rankingpoll}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>A ranking poll is a survey where people order a list of items by preference. You can use it for elections, product preferences, event planning, decisions, or market research.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show23} onHide={handleClose23} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Adobe Express</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Adobeoverview}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>Adobe Express enables you to create designs more efficiently with access to a vast library of professionally designed templates, Adobe Stock photos, videos, music, and more.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show41} onHide={handleClose41} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Flashcard</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Flashcardsgif}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>Flashcard is a small card with a question or information on one side and the corresponding answer or details on the other. They're used for quick and repeated learning to help remember facts, vocabulary, or concepts efficiently.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show42} onHide={handleClose42} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Record Screen</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Recordscreenimg} className="disfull1"/>
                        <br/>
                        <br/>
                        <p>"Record Screen" captures everything on your screen as a video, including audio, and
                            interactions. It’s used for creating tutorials, demos, capturing
                            gameplay, and more.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show43} onHide={handleClose43} aria-labelledby="contained-modal-title-vcenter" size="lg"
                   centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Math Fill in the Blank</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Mathfillgif} className="disfull1"/>
                        <br/>
                        <br/>
                        <p>The Math Slide feature allows you to upload images containing math, or generate graphics
                            using <span onClick={() => window.open("https://www.desmos.com/", "_blank")} className="underlinetext">Desmos</span>. It allows students to input equations as their answers.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show44} onHide={handleClose44} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Math Single or Multi-Select</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Mathmultigif}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>The Math Single or Multi-Select feature enables you to upload images containing math, create equations, include text, or generate <span onClick={()=>  window.open("https://www.desmos.com/", "_blank")} className="underlinetext">Desmos</span> graphics as answer options.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show45} onHide={handleClose45} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Math Sequencing</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Mathsequgif}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>The Math Sequencing feature empowers you to upload math-based images, formulate equations as questions, and generate graphics using <span onClick={()=>  window.open("https://www.desmos.com/", "_blank")} className="underlinetext">Desmos</span> within your sequencing.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show24} onHide={handleClose24} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className="modalbackcolor">
                <Modal.Header closeButton>
                    <span className="redthis">Word Cloud</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Wordcloudoverview}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>Word Cloud is an excellent icebreaker activity to kick off a meeting, workshop, or any gathering where participants are encouraged to engage and interact. Limit student responses to 25 characters.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show50} onHide={handleClose50} onClose={handleClose50} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Answer id={id} handleClose50={handleClose50}/>
                </Modal.Body>
            </Modal>
            <Modal show={show51} onHide={handleClose51} onClose={handleClose51} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Blackboard id={id} handleClose51={handleClose51}/>
                </Modal.Body>
            </Modal>
            <Modal show={show52} onHide={handleClose52} onClose={handleClose52} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Createflipcards id={id} handleClose52={handleClose52}/>
                </Modal.Body>
            </Modal>
            <Modal show={show53} onHide={handleClose53} onClose={handleClose53} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Giphy id={id} handleClose53={handleClose53}/>
                </Modal.Body>
            </Modal>
            <Modal show={show54} onHide={handleClose54} onClose={handleClose54} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Drawcreateoptions id={id} handleClose54={handleClose54}/>
                </Modal.Body>
            </Modal>
            <Modal show={show55} onHide={handleClose55} onClose={handleClose55} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Fastanswer id={id} handleClose55={handleClose55}/>
                </Modal.Body>
            </Modal>
            <Modal show={show56} onHide={handleClose56} onClose={handleClose56} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Flipcards id={id} handleClose56={handleClose56}/>
                </Modal.Body>
            </Modal>
            <Modal show={show57} onHide={handleClose57} onClose={handleClose57} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Mathfastanswer id={id} handleClose57={handleClose57}/>
                </Modal.Body>
            </Modal>
            <Modal show={show58} onHide={handleClose58} onClose={handleClose58} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Createdesmosmultioptions id={id} handleClose58={handleClose58}/>
                </Modal.Body>
            </Modal>
            <Modal show={show59} onHide={handleClose59} onClose={handleClose59} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Createdesmossequencing id={id} handleClose59={handleClose59}/>
                </Modal.Body>
            </Modal>
            <Modal show={show60} onHide={handleClose60} onClose={handleClose60} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Createmultioptions id={id} handleClose60={handleClose60}/>
                </Modal.Body>
            </Modal>
            <Modal show={show61} onHide={handleClose61} onClose={handleClose61} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Createoptions id={id} handleClose61={handleClose61}/>
                </Modal.Body>
            </Modal>
            <Modal show={show62} onHide={handleClose62} onClose={handleClose62} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Createsequencing id={id} handleClose62={handleClose62}/>
                </Modal.Body>
            </Modal>
            <Modal show={show63} onHide={handleClose63} onClose={handleClose63} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Categorydragdrop id={id} handleClose63={handleClose63}/>
                </Modal.Body>
            </Modal>
            <Modal show={show64} onHide={handleClose64} onClose={handleClose64} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Videoquizfile id={id}/>
                </Modal.Body>
            </Modal>
            <Modal show={show65} onHide={handleClose65} onClose={handleClose65} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Recordscreen id={id} handleClose65={handleClose65}/>
                </Modal.Body>
            </Modal>
            <Modal show={show66} onHide={handleClose66} onClose={handleClose66} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Polls id={id} handleClose66={handleClose66}/>
                </Modal.Body>
            </Modal>
            <Modal show={show67} onHide={handleClose67} onClose={handleClose67} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Createranking id={id} handleClose67={handleClose67}/>
                </Modal.Body>
            </Modal>
            <Modal show={show68} onHide={handleClose68} onClose={handleClose68} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Textslide id={id} handleClose68={handleClose68}/>
                </Modal.Body>
            </Modal>
            <Modal show={show69} onHide={handleClose69} onClose={handleClose69} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadimg id={id} handleClose69={handleClose69}/>
                </Modal.Body>
            </Modal>
            <Modal show={show70} onHide={handleClose70} onClose={handleClose70} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadpdf id={id} handleClose70={handleClose70}/>
                </Modal.Body>
            </Modal>
            <Modal show={show71} onHide={handleClose71} onClose={handleClose71} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadpptx id={id} handleClose71={handleClose71}/>
                </Modal.Body>
            </Modal>
            <Modal show={show72} onHide={handleClose72} onClose={handleClose72} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadgifs id={id} handleClose72={handleClose72}/>
                </Modal.Body>
            </Modal>
            <Modal show={show73} onHide={handleClose73} onClose={handleClose73} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadvideo id={id} handleClose73={handleClose73}/>
                </Modal.Body>
            </Modal>
            <Modal show={show74} onHide={handleClose74} onClose={handleClose74} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadurl id={id} handleClose74={handleClose74}/>
                </Modal.Body>
            </Modal>
            <Modal show={show75} onHide={handleClose75} onClose={handleClose75} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadvimeo id={id} handleClose75={handleClose75}/>
                </Modal.Body>
            </Modal>
            <Modal show={show76} onHide={handleClose76} onClose={handleClose76} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Words id={id} handleClose76={handleClose76}/>
                </Modal.Body>
            </Modal>
            <Modal show={show77} onHide={handleClose77} onClose={handleClose77} aria-labelledby="contained-modal-title-vcenter" size="xl" centered className="modalbackcolor">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Uploadmp3 id={id} handleClose77={handleClose77}/>
                </Modal.Body>
            </Modal>
                </>

    )
}


export default Menus;

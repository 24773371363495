import React, { useState, useRef, useEffect } from 'react';
import {API, Auth} from 'aws-amplify';
import {useParams} from "react-router-dom";
import DesmosCalculator from "./desmosCalculator";
import {
    updateDesmomathslide,
} from "../../graphql/mutations";
import Resizer from "react-image-file-resizer";
import {getDesmomathslide, getPlayslide} from "../../graphql/queries";
import Grid from "@material-ui/core/Grid";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            960,
            540,
            "JPEG",
            85,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });
const resizeFile1 = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            400,
            400,
            "JPEG",
            85,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });
const  Editmathslide = ()  =>{
    const ref = useRef(null);
    const { id,rid } = useParams();
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const addRef = useRef(null);
    const [thisimage, setThisimage] = useState('');
    const [uploadedmessage, setUploadedmessage] = useState("");
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
    }, []);
    useEffect( () => {
        (async() => {
            const response = await API.graphql({
                query: getDesmomathslide,
                variables:{id:rid}
            })
            const datas = response.data.getDesmomathslide;
            const mimage = datas.image;
            if(mimage !== null){
                setThisimage(mimage)
            }
        })()
    }, []);
    function handleMathInputChange (value) {
        ref.current.value = value
    }
    const handleFileChange = async (event) => {
        const img = event.target.files[0];
        const resized = await resizeFile1(img);
        setThisimage(resized)
    };

    async function savethis(){
        const calculatorState = addRef.current.getState()
        const latexExpressions = calculatorState.expressions.list.filter(expression => {
            return expression.latex != null
        })
        let resized;
        if (latexExpressions.length === 0) {
            resized = "";
        } else {
            const screenshot = addRef.current.screenshot()
            const response = await fetch(screenshot)
            const blob = await response.blob()
            resized = await resizeFile(blob)
        }
        try {
            const input = {
                id:rid,
                graphic:resized,
                image:thisimage
            }
            await API.graphql({
                query: updateDesmomathslide,
                variables: {input}
            });
            setUploadedmessage(" Updated. ")
        } catch (err) {
            console.log(err)
        }
    }
    return (
        isOwner === isUser ?
        <>
            <div className="mathslidepage">
                <br/>
                <br/>
                <Grid container spacing={0}>
                    <Grid item xs={3}>
                        <p>Update image: &nbsp; &nbsp; &nbsp;</p>
                    </Grid>
                    <Grid item xs={9}>
                        <input type="file" onChange={handleFileChange} />
                    </Grid>
                </Grid>
                <br/>
                <div className="mathcccenter">
                    <img src={thisimage} className="pximages400" />
                </div>
                <br/>
                <br/>
                <p>Graphing Calculator: </p>
                <DesmosCalculator ref={addRef} />
                <br/>
                <Grid container spacing={0}>
                    <Grid item xs={11} className="rightside">
                        <span className="redthis">{uploadedmessage}</span> <button className="answersubmit" onClick={savethis}>Update</button>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <br/>
            </div>
        </>
            :""
    );
}

export default Editmathslide;

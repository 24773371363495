import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getPlayslide, listLeaderboards, listEngagements} from '../../../graphql/queries';
import "../../css/leaderboard.css";
import {deleteLeaderboard, deleteEngagement} from "../../../graphql/mutations";
import writeXlsx from 'write-excel-file';

function Cdlessonlog(props) {
    const  id  = props.data;
    const [myData, setMyData] = useState([]);
    const [roomname, setRoomname] = useState("");
    const [thisdatas, setThisdatas] = useState([]);
    useEffect(() => {
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const myroomname = datas.meetingname;
            setRoomname(myroomname)
        })()
        fetchDas();
    }, []);

    async function fetchDas() {
        const scounts = [];
        const dcounts=[];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "answerid": {
                            eq: id
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listLeaderboards.items;
            setThisdatas(datas);
            datas.forEach((data) => {
                const listdata = data;
                scounts.push(listdata)
            })
            scounts.sort((a, b) => {
                return b.score - a.score;
            });
            scounts.forEach((e) => {
                dcounts.push(e)
            });
            const result =  dcounts.map(item => {
                const totalDuration = item.questions
                    ? item.questions.reduce((acc, question) => acc + question.duration, 0)
                    : 0;
                return {
                    ...item,
                    dura: totalDuration,
                };
            });
            setMyData(result);
        }catch(err){
            console.log(err)
        }
    }
    const questions = myData.reduce((result, column) => {
        if (column.questions) {
            column.questions.forEach(question => {
                result[question.rid] = question.question;
            });
        };
        return result;
    }, {});

    const questionIdToNumbers = Object.keys(questions).reduce((result, qId, i) => {
        result[qId] = i+1;
        return result;
    }, {});

    let headings = [
        { value: 'Name', fontWeight: "bold"},
        { value: "Email", fontWeight: "bold"},
        { value: "Total Score", fontWeight: "bold"},
        { value: "Total Duration(min)", fontWeight: "bold"},
        { value: "Time In", fontWeight: "bold"},
        { value: "Time Out", fontWeight: "bold"},
        { value: "Education Level", fontWeight: "bold"},
        { value: "Source", fontWeight: "bold"}
    ];
    Object.keys(questions).forEach((questionId) => {
        headings.push(
            {value: `Question ${questionIdToNumbers[questionId]}`, fontWeight: 'bold'},
            {value: `Answer ${questionIdToNumbers[questionId]}`, fontWeight: 'bold'},
            {value: `Score ${questionIdToNumbers[questionId]}`, fontWeight: 'bold'},
            {value: `Duration ${questionIdToNumbers[questionId]}`, fontWeight: 'bold'},
        )
    });

    const rows = myData.map((rowData) => {
        const questionsForRow = rowData.questions || [];
        const questionsRowMap = questionsForRow.reduce((result, question) => {
            result[question.rid] = question;
            return result;
        }, {});
        const finalResults = [
            { type: String, value: rowData.name },
            { type: String, value: rowData.meetemail },
            { type: Number, value: rowData.score },
            { type: Number, value: Math.round((rowData.dura)/60) },
            { type: String, value: rowData.timerin },
            { type: String, value: rowData.timerout },
            { type: String, value: "Higher" },
            { type: String, value: "Reachum" }
        ];
        Object.keys(questions).forEach((questionId) => {
            finalResults.push(
                {value: questionsRowMap[questionId]?.question || '', type: String},
                {value: questionsRowMap[questionId]?.correctanswer || '', type: String},
                {value: questionsRowMap[questionId]?.thisscore || '', type: Number},
                {value: questionsRowMap[questionId]?.duration || '', type: Number}
            );
        });
        return finalResults;
    })

    const excelData = [
        headings,
        ...rows
    ]
    const downloadlessonFile = async () => {
        await writeXlsx(excelData, {fileName: `${roomname} - Reachum.xlsx`})
    };

    const clearresults = async ()=>{
        let text = "Are you sure you want to delete all players? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            try {
                let cl = thisdatas.length;
                for (let i = 0; i < cl; i++) {
                    const input = {
                        id: thisdatas[i].id
                    };
                    await API.graphql({
                        query: deleteLeaderboard,
                        variables: {input}
                    });
                }
            } catch (err) {
                console.log(err)
            }
            try {
                const response = await API.graphql({
                    query: listEngagements,
                    variables: {
                        filter: {
                            "questionid": {
                                eq: id
                            }
                        },
                        limit:10000
                    }
                })
                const datas = response.data.listEngagements.items;
                const datalength = datas.length;
                for(var j=0; j<datalength; j++) {
                    const input = {
                        id: datas[j].id
                    };
                    await API.graphql({
                        query: deleteEngagement,
                        variables: {input}
                    });
                }
            }catch(err){
                console.log(err)
            }
            window.location.reload();
        }
    }
    return (
        <>
                        <div className="leaderpage">
                                <div>
                                    <button onClick={downloadlessonFile} className="savespread">Download</button>
                                    <br/>
                                    <br/>
                                    <button onClick={clearresults} className="clearresub">Clear Results</button>
                                </div>
                        </div>
                    </>
    )
}

export default Cdlessonlog;

import React, { useState, useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import {API, Auth} from "aws-amplify";
import {getPlayslide} from "../../graphql/queries";
import Login from "../login";
import { email } from 'vanilla-sharing';
import {Helmet} from "react-helmet";
import "../../pages/css/exportLessonPlan.css"
import {CopyToClipboard} from "react-copy-to-clipboard";
import Leftarrow from "../../img/left-arrow.png";
import Grid from "@material-ui/core/Grid";
import Navbars from "../../components/Navbar";
import Foot from "../../components/Foot";

const ShareLessonPlan = ()  => {
    const { id } = useParams();
    const history = useNavigate();
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [playcontents, setPlaycontents] = useState([]);
    const [ymeeting, setYmeeting] = useState("");
    const [copied, setCopied] = useState("");
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setIsUser(user.attributes.email);
            })
            .catch(err => console.log(err));
        fetchData();
    }, []);
    async function fetchData() {
        const response =  await API.graphql({
            query: getPlayslide,
            variables: { id }
        });
        const datas = response.data.getPlayslide;
        const iowner = datas.owner;
        setIsOwner(iowner);
        const emeeting = datas.meetingname;
        setYmeeting(emeeting);
        const edatas = datas.playlistlinks;
        if(edatas === null){
            console.log("hello");
        }else {
            setPlaycontents(edatas);
        }
    }
    function scp(){
        history(`/editroom/${id}`);
    }
    function sharethis(){
        email({
                url: `https://edu.reachum.com/publicLessonPlan/${id}`,
                subject: `${ymeeting} - Lesson Plan`
            }
        )
    }
    return(
        <>
            <Helmet>
                <title>Lesson Plan</title>
            </Helmet>
            {!isOwner === isUser ?
                <Login />
                :(
                    <>
                        <Navbars />
                        <div className="pdfpage">
                            <Grid container spacing={0}>
                                <Grid item xs={3}>
                                    <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
                                </Grid>
                                <Grid item xs={9}>
                                </Grid>
                            </Grid>
                            <br/>
                            <br/>
                            <h2>{ymeeting}</h2>
                            <span>Share the Lesson Plan(View only)</span>
                            <div>
                                <CopyToClipboard text={`https://edu.reachum.com/publicLessonPlan/${id}`} onCopy={() => {
                                    setCopied(true);
                                    setTimeout(() => {
                                        setCopied(false);
                                    }, 3000);
                                }}>
                                    <div className="upgradenew2">
                                        <span className="underline">Click here to copy the link.</span>
                                        <span className="tooltiptext2">
                                        Copy the link for public use.
                                    </span>
                                    </div>
                                </CopyToClipboard>
                                {copied ? <span style={{color: 'red'}}>Copied</span> : null}
                            </div>
                            <button onClick={sharethis} className="shareemailbuton">Send via Email</button>
                            <br/>
                            {playcontents.map((page, index) => (
                                <div key={index} className="pdfiframe">
                                    <p>Page {index+1} - {page.title}</p>
                                    <iframe src={page.rurl} allowFullScreen={true} loading="lazy" scrolling="no" className="pdfiframes"></iframe>
                                </div>
                            ))}
                        </div>
                        <Foot />
                        <br/>
                        <br/>
                        <br/>
                    </>
                )
            }
        </>

    )
}


export default ShareLessonPlan;

import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePlayslide} from '../../graphql/mutations';
import "../css/uploadurl.css";
import {getPlayslide} from "../../graphql/queries";
import { v4 as uuid } from 'uuid';
import Grid from "@material-ui/core/Grid";

function Uploadurl({id, handleClose74}) {
    const [val, setVal] = useState("");
    const [vals, setVals] = useState("");
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [images, setImages] = useState([]);
    const [smessage, setSmessage] = useState("")
    const [opensmessage, setOpensmessage] = useState(true)
    const [smessage1, setSmessage1] = useState("")
    const [opensmessage1, setOpensmessage1] = useState(true)
    const d = uuid();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);
    var sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;
    const handleChange = e => {
        const tt = sanitizeUrl(e.target.value)
        setVal(tt);
    }
    const handleChanges = e => {
        const tts = sanitizeUrl(e.target.value)
        setVals(tts);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const riurl = `https://edu.reachum.com/iurlshowroom/${id}/${d}`;
        const erurl = `https://edu.reachum.com/urledit/${id}/${d}`;
        try {
                const urldata = {
                    rid:d,
                    title:"Webpage",
                    lurl: val,
                    rurl: riurl,
                    eurl: erurl
                };
            const allImages = [...images, urldata]
            const input = {
                id,
                playlistlinks: allImages
            }
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
            setOpensmessage(false);
            setSmessage("Submitted");
            setTimeout(() => {
                handleClose74()
            }, 1000)
        }catch(err){
            console.log(err)
        }
    }
    const handleSubmits = async (e) => {
        e.preventDefault();
        const rurl = `https://edu.reachum.com/urlshowroom/${id}/${d}`;
        const erurl = `https://edu.reachum.com/urledit/${id}/${d}`;
        try {
            const urldata = {
                rid:d,
                title:"Secure webpage",
                lurl: vals,
                rurl,
                eurl: erurl
            };
            const allImages = [...images, urldata]
            const input = {
                id,
                playlistlinks: allImages
            }
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
            setOpensmessage1(false);
            setSmessage1("Submitted");
            setTimeout(() => {
                handleClose74()
            }, 1000)
        }catch(err){
            console.log(err)
        }
    }

    return (
        isOwner === isUser ?
            <>
                <div className="uploadurl">
                    <form onSubmit={handleSubmit}>
                        <p>Add URL (Example: https://example.com)</p>
                        <div className="form-row">
                            <Grid container spacing={0}>
                                <Grid item xs={9}>
                                    <div className="upgradenew5">
                                        <input onChange={handleChange} className="form-control urlsform" name="rurl" placeholder="URL" />
                                        <span className="tooltiptext5">URLs to public content go here.</span>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    {opensmessage ?
                                    <input type="submit" value="Submit" className="answersubmit"/>
                                    :
                                    <p className="redthis"> {smessage} </p>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                    <br/>
                    <form onSubmit={handleSubmits}>
                        <p>Enter the URL here if it doesn't work using the entry field above. </p>
                        <div className="form-row">
                            <Grid container spacing={0}>
                                <Grid item xs={9}>
                                    <div className="upgradenew2">
                                        <input onChange={handleChanges} className="form-control urlsform" name="lurl" placeholder="Secure URL"/>
                                        <span className="tooltiptext2">
                                It appears in a secure popup window.
                            </span>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    {opensmessage1 ?
                                    <input type="submit" value="Submit" className="answersubmit"/>
                                        :
                                        <p className="redthis"> {smessage1} </p>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
                </>
            :""
    );
}

export default Uploadurl;
